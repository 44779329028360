import Web3 from 'web3';
import erc20Abi from '../abi/erc20Abi.json'
import infiAbi from '../abi/infiAbi.json'
import infiswapAbi from '../abi/infiSwapAbi.json'
import swapAbi from '../abi/swapAbi.json'
import web3config from '../web3config';

const validateProvider = async (walletProvider) => {
  console.log("validateProviderNew");
  try {
    if (!walletProvider) {
    console.log("walletProvider");

      return {
        status: false,
        message: `Please connect your web3 wallet`,
      };
    }
    const web3 = new Web3(window.ethereum);

    let accounts = await window.ethereum.request({
      method: "eth_requestAccounts"
    });

    if (!accounts || accounts.length === 0) {
      return {
        status: false,
        message: "No accounts found. Please connect your wallet.",
      };
    }
   const currentProvider = await web3.currentProvider;
   const chainId = await web3.currentProvider.chainId;
    return {
      status: true,
      web3,
      accounts:accounts[0],
    chainId
    };
  } catch (error) {
    console.log("Error in validateProvider :", error);
    return {
      status: false,
      message: "Error in validating provider. Please try again.",
    };
  }
};

export const NewPool = async (req) => {
    try{
      const {walletProvider,infipairContractAddress,pairContractAddress, infiAddress,infiAmount, tokenAddress, tokenAmount,networkId} = req
      const validate = await validateProvider(walletProvider)
      if (!validate.status) {
        return validate;
      }
      let web3 = validate.web3;
      let walletAddress = await validate.accounts
      let chain = await validate.chainId

      if(networkId !== chain) {
        try{
          await window.ethereum.request({
              method: 'wallet_switchEthereumChain',
              params: [{chainId: web3config.ChainIdMatic}]
            });
      } catch(switchError) {
          console.error('switch Error',switchError)
          return {
            message: `Switch Error: ${switchError.toString()}`
          }
        }
      }
  
      let infiswapContract = new web3.eth.Contract(infiswapAbi,infipairContractAddress)
      let swapContract = new web3.eth.Contract(swapAbi,pairContractAddress)
      let infiContract = new web3.eth.Contract(infiAbi,infiAddress)
      let infiDecimals = await infiContract.methods.decimals().call()
      let tokenContract = new web3.eth.Contract(erc20Abi,tokenAddress)
      let tokenDecimals = await tokenContract.methods.decimals().call()
  
      let amountInfi = (infiAmount * 10 ** parseInt(infiDecimals)).toLocaleString("fullwide", {useGrouping: false})
      let amountToken = (tokenAmount * 10** parseInt(tokenDecimals)).toLocaleString("fullwide", {useGrouping: false})

      // let isExists = await swapContract.methods.doesTokenUsdtPoolExists(tokenAddress).call()
      // if (isExists) {
      //   return {
      //     message: 'Pool already exist'
      //   }
      // }

      let trx = await infiswapContract.methods.createPool(tokenAddress, amountToken, amountInfi)
  
      let gasPrice = await web3.eth.getGasPrice()
      let gasLimit = await web3.eth.estimateGas({
        gasPrice: web3.utils.toHex(gasPrice),
        to: infipairContractAddress,
        from: walletAddress,
        data: trx.encodeABI(),
      })
  
      const trxData = await web3.eth.sendTransaction({
        gasPrice: web3.utils.toHex(gasPrice),
        gas: web3.utils.toHex(gasLimit),
        to: infipairContractAddress,
        from: walletAddress,
        data: trx.encodeABI()
      })
  
      return {
        status: true,
        hash: trxData.transactionHash,
        message: "Pool created successfully. It will take a few minutes to confirm the transaction."
      }
  
  
    } catch(error) {
      console.log('POOL',error)
      return {
        status: false,
        message: `Error creating infi pool ${error.toString()}`
    }
    }
  }

export const ApproveInfiToken = async (req) => {
    try{
      const {walletProvider, infiAddress, infipairContractAddress, infiAmount,networkId} = req
      const validate = await validateProvider(walletProvider)
      if (!validate.status) {
        return validate;
      }
      let web3 = validate.web3;
      let walletAddress = await validate.accounts
      let chain = await validate.chainId

      if(networkId != chain) {
        try{
          await window.ethereum.request({
              method: 'wallet_switchEthereumChain',
              params: [{chainId: web3config.ChainIdMatic}]
            });
      } catch(switchError) {
          console.error('Error',switchError)
          return {
            message: `Switch Error: ${switchError.toString()}`
          }
        }
      }
    
      let infiContract = new web3.eth.Contract(infiAbi, infiAddress)

      let decimals = await infiContract.methods.decimals().call()

      let amount = (infiAmount * 10 ** parseInt(decimals)).toLocaleString("fullwide", {useGrouping: false})
      let balance = await infiContract.methods.balanceOf(walletAddress).call();
      if (parseInt(balance) < parseInt(amount)) {
        return {
          status: false,
          message: `Insufficient balance. You need ${infiAmount} tokens, but only have ${balance / 10 ** decimals}`
        };
      }
      console.log('aaaaaaa',amount)      
        let trx = await infiContract.methods.approve(infipairContractAddress, amount)

        let gasPrice = await web3.eth.getGasPrice()
        let gasLimit = await web3.eth.estimateGas({
          gasPrice: web3.utils.toHex(gasPrice),
          to: infiAddress,
          from: walletAddress,
          data: trx.encodeABI(),
        })
      
        const trxData = await web3.eth.sendTransaction({
          gasPrice: web3.utils.toHex(gasPrice),
          gas: web3.utils.toHex(gasLimit),
          to: infiAddress,
          from: walletAddress,
          data: trx.encodeABI(),
        })
      
        return {
          status: true,
          hash: trxData.transactionHash
        }
    } catch(error) {
      console.log('Infi approval error',error)
            return {
                status: false,
                message: `Infi Approval error: ${error.toString()}`
            }
        }
    }


export const ApproveToken = async (req) => {
        try{
          const {walletProvider, tokenAddress, infipairContractAddress, tokenAmount,networkId} = req
          const validate = await validateProvider(walletProvider)
          if (!validate.status) {
            return validate;
          }
          let web3 = validate.web3;
          let walletAddress = await validate.accounts
          let chain = await validate.chainId

          if(networkId != chain) {
            try{
              await window.ethereum.request({
                  method: 'wallet_switchEthereumChain',
                  params: [{chainId: web3config.ChainIdMatic}]
                });
          } catch(switchError) {
              console.error('Error',switchError)
              return {
                message: `Switch error: ${switchError.toString()}`
              }
            }
          }
        
          let erc20Contract = new web3.eth.Contract(erc20Abi, tokenAddress)
        
          let decimals = await erc20Contract.methods.decimals().call()
           let  amount = (tokenAmount * 10 ** parseInt(decimals)).toLocaleString("fullwide", {useGrouping: false})
           let balance = await erc20Contract.methods.balanceOf(walletAddress).call();
           if (parseInt(balance) < parseInt(amount)) {
             return {
               status: false,
               message: `Insufficient balance. You need ${amount} tokens, but only have ${balance / 10 ** decimals}`
             };
           }
            let trx = await erc20Contract.methods.approve(infipairContractAddress, amount)
            console.log('UDT',infipairContractAddress,amount)
            let gasPrice = await web3.eth.getGasPrice()
            let gasLimit = await web3.eth.estimateGas({
              gasPrice: web3.utils.toHex(gasPrice),
              to: tokenAddress,
              from: walletAddress,
              data: trx.encodeABI(),
            })
          
            const trxData = await web3.eth.sendTransaction({
              gasPrice: web3.utils.toHex(gasPrice),
              gas: web3.utils.toHex(gasLimit),
              to: tokenAddress,
              from: walletAddress,
              data: trx.encodeABI(),
            })
          
            return {
              status: true,
              hash: trxData.transactionHash
            }
        } catch(error) {
          console.log('Approve token error',error)
                return {
                    status: false,
                    message: `Approve Token error: ${error.toString()}`
                }
            }
  }

export const ApproveUsdtToken = async (req) => {
    try{
      const {walletProvider, usdtAddress, infipairContractAddress, usdtAmount,networkId} = req
      const validate = await validateProvider(walletProvider)
      if (!validate.status) {
        return validate;
      }
      let web3 = validate.web3;
      let walletAddress = await validate.accounts
      let chain = await validate.chainId

      if(networkId != chain) {
        try{
          await window.ethereum.request({
              method: 'wallet_switchEthereumChain',
              params: [{chainId: web3config.ChainIdMatic}]
            });
      } catch(switchError) {
          console.error('Error',switchError)
          return {
            message: `Switch Error: ${switchError.toString()}`
          }
        }
      }
    
      let erc20Contract = new web3.eth.Contract(erc20Abi, usdtAddress)

      let decimals = await erc20Contract.methods.decimals().call()

      let amount = (usdtAmount * 10 ** parseInt(decimals)).toLocaleString("fullwide", {useGrouping: false})
      let balance = await erc20Contract.methods.balanceOf(walletAddress).call();
      if (parseInt(balance) < parseInt(amount)) {
        return {
          status: false,
          message: `Insufficient balance. You need ${amount} tokens, but only have ${balance / 10 ** decimals}`
        };
      }
      console.log('aaaaaaa',amount)      
        let trx = await erc20Contract.methods.approve(infipairContractAddress, amount)

        let gasPrice = await web3.eth.getGasPrice()
        let gasLimit = await web3.eth.estimateGas({
          gasPrice: web3.utils.toHex(gasPrice),
          to: usdtAddress,
          from: walletAddress,
          data: trx.encodeABI(),
        })
      
        const trxData = await web3.eth.sendTransaction({
          gasPrice: web3.utils.toHex(gasPrice),
          gas: web3.utils.toHex(gasLimit),
          to: usdtAddress,
          from: walletAddress,
          data: trx.encodeABI(),
        })
      
        return {
          status: true,
          hash: trxData.transactionHash
        }
    } catch(error) {
      console.log('Usdt approval error',error)
            return {
                status: false,
                message: `Usdt Approval error: ${error.toString()}`
            }
        }
    }


export const AddLiquidity = async (req) => {
    try{
      const {walletProvider,infipairContractAddress,tokenAddress, tokenAmount,networkId} = req
      const validate = await validateProvider(walletProvider)
      if (!validate.status) {
        return validate;
      }
      let web3 = validate.web3;
      let walletAddress = await validate.accounts
      let chain = await validate.chainId

      if(networkId != chain) {
        try{
          await window.ethereum.request({
              method: 'wallet_switchEthereumChain',
              params: [{chainId: web3config.ChainIdMatic}]
            });
      } catch(switchError) {
          console.error('Error',switchError)
          return {
            message: switchError.toString()
          }
        }
      }
  
      let pairContract = new web3.eth.Contract(infiswapAbi,infipairContractAddress)
      let erc20Contract = new web3.eth.Contract(erc20Abi,tokenAddress)
      let decimals = await erc20Contract.methods.decimals().call()

      let amountToken = (tokenAmount * 10 ** parseInt(decimals)).toLocaleString("fullwide", {useGrouping: false})
console.log('AAAAAA',amountToken)
    //   let isExists = await pairContract.methods.doesTokenUsdtPoolExists(tokenAddress).call()
    //   if (!isExists) {
    //     return {
    //       status: true,
    //       message: 'Pool does not exist'
    //     }
    //   }
  
      let trx = await pairContract.methods.addLiquidity(tokenAddress, amountToken)
  
      let gasPrice = await web3.eth.getGasPrice()
      let gasLimit = await web3.eth.estimateGas({
        gasPrice: web3.utils.toHex(gasPrice),
        to: infipairContractAddress,
        from: walletAddress,
        data: trx.encodeABI(),
      })
  
      const trxData = await web3.eth.sendTransaction({
        gasPrice: web3.utils.toHex(gasPrice),
        gas: web3.utils.toHex(gasLimit),
        to: infipairContractAddress,
        from: walletAddress,
        data: trx.encodeABI(),
      })
  
      return {
        status: true,
        hash: trxData.transactionHash,
        message: "Tokens added to liquidity pool successfully. It will take a few minutes to confirm the transaction."
      }
  
  
    } catch(error) {
      console.log("Error adding Liquidity",error)
      return {
        status: false,
        message: `Error adding liquidity: ${error.toString()}`
    }
    }
  }

export const RemoveLiquidity = async (req) => {
    try{
      const {walletProvider,infipairContractAddress,tokenAddress, lpAmount,networkId} = req
      const validate = await validateProvider(walletProvider)
      if (!validate.status) {
        return validate;
      }
      let web3 = validate.web3;
      let walletAddress = await validate.accounts
      let chain = await validate.chainId

      if(networkId != chain) {
        try{
          await window.ethereum.request({
              method: 'wallet_switchEthereumChain',
              params: [{chainId: web3config.ChainIdMatic}]
            });
      } catch(switchError) {
          console.error('Error',switchError)
          return {
            message: switchError.toString()
          }
        }
      }
  
      let contract = new web3.eth.Contract(infiswapAbi,infipairContractAddress)
      let erc20Contract = new web3.eth.Contract(erc20Abi,tokenAddress)
      let decimals = await erc20Contract.methods.decimals().call()

      let amountLp = (lpAmount * 10 ** parseInt(decimals)).toLocaleString("fullwide",{useGrouping:false})

    //   let isExists = await contract.methods.doesTokenUsdtPoolExists(tokenAddress).call()
    //   if (!isExists) {
    //     return {
    //       status: true,
    //       message: 'Pool does not exist'
    //     }
    //   }
  
      let trx = await contract.methods.removeLiquidity(tokenAddress, amountLp)
  
      let gasPrice = await web3.eth.getGasPrice()
      let gasLimit = await web3.eth.estimateGas({
        gasPrice: web3.utils.toHex(gasPrice),
        to: infipairContractAddress,
        from: walletAddress,
        data: trx.encodeABI(),
      })
  
      const trxData = await web3.eth.sendTransaction({
        gasPrice: web3.utils.toHex(gasPrice),
        gas: web3.utils.toHex(gasLimit),
        to: infipairContractAddress,
        from: walletAddress,
        data: trx.encodeABI()
      })
  
      return {
        status: true,
        hash: trxData.transactionHash,
        message: "Tokens removed from liquidity pool successfully. It will take a few minutes to confirm the transaction."
      }
  
  
    } catch(error) {
      console.log('Error removing liquidty',error)
      return {
        status: false,
        message: `Error reemoving Liquidity: ${error.toString()}`
    }
    }
  }


export const ReceivableInfi = async (req) => {
    try {
      const {tokenAmount, infipairContractAddress, walletProvider,tokenAddress,networkId} = req
      const validate = await validateProvider(walletProvider)
      if(!validate.status) {
        return validate
      }
  
      let web3 = validate.web3
      let walletAddress = await validate.accounts
              let chain = await validate.chainId

        if(networkId != chain) {
          try{
            await window.ethereum.request({
                method: 'wallet_switchEthereumChain',
                params: [{chainId: networkId}]
              });
        } catch(switchError) {
            console.error('Error',switchError)
            return {
              message: switchError.toString()
            }
          }
        }

      let pairContract = new web3.eth.Contract(infiswapAbi,infipairContractAddress)
      let erc20Contract = new web3.eth.Contract(erc20Abi,tokenAddress)
      let decimals =  await erc20Contract.methods.decimals().call()

      let amountToken = (tokenAmount * 10 ** parseInt(decimals)).toLocaleString("fullwide", {useGrouping: false})

        let result = await pairContract.methods.expectedInfiToReceive(tokenAddress,amountToken).call()
        //console.log('ANS2',result)

        return [{
          staus: true,
          data: {
          Fees:Number(result.amountToSbse + result.amountToTreasury) / 10**6,
          ToSbsePool:Number(result.amountToSbse) / 10**6,
          ToTreasury:Number(result.amountToTreasury)/10**6,
          ToStakingPool:Number(result.amountToTradingPool)/ 10**18,
          INFI:Number(result.actualInfiToReceive) / 10**18
          }
        }]
    }   catch (error) {
      console.error("Error getting expected infi",error);
    }
  }

  export const SwapTokenToInfi = async (req) => {
    try{
      const {walletProvider,infipairContractAddress,tokenAddress,tokenAmount,networkId} = req
      const validate = await validateProvider(walletProvider)
      if (!validate.status) {
        return validate;
      }
      let web3 = validate.web3;
      let walletAddress = await validate.accounts
      let chain = await validate.chainId

      if(networkId != chain) {
        try{
          await window.ethereum.request({
              method: 'wallet_switchEthereumChain',
              params: [{chainId: web3config.ChainIdMatic}]
            });
      } catch(switchError) {
          console.error('Error',switchError)
          return {
            message: switchError.toString()
          }
        }
      }
  
      let swapContract = new web3.eth.Contract(infiswapAbi,infipairContractAddress)
      let erc20Contract = new web3.eth.Contract(erc20Abi,tokenAddress)
      let decimals = await erc20Contract.methods.decimals().call()

      let amountToken = (tokenAmount * 10 ** parseInt(decimals)).toLocaleString("fullwide", {useGrouping: false})

    //   let isExists = await swapContract.methods.doesTokenUsdtPoolExists(tokenAddress).call()
    //   if (!isExists) {
    //     return {
    //       message: 'Pool does not exist'
    //     }
    //   }
  
      let trx = await swapContract.methods.swapTokenToInfi(tokenAddress, amountToken)
  
      let gasPrice = await web3.eth.getGasPrice()
      let gasLimit = await web3.eth.estimateGas({
        gasPrice: web3.utils.toHex(gasPrice),
        to: infipairContractAddress,
        from: walletAddress,
        data: trx.encodeABI(),
      })
  
      const trxData = await web3.eth.sendTransaction({
        gasPrice: web3.utils.toHex(gasPrice),
        gas: web3.utils.toHex(gasLimit),
        to: infipairContractAddress,
        from: walletAddress,
        data: trx.encodeABI(),
      })
  
      return {
        status: true,
        hash: trxData.transactionHash,
        message: "Tokens swapped succcessfully.It will take a few minutes to confirm the transaction "
      }
  
  
    } catch(error) {
      console.log('token to infi swap error',error)
      return {
        status: false,
        message: `Token to Infi swap error: ${error.toString()}`
    }
    }
  }



export const ReceivableToken = async (req) => {
    try {
      const {infiAmount, infipairContractAddress, walletProvider,infiAddress,tokenAddress,networkId} = req
      //console.log('AAARRRRRRRRR',infiAmount, infipairContractAddress, walletProvider,infiAddress,tokenAddress)
      const validate = await validateProvider(walletProvider)
      if(!validate.status) {
        return validate
      }
  
      let web3 = validate.web3
      let walletAddress = await validate.accounts
      let chain = await validate.chainId

      if(networkId != chain) {
        try{
          await window.ethereum.request({
              method: 'wallet_switchEthereumChain',
              params: [{chainId: networkId}]
            });
      } catch(switchError) {
          console.error('Error',switchError)
          return {
            message: switchError.toString()
          }
        }
      }

      let pairContract = new web3.eth.Contract(infiswapAbi,infipairContractAddress)
      let erc20Contract = new web3.eth.Contract(erc20Abi,infiAddress)
      let decimals =  await erc20Contract.methods.decimals().call()

      let amountInfi = (infiAmount * 10 ** parseInt(decimals)).toLocaleString("fullwide", {useGrouping: false})

      let result = await pairContract.methods.expectedTokenToReceive(tokenAddress,amountInfi).call()
      //console.log('ANS',result)
        return [{
          status: true,
         data: {
          Fees: Number(result.amountToSbse + result.amountToTreasury)/10**6,
          ToSbsePool: Number(result.amountToSbse)/10**6,
          ToTreasury: Number(result.amountToTreasury) / 10**6,
          ToStakingPool: Number(result.reserveInfi) / 10**18,
          Token: Number(result.actualTokenToReceive)/10**18
        }
        }]
    }   catch (error) 
        {
          console.log("Error getting expected token",error)
          return [{
          status:false
    }]
    }
  }

  export const SwapInfiToToken = async (req) => {
    try{
      const {walletProvider,infipairContractAddress,tokenAddress, infiAddress,infiAmount,networkId} = req
      const validate = await validateProvider(walletProvider)
      if (!validate.status) {
        return validate;
      }
      let web3 = validate.web3;
      let walletAddress =  await validate.accounts
      let chain = await validate.chainId

      if(networkId != chain) {
        try{
          await window.ethereum.request({
              method: 'wallet_switchEthereumChain',
              params: [{chainId: web3config.ChainIdMatic}]
            });
      } catch(switchError) {
          console.error('Error',switchError)
          return {
            message: switchError.toString()
          }
        }
      }
  
  
      let swapContract = new web3.eth.Contract(infiswapAbi,infipairContractAddress)
      let erc20Contract = new web3.eth.Contract(erc20Abi,tokenAddress)
      let infiContract = new web3.eth.Contract(infiAbi,infiAddress)

      let tokenDecimals = await erc20Contract.methods.decimals().call()
      let infiDecimals = await infiContract.methods.decimals().call()

      let amountInfi = (infiAmount * 10 ** parseInt(infiDecimals)).toLocaleString("fullwide", {useGrouping: false})


    //   let isExists = await swapContract.methods.doesTokenUsdtPoolExists(tokenAddress).call()
    //   if (!isExists) {
    //     return {
    //       message: 'Pool does not exist'
    //     }
    //   }
  
      let trx = await swapContract.methods.swapInfiToToken(tokenAddress,amountInfi)
  
      let gasPrice = await web3.eth.getGasPrice()
      let gasLimit = await web3.eth.estimateGas({
        gasPrice: web3.utils.toHex(gasPrice),
        to: infipairContractAddress,
        from: walletAddress,
        data: trx.encodeABI(),
      })
  
      const trxData = await web3.eth.sendTransaction({
        gasPrice: web3.utils.toHex(gasPrice),
        gas: web3.utils.toHex(gasLimit),
        to: infipairContractAddress,
        from: walletAddress,
        data: trx.encodeABI(),
      })
  
      return {
        status: true,
        hash: trxData.transactionHash,
        message: "Infi swapped succcessfully.It will take a few minutes to confirm the transaction "
      }
  
    } catch(error) {
      console.log("Infi to Token swap error",error)
      return {
        status: false,
        message: `Infi to token swap error: ${error.toString()}`
    }
    }
  }



export const getUserUsdtBalance = async (req) => {
  const {usdtContract, walletProvider,networkId} = req
    try {
        const validate = await validateProvider(walletProvider);
        if (!validate.status) {
            return validate;
        }
        let web3 = validate.web3;
        let walletAddress = await validate.accounts
        let chain = await validate.chainId

        if(networkId != chain) {
          try{
            await window.ethereum.request({
                method: 'wallet_switchEthereumChain',
                params: [{chainId: networkId}]
              });
        } catch(switchError) {
            console.error('Error',switchError)
            return {
              message: switchError.toString()
            }
          }
        }

        let contract = new web3.eth.Contract(erc20Abi, usdtContract);
        let balance = await contract.methods.balanceOf(walletAddress).call();

        return {
            status: true,
            balance: Number(balance) / 10 ** 6
        }
    } catch (error) {
      console.log("Error getting user usdt balance",error);
        return {
          message: error.toString()
        }
    }
};

export const getUserTokenBalance = async (req) => {
  const {walletProvider, tokenAddress,networkId} = req
  try {
      const validate = await validateProvider(walletProvider);
      if (!validate.status) {
          return validate;
      }
      let web3 = validate.web3;
      let walletAddress = await validate.accounts
      let chain = await validate.chainId

      if(networkId != chain) {
        try{
          await window.ethereum.request({
              method: 'wallet_switchEthereumChain',
              params: [{chainId: networkId}]
            });
      } catch(switchError) {
          console.error('Error',switchError)
          return {
            message: switchError.toString()
          }
        }
      }

      let erc20Contract = new web3.eth.Contract(erc20Abi, tokenAddress);
      let balance = await erc20Contract.methods.balanceOf(walletAddress).call();

      return {
          status: true,
          balance: Number(balance) / 10 ** 18
      }
  } catch (error) {
      console.log('Error getting user token balance',error);
  }
};

export const getUserInfiBalance = async (req) => {
    const {walletProvider, infiAddress,networkId} = req
    try {
        const validate = await validateProvider(walletProvider);
        if (!validate.status) {
            return validate;
        }
        let web3 = validate.web3;
        let walletAddress = await validate.accounts
        let chain = await validate.chainId
  
        if(networkId != chain) {
          try{
            await window.ethereum.request({
                method: 'wallet_switchEthereumChain',
                params: [{chainId: networkId}]
              });
        } catch(switchError) {
            console.error('Error',switchError)
            return {
              message: switchError.toString()
            }
          }
        }
  
        let erc20Contract = new web3.eth.Contract(infiAbi, infiAddress);
        let balance = await erc20Contract.methods.balanceOf(walletAddress).call();
  
        return {
            status: true,
            balance: Number(balance) / 10 ** 18
        }
    } catch (error) {
        console.log('Error getting user infi balance',error);
    }
  };

  export const getTokenPriceInfiSwap = async (req) => {
    try {
      let data = req
      let results = []
      for(let i = 0; i < data.length; i++) {
        const validate = await validateProvider2(data[i].chainRPCUrl)
        if(!validate.status) {
          return validate
        }
      let web3 = validate.web3
      let infiswapContract = new web3.eth.Contract(infiswapAbi,data[i].infiPairContract)
      console.log('AOX',data[i].infiPairContract)
      let contractInfi = new web3.eth.Contract(infiAbi,data[i].infiAddress)
      let decimals = await contractInfi.methods.decimals().call()
      let trx = await infiswapContract.methods.getTokenPriceInInfi(data[i].token_address).call()
      let Price = Number(trx) / 10 ** parseInt(decimals).toLocaleString("fullwide", {useGrouping: false})
      results.push(Price)
      }
      return {
        status: true,
        data: results
      }
    }
       catch (error) {
      console.error('Error getting token price',error);
    }
  }

  export const getTokenSupplyInfiSwap = async (req) => {
    try {
      let data = req
      let results = []
      for(let i = 0; i < data.length; i++) {
        const validate = await validateProvider2(data[i].chainRPCUrl)
        if(!validate.status) {
          return validate
        }
      let web3 = validate.web3
      let contractToken = new web3.eth.Contract(erc20Abi,data[i].token_address)
      let decimals = await contractToken.methods.decimals().call()
      let trx = await contractToken.methods.totalSupply(data[i].token_address).call()
      console.log('LOO',trx)
      let Supply = Number(trx) / 10 ** parseInt(decimals).toLocaleString("fullwide", {useGrouping: false})
      results.push(Supply)
      }
      return {
        status: true,
        data: results
      }
    }
       catch (error) {
      console.error('Error getting token price',error);
    }
  }
  



export const PoolBalances = async (req) => {
  try {
    const {infipairContractAddress, walletProvider,tokenAddress,networkId} = req
    const validate = await validateProvider(walletProvider)
    if(!validate.status) {
      return validate
    }

    let web3 = validate.web3
    let walletAddress = await validate.accounts
    let chain = await validate.chainId

    if(networkId != chain) {
      try{
        await window.ethereum.request({
            method: 'wallet_switchEthereumChain',
            params: [{chainId: networkId}]
          });
          window.location.reload()
    } catch(switchError) {
        console.error('Error',switchError)
        return {
          message: switchError.toString()
        }
      }
    }

    let pairContract = new web3.eth.Contract(infiswapAbi,infipairContractAddress)
      let result = await pairContract.methods.getTokenBalances(tokenAddress).call()
      return [{
        staus: true,
        data:{
        Token: Number(result.tokenABalance) / 10**18,
        INFI: Number(result.infiBalance) / 10**18
        }
      }]
  }   catch (error) {
      console.log('Error getting pool token balance',error);
  }
}


export const ReceivableAmountAfterRemovingLiquidity = async (req) => {
  try {
    const {infipairContractAddress, walletProvider,tokenAddress,networkId,lpAmount} = req
    const validate = await validateProvider(walletProvider)
    if(!validate.status) {
      return validate
    }

    let web3 = validate.web3
    let walletAddress = await validate.accounts
    let chain = await validate.chainId

    if(networkId != chain) {
      try{
        await window.ethereum.request({
            method: 'wallet_switchEthereumChain',
            params: [{chainId: networkId}]
          });
    } catch(switchError) {
        console.error('Error',switchError)
        return {
          message: switchError.toString()
        }
      }
    }

    let pairContract = new web3.eth.Contract(infiswapAbi,infipairContractAddress)
    let amount = lpAmount * 10**18
      let result = await pairContract.methods.expectedReturnsAfterRemovingLiquidity(tokenAddress,amount).call()
      return [{
        staus: true,
        data: {
        Token: Number(result.tokenAReturned)/10**18,
        INFI: Number(result.infiReturned - result.penalty)/10**18,
        PENALTY: Number(result.penalty) /10**18
        }
      }]
  }   catch (error) {
    console.log('Error getting return amount',error);
  }
}

export const LPBalance = async (req) => {
  try {
    const {infipairContractAddress, walletProvider,tokenAddress,networkId} = req
    const validate = await validateProvider(walletProvider)
    if(!validate.status) {
      return validate
    }

    let web3 = validate.web3
    let walletAddress = await validate.accounts
    let chain = await validate.chainId

    if(networkId != chain) {
      try{
        await window.ethereum.request({
            method: 'wallet_switchEthereumChain',
            params: [{chainId: networkId}]
          });
    } catch(switchError) {
        console.error('Error',switchError)
        return {
          message: switchError.toString()
        }
      }
    }

    let pairContract = new web3.eth.Contract(infiswapAbi,infipairContractAddress)
      let result = await pairContract.methods.getLPBalance(tokenAddress).call({ from: walletAddress})
      return {
        status: true,
        balance: Number(result)/10**18
      }
  }   catch (error) {
    console.log('Error getting lp token balance',error);
  }
}

export const InfiAmount = async (req) => {
  try {
    const {infipairContractAddress, walletProvider,tokenAddress,tokenAmount,networkId} = req
    const validate = await validateProvider(walletProvider)
    if(!validate.status) {
      return validate
    }

    let web3 = validate.web3
    let walletAddress = await validate.accounts
    let chain = await validate.chainId

    if(networkId != chain) {
      try{
        await window.ethereum.request({
            method: 'wallet_switchEthereumChain',
            params: [{chainId: networkId}]
          });
    } catch(switchError) {
        console.error('Error',switchError)
        return {
          message: switchError.toString()
        }
      }
    }

    let pairContract = new web3.eth.Contract(infiswapAbi,infipairContractAddress)
    let erc20Contract = new web3.eth.Contract(erc20Abi,tokenAddress)
    let tokenDecimals = await erc20Contract.methods.decimals().call()
    let amountToken = (tokenAmount * 10 ** parseInt(tokenDecimals)).toLocaleString("fullwide", {useGrouping: false})

      let result = await pairContract.methods.getInfiAmount(tokenAddress,amountToken).call()
      return {
        staus: true,
        Bal: Number(result) / 10**18
      }
  }   catch (error) {
    console.error(error);
  }
}

export const LpToMint = async (req) => {
  try {
    const {infipairContractAddress, walletProvider,tokenAddress,tokenAmount,infiAddress,infiAmount,networkId} = req
    const validate = await validateProvider(walletProvider)
    if(!validate.status) {
      return validate
    }

    let web3 = validate.web3
    let walletAddress = await validate.accounts
    let chain = await validate.chainId

    if(networkId != chain) {
      try{
        await window.ethereum.request({
            method: 'wallet_switchEthereumChain',
            params: [{chainId: networkId}]
          });
    } catch(switchError) {
        console.error('Error',switchError)
        return {
          message: switchError.toString()
        }
      }
    }

    let pairContract = new web3.eth.Contract(infiswapAbi,infipairContractAddress)
    let erc20Contract = new web3.eth.Contract(erc20Abi,tokenAddress)
    let infiContract = new web3.eth.Contract(infiAbi,infiAddress)
    let tokenDecimals = await erc20Contract.methods.decimals().call()
    let infiDecimals = await infiContract.methods.decimals().call()

    let amountToken = (tokenAmount * 10 ** parseInt(tokenDecimals)).toLocaleString("fullwide", {useGrouping: false})
    let amountInfi = (infiAmount * 10 ** parseInt(infiDecimals)).toLocaleString("fullwide", {useGrouping: false})

      let result = await pairContract.methods.calculateLPTokensToMint(tokenAddress,amountToken,amountInfi).call()
      return {
        staus: true,
        Bal: Number(result) / 10**18
      }
  }   catch (error) {
    console.log('Error getting lp to mint',error);
  }
}

export const PriceCalculator = async (req) => {
  try {
    const {walletProvider,infiAmount,infipairContractAddress,tokenAmount,tokenAddress,infiAddress,networkId} = req
    const validate = await validateProvider(walletProvider)
    if(!validate.status) {
      return validate
    }

    let web3 = validate.web3
    let walletAddress = await validate.accounts
    let chain = await validate.chainId

    if(networkId != chain) {
      try{
        await window.ethereum.request({
            method: 'wallet_switchEthereumChain',
            params: [{chainId: networkId}]
          });
    } catch(switchError) {
        console.error('Error',switchError)
        return {
          message: switchError.toString()
        }
      }
    }

    let pairContract = new web3.eth.Contract(infiswapAbi,infipairContractAddress)
    let erc20Contract = new web3.eth.Contract(erc20Abi,tokenAddress)
    let tokenDecimals = await erc20Contract.methods.decimals().call()
    let infiContract = new web3.eth.Contract(infiAbi,infiAddress)
    let infiDecimals = await infiContract.methods.decimals().call()

    let amountToken = (tokenAmount * 10 ** parseInt(tokenDecimals)).toLocaleString("fullwide", {useGrouping: false})
    let amountInfi = (infiAmount * 10 ** parseInt(infiDecimals)).toLocaleString("fullwide", {useGrouping: false})

      let result = await pairContract.methods.tokenPriceCalculator(amountToken,amountInfi).call()
      return {
        staus: true,
        Price: Number(result) / 10**18
      }
  }   catch (error) {
    console.error(error);
  }
}


export const RemovalStatus = async (req) => {
  try{
    const {walletProvider, infipairContractAddress,tokenAddress,networkId} = req
    const validate = await validateProvider(walletProvider)
    if (!validate.status) {
      return validate;
    }
    let web3 = validate.web3;
    let walletAddress = await validate.accounts
    let chain = await validate.chainId

    if(networkId != chain) {
      try{
        await window.ethereum.request({
            method: 'wallet_switchEthereumChain',
            params: [{chainId: networkId}]
          });
    } catch(switchError) {
        console.error('Error',switchError)
        return {
          message: switchError.toString()
        }
    }
  }

    let contractSwap = new web3.eth.Contract(infiswapAbi,infipairContractAddress)

    let trx = await contractSwap.methods.removalStatus(tokenAddress).call()

    return {
      status:trx
    }

  } catch(error) {
    return {
      status: false,
      message: error.toString()
  }
  }
}

const validateProvider2 = async (walletProvider) => {
  console.log("validateProvider2");
  try {
    if (!walletProvider) {
      console.log("walletProvider");

      return {
        status: false,
        message: `Please connect your web3 wallet`,
      };
    }
    const web3 = new Web3(walletProvider);

    return {
      status: true,
      web3
    };
  } catch (error) {
    console.log("Error in validateProvider :", error);
    return {
      status: false,
      message: "Error in validating provider. Please try again.",
    };
  }
};