import React ,{useState, useEffect} from "react";
import { Fade, Slide } from "react-awesome-reveal";
import { Button, Col, Container, Row } from "react-bootstrap";
import { FaFacebook } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { SiLinktree } from "react-icons/si";
import config from "../../config";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

export default function Footer() {

    const navigate = useNavigate();
    let loginDetails = useSelector((state) => state.auth.LoginDetails);


    const applicationForm = () => {
        if (Object.keys(loginDetails).length === 0) {
            navigate(`${config.baseUrl}login`);
        } else {
        navigate(`${config.baseUrl}launchprocessform`);
    }
}


const liquidityApplicationForm = () => {
    if (Object.keys(loginDetails).length === 0) {
        navigate(`${config.baseUrl}login`);
    } else {
    navigate(`${config.baseUrl}liquidityListingForm`);
    } 
}


const infiApplicationForm = () => {
    if (Object.keys(loginDetails).length === 0) {
        navigate(`${config.baseUrl}login`);
    } else {
    navigate(`${config.baseUrl}infiListingForm`);
    } 
}

    const goTo = (e) => {
        e.preventDefault()
        navigate(`${config.baseUrl}home`)
    }

    return (
        <>
            <section className="launchProject p-5">
                <Container>
                    <div>
                        <Slide>
                            <h2 className="text-center">Want to Launch Your Project On INFI MULTlCHAIN LAUNCHPAD?</h2>
                        </Slide>
                    </div>

                    <div className="mt-3 text-center">
                        <div className="d-inline-block">
                            <div className="d-inline-block text-center text-md-start me-3 mb-3">
                                <Fade>
                                    <Button variant="dark" className="px-4" onClick={applicationForm} >RAISE FUNDS</Button>
                                </Fade>
                            </div>
                            <div className="d-inline-block text-center text-md-start me-3 mb-3">
                                <Fade>
                                    <Button variant="dark" className="px-4" onClick={liquidityApplicationForm} >LIST YOUR PROJECT</Button>
                                </Fade>
                            </div>
                            <div className="d-inline-block text-center text-md-start ">
                                <Fade>
                                    <Button variant="dark" className="px-4" onClick={infiApplicationForm} >SPECIAL LIQUIDITY LISTING </Button>
                                </Fade>
                            </div>

                        </div>





                        {/* <Col md={6} className="text-center text-md-start">
                            <Fade>
                                <Button variant="outline-dark mt-2 px-4 mt-md-0">HOW IT WORKS</Button>
                            </Fade>
                        </Col> */}
                    </div>
                </Container>
            </section>

            <section className="py-5 footer">
                <Container>
                    <Row>
                        <Col md={3}>
                            <div className="logo d-flex justify-content-center mt-3">
                                <div>
                                    <img src={`${config.baseUrl}images/logos/logoLaunchPadNew.png`} alt="" className="img-fluid" onClick={(e) => goTo(e)} width={220} />
                                </div>
                            </div>
                        </Col>

                        <Col md={6}>
                            <p className="fs-5 mt-3 mt-md-0 text-center text-md-start">INFI MultiChain Launchpad is a plaform that helps and advises project teams on how to best issue and launch their token.</p>
                        </Col>

                        <Col md={3}>
                            <div className="text-center text-md-end icons">
                                <span className="mx-2">
                                    <a href='https://www.facebook.com/profile.php?id=100093242221968' target='_blank'>
                                        <FaFacebook />
                                    </a> </span>
                                <span className="mx-2">
                                    <a href='https://www.instagram.com/inverted_investment/' target='_blank'>
                                        <FaInstagram />
                                    </a></span>
                                <span className="mx-2">
                                    <a href='https://twitter.com/InvestmentFina4' target='_blank'>
                                        <FaXTwitter />
                                    </a></span>
                                <span className="mx-2">
                                    <a href='https://linktr.ee/invertedinvestment' target='_blank'>
                                        <span className='fs-5'><SiLinktree /></span>
                                    </a></span>

                            </div>
                        </Col>
                    </Row>

                    <div className="footer_section mt-5">
                        <Row>
                            {/* <Col sm={6} md={2} className="mt-4 mt-md-0 text-center text-md-start">
                                <div>
                                    <b>General</b>
                                </div>

                                <div>
                                    <div className="mt-3">Level Staking</div>
                                    <div className="mt-3">Farm</div>
                                    <div className="mt-3">Apply for IDO</div>
                                    <div className="mt-3">Privacy Policy</div>
                                    <div className="mt-3">Terms of use</div>
                                </div>
                            </Col> */}

                            {/* <Col sm={6} md={3} className="mt-4 mt-md-0 text-center text-md-start">
                                <div>
                                    <b>Help</b>
                                </div>

                                <div>
                                    <div className="mt-3">How to participate in IDO</div>
                                    <div className="mt-3">FAQs</div>
                                    <div className="mt-3">Documentation</div>
                                </div>
                            </Col> */}

                            {/* <Col sm={6} md={2} className="mt-4 mt-md-0 text-center text-md-start">
                                <div>
                                    <b>Communities</b>
                                </div>

                                <div>
                                    <div className="d-flex mt-3 justify-content-center justify-content-md-start">
                                        <div className="me-2">
                                            <img src="images/footerLogos/flag/UnitedStates.svg" className="img-fluid" alt="" />
                                        </div>
                                        English
                                    </div>
                                    <div className="d-flex mt-3 justify-content-center justify-content-md-start">
                                        <div className="me-2">
                                            <img src="images/footerLogos/flag/Russia.svg" className="img-fluid" alt="" />
                                        </div>
                                        Russian
                                    </div>
                                    <div className="d-flex mt-3 justify-content-center justify-content-md-start">
                                        <div className="me-2">
                                            <img src="images/footerLogos/flag/China.svg" className="img-fluid" alt="" />
                                        </div>
                                        Chinese
                                    </div>
                                    <div className="d-flex mt-3 justify-content-center justify-content-md-start">
                                        <div className="me-2">
                                            <img src="images/footerLogos/flag/Turkey.svg" className="img-fluid" alt="" />
                                        </div>
                                        Turkish
                                    </div>
                                    <div className="d-flex mt-3 justify-content-center justify-content-md-start">
                                        <div className="me-2">
                                            <img src="images/footerLogos/flag/Southkorea.svg" className="img-fluid" alt="" />
                                        </div>
                                        Korean
                                    </div>
                                </div>
                            </Col> */}

                            {/* <Col sm={6} md={3} className="mt-4 mt-md-0 text-center text-md-start">
                                <div>
                                    <b>Token</b>
                                </div>

                                <div>
                                    <div className="d-flex justify-content-center justify-content-md-start mt-3">
                                        <div className="me-2">
                                            <img src="images/footerLogos/logo/1.svg" className="img-fluid" alt="" />
                                        </div>
                                        CoinGeko
                                    </div>
                                    <div className="d-flex justify-content-center justify-content-md-start mt-3">
                                        <div className="me-2">
                                            <img src="images/footerLogos/logo/2.svg" className="img-fluid" alt="" />
                                        </div>
                                        CoinMarketCap
                                    </div>
                                    <div className="d-flex justify-content-center justify-content-md-start mt-3">
                                        <div className="me-2">
                                            <img src="images/footerLogos/logo/3.svg" className="img-fluid" alt="" />
                                        </div>
                                        CryptoRank
                                    </div>
                                    <div className="d-flex justify-content-center justify-content-md-start mt-3">
                                        <div className="me-2">
                                            <img src="images/footerLogos/logo/4.svg" className="img-fluid" alt="" />
                                        </div>
                                        PancakeSwap
                                    </div>
                                </div>
                            </Col> */}

                            <Col md={4} sm={5} className="mt-4 mt-md-0">
                                {/* <div>
                                    <b>Audits</b>
                                </div> */}
                            
                                    <div className='strategicpartners ' style={{ color: "black", fontWeight: "bold" }}>
                                        INFI CDEX is safeguarded by our strategic partners
                                        <div className="">
                                            <div className="me-2 mb-2">
                                                <a href="https://cyvers.ai/">
                                                    <img src={`${config.baseUrl}images/logos/Cyvers.png`} alt="" width="155px" altstyle={{ display: "block", margin: "0px auto" }} />
                                                </a>
                                            </div>
                                        </div>

                                    </div>
                              
                            </Col>
                            <Col md={8} sm={7} className="mt-4 mt-md-0">
                                <div>
                                    <div className="">
                                        <img src={`${config.baseUrl}images/footerLogos/logo/esplogo.svg`} alt="" width="160px" altstyle={{ display: "block", margin: "0px auto" }} />
                                        <div className="small">Designed By <a href="#" className="text-decoration-none text-primary fw-semibold">ESP Softtech Pvt Ltd</a></div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>

                    <hr className="my-4" />

                    <div className="d-block d-lg-flex justify-content-between">
                        <div>
                            <a href='mailto:support@infimultichain.com' className='me-3 text-decoration-none d-inline-block'>support@infimultichain.com</a>
                        </div>
                        <div className="mt-3 mt-lg-0">
                            <a href="javascript:void(0)" className='text-decoration-none'>© 2024 Infi Multichain. All rights reserved.</a>
                        </div>
                    </div>
                </Container>
            </section>
        </>
    )
}