import Web3, { net } from 'web3';
import erc20Abi from '../abi/erc20Abi.json'
import swapAbi from '../abi/swapAbi.json'
import web3config from '../web3config';

const validateProvider = async (walletProvider) => {
  console.log("validateProviderNew");
  try {
    if (!walletProvider) {
    console.log("walletProvider");

      return {
        status: false,
        message: `Please connect your web3 wallet`,
      };
    }
    //console.log('WALLET', walletProvider)
    const web3 = new Web3(window.ethereum);

    let accounts = await window.ethereum.request({
      method: "eth_requestAccounts"
    });

    if (!accounts || accounts.length === 0) {
      return {
        status: false,
        message: "No accounts found. Please connect your wallet.",
      };
    }
   const currentProvider = await web3.currentProvider;
   const chainId = await web3.currentProvider.chainId;
  //  console.log({
  //   status: true,
  //   web3,chainId
  // })
    return {
      status: true,
      web3,
      accounts:accounts[0],
    chainId
    };
  } catch (error) {
    console.log("Error in validateProvider :", error);
    return {
      status: false,
      message: "Error in validating provider. Please try again.",
    };
  }
};

export const NewPool = async (req) => {
    try{
      const {walletProvider,pairContractAddress, usdtAddress,usdtAmount, tokenAddress, tokenAmount,networkId} = req
      const validate = await validateProvider(walletProvider)
      if (!validate.status) {
        return validate;
      }
      let web3 = validate.web3;
      let walletAddress = await validate.accounts
      let chain = await validate.chainId

      if(networkId !== chain) {
        try{
          await window.ethereum.request({
              method: 'wallet_switchEthereumChain',
              params: [{chainId: networkId}]
            });
      } catch(switchError) {
          console.error('switch Error',switchError)
          return {
            message: `Switch Error: ${switchError.toString()}`
          }
        }
      }
  
      let swapContract = new web3.eth.Contract(swapAbi,pairContractAddress)
      let usdtContract = new web3.eth.Contract(erc20Abi,usdtAddress)
      let usdtDecimals = await usdtContract.methods.decimals().call()
      let tokenContract = new web3.eth.Contract(erc20Abi,tokenAddress)
      let tokenDecimals = await tokenContract.methods.decimals().call()
      console.log("dec",usdtDecimals,tokenDecimals)
      let amountUsdt = (usdtAmount * 10 ** parseInt(usdtDecimals)).toLocaleString("fullwide", {useGrouping: false})
      let amountToken = (tokenAmount * 10** parseInt(tokenDecimals)).toLocaleString("fullwide", {useGrouping: false})

      let isExists = await swapContract.methods.doesTokenUsdtPoolExists(tokenAddress).call()
      if (isExists) {
        return {
          message: 'Pool already exist'
        }
      }

      let trx = await swapContract.methods.createPool(tokenAddress, amountToken, amountUsdt)
  
      let gasPrice = await web3.eth.getGasPrice()
      let gasLimit = await web3.eth.estimateGas({
        gasPrice: web3.utils.toHex(gasPrice),
        to: pairContractAddress,
        from: walletAddress,
        data: trx.encodeABI(),
      })
  
      const trxData = await web3.eth.sendTransaction({
        gasPrice: web3.utils.toHex(gasPrice),
        gas: web3.utils.toHex(gasLimit),
        to: pairContractAddress,
        from: walletAddress,
        data: trx.encodeABI()
      })
  
      return {
        status: true,
        hash: trxData.transactionHash,
        message: "Pool created successfully. It will take a few minutes to confirm the transaction."
      }
  
  
    } catch(error) {
      console.log('POOL',error)
      return {
        status: false,
        message: `Error creating pool ${error.toString()}`
    }
    }
  }

export const ApproveUsdtToken = async (req) => {
    try{
      const {walletProvider, usdtAddress, pairContractAddress, usdtAmount,networkId} = req
      const validate = await validateProvider(walletProvider)
      if (!validate.status) {
        return validate;
      }
      let web3 = validate.web3;
      let walletAddress = await validate.accounts
      let chain = await validate.chainId

      if(networkId != chain) {
        try{
          await window.ethereum.request({
              method: 'wallet_switchEthereumChain',
              params: [{chainId: networkId}]
            });
      } catch(switchError) {
          console.error('Error',switchError)
          return {
            message: `Switch Error: ${switchError.toString()}`
          }
        }
      }
    
      let erc20Contract = new web3.eth.Contract(erc20Abi, usdtAddress)

      let decimals = await erc20Contract.methods.decimals().call()

      let amount = (usdtAmount * 10 ** parseInt(decimals)).toLocaleString("fullwide", {useGrouping: false})
      let balance = await erc20Contract.methods.balanceOf(walletAddress).call();
      if (parseInt(balance) < parseInt(amount)) {
        return {
          status: false,
          message: `Insufficient balance. You need ${amount} tokens, but only have ${balance / 10 ** decimals}`
        };
      }
      console.log('aaaaaaa',amount)      
        let trx = await erc20Contract.methods.approve(pairContractAddress, amount)

        let gasPrice = await web3.eth.getGasPrice()
        let gasLimit = await web3.eth.estimateGas({
          gasPrice: web3.utils.toHex(gasPrice),
          to: usdtAddress,
          from: walletAddress,
          data: trx.encodeABI(),
        })
      
        const trxData = await web3.eth.sendTransaction({
          gasPrice: web3.utils.toHex(gasPrice),
          gas: web3.utils.toHex(gasLimit),
          to: usdtAddress,
          from: walletAddress,
          data: trx.encodeABI(),
        })
      
        return {
          status: true,
          hash: trxData.transactionHash
        }
    } catch(error) {
      console.log('Usdt approval error',error)
            return {
                status: false,
                message: `Usdt Approval error: ${error.toString()}`
            }
        }
    }


export const ApproveToken = async (req) => {
        try{
          const {walletProvider, tokenAddress, pairContractAddress, tokenAmount,networkId} = req
          const validate = await validateProvider(walletProvider)
          if (!validate.status) {
            return validate;
          }
          let web3 = validate.web3;
          let walletAddress = await validate.accounts
          let chain = await validate.chainId

          if(networkId != chain) {
            try{
              await window.ethereum.request({
                  method: 'wallet_switchEthereumChain',
                  params: [{chainId: networkId}]
                });
          } catch(switchError) {
              console.error('Error',switchError)
              return {
                message: `Switch error: ${switchError.toString()}`
              }
            }
          }
        
          let erc20Contract = new web3.eth.Contract(erc20Abi, tokenAddress)
        
          let decimals = await erc20Contract.methods.decimals().call()
           let  amount = (tokenAmount * 10 ** parseInt(decimals)).toLocaleString("fullwide", {useGrouping: false})
           let balance = await erc20Contract.methods.balanceOf(walletAddress).call();
           if (parseInt(balance) < parseInt(amount)) {
             return {
               status: false,
               message: `Insufficient balance. You need ${amount} tokens, but only have ${balance / 10 ** decimals}`
             };
           }
            let trx = await erc20Contract.methods.approve(pairContractAddress, amount)
            console.log('UDT',pairContractAddress,amount)
            let gasPrice = await web3.eth.getGasPrice()
            let gasLimit = await web3.eth.estimateGas({
              gasPrice: web3.utils.toHex(gasPrice),
              to: tokenAddress,
              from: walletAddress,
              data: trx.encodeABI(),
            })
          
            const trxData = await web3.eth.sendTransaction({
              gasPrice: web3.utils.toHex(gasPrice),
              gas: web3.utils.toHex(gasLimit),
              to: tokenAddress,
              from: walletAddress,
              data: trx.encodeABI(),
            })
          
            return {
              status: true,
              hash: trxData.transactionHash
            }
        } catch(error) {
          console.log('Approve token error',error)
                return {
                    status: false,
                    message: `Approve Token error: ${error.toString()}`
                }
            }
  }


export const AddLiquidity = async (req) => {
    try{
      const {walletProvider,pairContractAddress,tokenAddress, tokenAmount,networkId} = req
      const validate = await validateProvider(walletProvider)
      if (!validate.status) {
        return validate;
      }
      let web3 = validate.web3;
      let walletAddress = await validate.accounts
      let chain = await validate.chainId

      if(networkId != chain) {
        try{
          await window.ethereum.request({
              method: 'wallet_switchEthereumChain',
              params: [{chainId: networkId}]
            });
      } catch(switchError) {
          console.error('Error',switchError)
          return {
            message: switchError.toString()
          }
        }
      }
  
      let pairContract = new web3.eth.Contract(swapAbi,pairContractAddress)
      let erc20Contract = new web3.eth.Contract(erc20Abi,tokenAddress)
      let decimals = await erc20Contract.methods.decimals().call()

      let amountToken = (tokenAmount * 10 ** parseInt(decimals)).toLocaleString("fullwide", {useGrouping: false})
console.log('AAAAAA',amountToken)
      let isExists = await pairContract.methods.doesTokenUsdtPoolExists(tokenAddress).call()
      if (!isExists) {
        return {
          status: true,
          message: 'Pool does not exist'
        }
      }
  
      let trx = await pairContract.methods.addLiquidity(tokenAddress, amountToken)
  
      let gasPrice = await web3.eth.getGasPrice()
      let gasLimit = await web3.eth.estimateGas({
        gasPrice: web3.utils.toHex(gasPrice),
        to: pairContractAddress,
        from: walletAddress,
        data: trx.encodeABI(),
      })
  
      const trxData = await web3.eth.sendTransaction({
        gasPrice: web3.utils.toHex(gasPrice),
        gas: web3.utils.toHex(gasLimit),
        to: pairContractAddress,
        from: walletAddress,
        data: trx.encodeABI(),
      })
  
      return {
        status: true,
        hash: trxData.transactionHash,
        message: "Tokens added to liquidity pool successfully. It will take a few minutes to confirm the transaction."
      }
  
  
    } catch(error) {
      console.log("Error adding Liquidity",error)
      return {
        status: false,
        message: `Error adding liquidity: ${error.toString()}`
    }
    }
  }

export const RemoveLiquidity = async (req) => {
    try{
      const {walletProvider,pairContractAddress,tokenAddress, lpAmount,networkId} = req
      const validate = await validateProvider(walletProvider)
      if (!validate.status) {
        return validate;
      }
      let web3 = validate.web3;
      let walletAddress = await validate.accounts
      let chain = await validate.chainId

      if(networkId != chain) {
        try{
          await window.ethereum.request({
              method: 'wallet_switchEthereumChain',
              params: [{chainId: networkId}]
            });
      } catch(switchError) {
          console.error('Error',switchError)
          return {
            message: switchError.toString()
          }
        }
      }
  
      let contract = new web3.eth.Contract(swapAbi,pairContractAddress)
      let erc20Contract = new web3.eth.Contract(erc20Abi,tokenAddress)
      let decimals = await erc20Contract.methods.decimals().call()

      let amountLp = (lpAmount * 10 ** parseInt(decimals)).toLocaleString("fullwide",{useGrouping:false})

      let isExists = await contract.methods.doesTokenUsdtPoolExists(tokenAddress).call()
      if (!isExists) {
        return {
          status: true,
          message: 'Pool does not exist'
        }
      }
  
      let trx = await contract.methods.removeLiquidity(tokenAddress, amountLp)
  
      let gasPrice = await web3.eth.getGasPrice()
      let gasLimit = await web3.eth.estimateGas({
        gasPrice: web3.utils.toHex(gasPrice),
        to: pairContractAddress,
        from: walletAddress,
        data: trx.encodeABI(),
      })
  
      const trxData = await web3.eth.sendTransaction({
        gasPrice: web3.utils.toHex(gasPrice),
        gas: web3.utils.toHex(gasLimit),
        to: pairContractAddress,
        from: walletAddress,
        data: trx.encodeABI()
      })
  
      return {
        status: true,
        hash: trxData.transactionHash,
        message: "Tokens removed from liquidity pool successfully. It will take a few minutes to confirm the transaction."
      }
  
  
    } catch(error) {
      console.log('Error removing liquidty',error)
      return {
        status: false,
        message: `Error reemoving Liquidity: ${error.toString()}`
    }
    }
  }


export const ReceivableUsdt = async (req) => {
    try {
      let {tokenAmount, pairContractAddress, walletProvider,tokenAddress,networkId} = req
      //console.log('NNNN',networkId)
      const validate = await validateProvider(walletProvider)
      if(!validate.status) {
        return validate
      }
      let web3 = validate.web3
      let walletAddress = await validate.accounts
      let chain = await validate.chainId
      if(networkId != chain) {
          try{
            await window.ethereum.request({
                method: 'wallet_switchEthereumChain',
                params: [{chainId: networkId}]
              });
        } catch(switchError) {
            console.error('Error',switchError)
            return {
              message: switchError.toString()
            }
          }
        }

      let pairContract = new web3.eth.Contract(swapAbi,pairContractAddress)
      let erc20Contract = new web3.eth.Contract(erc20Abi,tokenAddress)
      let decimals =  await erc20Contract.methods.decimals().call()

      let amountToken = (tokenAmount * 10 ** parseInt(decimals)).toLocaleString("fullwide", {useGrouping: false})

        let result = await pairContract.methods.expectedUsdtToReceive(tokenAddress,amountToken).call()

        if(networkId == web3config.ChaiIdBinanceTestnet) {
            return [{
              staus: true,
              data: {
                Fees:Number(result.finalfee - result.returnAmountInUsdt) / 10**18,
                BuyBack:Number(result.returnAmountInUsdt) / 10**18,
                ToTreasuryOrPool:Number(result.toTreasuryOrPoolInUsdt)/10**18,
                USDT:Number(result.actualUsdt) / 10**18
              }
          }]
        } else {
          return [{
            staus: true,
            data: {
            Fees:Number(result.finalfee - result.returnAmountInUsdt) / 10**6,
            BuyBack:Number(result.returnAmountInUsdt) / 10**6,
            ToTreasuryOrPool:Number(result.toTreasuryOrPoolInUsdt)/10**6,
            USDT:Number(result.actualUsdt) / 10**6
          }
        }]
      }
    }   catch (error) {
      console.error("Error getting expected usdt",error);
    }
  }

export const SwapUsdtToToken = async (req) => {
    try{
      const {walletProvider,pairContractAddress,tokenAddress, usdtAddress,usdtAmount,networkId} = req
      const validate = await validateProvider(walletProvider)
      if (!validate.status) {
        return validate;
      }
      let web3 = validate.web3;
      let walletAddress =  await validate.accounts
      let chain = await validate.chainId

      if(networkId != chain) {
        try{
          await window.ethereum.request({
              method: 'wallet_switchEthereumChain',
              params: [{chainId: networkId}]
            });
      } catch(switchError) {
          console.error('Error',switchError)
          return {
            message: switchError.toString()
          }
        }
      }
  
  
      let swapContract = new web3.eth.Contract(swapAbi,pairContractAddress)
      let erc20Contract = new web3.eth.Contract(erc20Abi,tokenAddress)
      let UsdtContract = new web3.eth.Contract(erc20Abi,usdtAddress)

      let tokenDecimals = await erc20Contract.methods.decimals().call()
      let usdtDecimals = await UsdtContract.methods.decimals().call()

      let amountUsdt = (usdtAmount * 10 ** parseInt(usdtDecimals)).toLocaleString("fullwide", {useGrouping: false})


      let isExists = await swapContract.methods.doesTokenUsdtPoolExists(tokenAddress).call()
      if (!isExists) {
        return {
          message: 'Pool does not exist'
        }
      }
  
      let trx = await swapContract.methods.swapUstdToToken(tokenAddress,amountUsdt)
  
      let gasPrice = await web3.eth.getGasPrice()
      let gasLimit = await web3.eth.estimateGas({
        gasPrice: web3.utils.toHex(gasPrice),
        to: pairContractAddress,
        from: walletAddress,
        data: trx.encodeABI(),
      })
  
      const trxData = await web3.eth.sendTransaction({
        gasPrice: web3.utils.toHex(gasPrice),
        gas: web3.utils.toHex(gasLimit),
        to: pairContractAddress,
        from: walletAddress,
        data: trx.encodeABI(),
      })
  
      return {
        status: true,
        hash: trxData.transactionHash,
        message: "Usdt swapped succcessfully.It will take a few minutes to confirm the transaction "
      }
  
    } catch(error) {
      console.log("Usdt to Token swap error",error)
      return {
        status: false,
        message: `Usdt to token swap error: ${error.toString()}`
    }
    }
  }

export const ReceivableToken = async (req) => {
    try {
      const {usdtAmount, pairContractAddress, walletProvider,usdtAddress,tokenAddress,networkId} = req
      const validate = await validateProvider(walletProvider)
      if(!validate.status) {
        return validate
      }
  
      let web3 = validate.web3
      let walletAddress = await validate.accounts
      let chain = await validate.chainId

        if(networkId != chain) {
          try{
            await window.ethereum.request({
                method: 'wallet_switchEthereumChain',
                params: [{chainId: networkId}]
              });
        } catch(switchError) {
            console.error('Error',switchError)
            return {
              message: switchError.toString()
            }
          }
        }
      let pairContract = new web3.eth.Contract(swapAbi,pairContractAddress)
      let erc20Contract = new web3.eth.Contract(erc20Abi,usdtAddress)
      let decimals =  await erc20Contract.methods.decimals().call()

      let amountUsdt = (usdtAmount * 10 ** parseInt(decimals)).toLocaleString("fullwide", {useGrouping: false})
      let result = await pairContract.methods.expectedTokenToReceive(tokenAddress,amountUsdt).call()
  //     console.log('ANS',result)
      if (networkId == web3config.ChaiIdBinanceTestnet) {
        return [{
          staus: true,
          data: {
            Fees: Number(result.finalFee - result.returnAmountInUsdt)/10**18,
            BuyBack: Number(result.returnAmountInUsdt)/10**18,
            Token: Number(result.actualTokenToReceive)/10**18
          }
        }]
      } else {
          return [{
            staus: true,
            data: {
              Fees: Number(result.finalFee - result.returnAmountInUsdt)/10**6,
              BuyBack: Number(result.returnAmountInUsdt)/10**6,
              Token: Number(result.actualTokenToReceive)/10**18
            }
          }]
        }
    }   catch (error) 
        {
          console.log("Error getting expected token",error)
          return [{
          status:false
    }]
    }
  }

export const SwapTokenToUsdt = async (req) => {
    try{
      const {walletProvider,pairContractAddress,tokenAddress,tokenAmount,networkId,toTreasury} = req
      const validate = await validateProvider(walletProvider)
      if (!validate.status) {
        return validate;
      }
      let web3 = validate.web3;
      let walletAddress = await validate.accounts
      let chain = await validate.chainId

      if(networkId != chain) {
        try{
          await window.ethereum.request({
              method: 'wallet_switchEthereumChain',
              params: [{chainId: networkId}]
            });
      } catch(switchError) {
          console.error('Error',switchError)
          return {
            message: switchError.toString()
          }
        }
      }
  
      let swapContract = new web3.eth.Contract(swapAbi,pairContractAddress)
      let erc20Contract = new web3.eth.Contract(erc20Abi,tokenAddress)
      let decimals = await erc20Contract.methods.decimals().call()

      let amountToken = (tokenAmount * 10 ** parseInt(decimals)).toLocaleString("fullwide", {useGrouping: false})

      let isExists = await swapContract.methods.doesTokenUsdtPoolExists(tokenAddress).call()
      if (!isExists) {
        return {
          message: 'Pool does not exist'
        }
      }
  
      let trx = await swapContract.methods.swapTokenToUsdt(tokenAddress, amountToken,toTreasury)
  
      let gasPrice = await web3.eth.getGasPrice()
      let gasLimit = await web3.eth.estimateGas({
        gasPrice: web3.utils.toHex(gasPrice),
        to: pairContractAddress,
        from: walletAddress,
        data: trx.encodeABI(),
      })
  
      const trxData = await web3.eth.sendTransaction({
        gasPrice: web3.utils.toHex(gasPrice),
        gas: web3.utils.toHex(gasLimit),
        to: pairContractAddress,
        from: walletAddress,
        data: trx.encodeABI(),
      })
  
      return {
        status: true,
        hash: trxData.transactionHash,
        message: "Tokens swapped succcessfully.It will take a few minutes to confirm the transaction "
      }
  
  
    } catch(error) {
      console.log('token to usdt swap error',error)
      return {
        status: false,
        message: `Token to Usdt swap error: ${error.toString()}`
    }
    }
  }

export const getUserUsdtBalance = async (req) => {
  const {usdtContract, walletProvider,networkId} = req
    try {
        const validate = await validateProvider(walletProvider);
        if (!validate.status) {
            return validate;
        }
        let web3 = validate.web3;
        let walletAddress = await validate.accounts
        let chain = await validate.chainId

        if(networkId != chain) {
          try{
            await window.ethereum.request({
                method: 'wallet_switchEthereumChain',
                params: [{chainId: networkId}]
              });
        } catch(switchError) {
            console.error('Error',switchError)
            return {
              message: switchError.toString()
            }
          }
        }

        let contract = new web3.eth.Contract(erc20Abi, usdtContract);
        let balance = await contract.methods.balanceOf(walletAddress).call();

        if(networkId == web3config.ChaiIdBinanceTestnet) {
          return {
            status: true,
            balance: Number(balance) / 10 ** 18
          }
        } else {
           return {
              status: true,
              balance: Number(balance) / 10 ** 6
          }
      }
    } catch (error) {
      console.log("Error getting user usdt balance",error);
        return {
          message: error.toString()
        }
    }
};

export const getUserTokenBalance = async (req) => {
  const {walletProvider, tokenAddress,networkId} = req
  try {
      const validate = await validateProvider(walletProvider);
      if (!validate.status) {
          return validate;
      }
      let web3 = validate.web3;
      let walletAddress = await validate.accounts
      let chain = await validate.chainId

      if(networkId != chain) {
        try{
          await window.ethereum.request({
              method: 'wallet_switchEthereumChain',
              params: [{chainId: networkId}]
            });
      } catch(switchError) {
          console.error('Error',switchError)
          return {
            message: switchError.toString()
          }
        }
      }

      let erc20Contract = new web3.eth.Contract(erc20Abi, tokenAddress);
      let balance = await erc20Contract.methods.balanceOf(walletAddress).call();

      return {
          status: true,
          balance: Number(balance) / 10 ** 18
      }
  } catch (error) {
      console.log('Error getting user token balance',error);
  }
};

export const getTokenPriceSwap = async (req) => {
  try {
    let data = req
    let results = []
    for(let i = 0; i < data.length; i++) {
      const validate = await validateProvider2(data[i].chainRPCUrl)
      if(!validate.status) {
        return validate
      }
    let web3 = validate.web3
    let pairContract = new web3.eth.Contract(swapAbi,data[i].pairContractAddress)
    let contractUsdt = new web3.eth.Contract(erc20Abi,data[i].usdtContract)
    let decimals = await contractUsdt.methods.decimals().call()
    let trx = await pairContract.methods.getTokenPriceInUsdt(data[i].token_address).call()
    console.log('PO',data[i].pairContractAddress,data[i].usdtContract, data[i].token_address)
    let Price = Number(trx) / 10 ** parseInt(decimals).toLocaleString("fullwide", {useGrouping: false})
    //let chainExplorerUrl = data[i]/chainExplorerUrl
    let chainNetworkId = data[i].chainNetworkId
    results.push(Price)
    }
    return {
      status: true,
      data: results
    }
  }
     catch (error) {
    console.error('Error getting token price',error);
  }
}

export const getTokenSupplySwap = async (req) => {
  try {
    let data = req
    let results = []
    for(let i = 0; i < data.length; i++) {
      const validate = await validateProvider2(data[i].chainRPCUrl)
      if(!validate.status) {
        return validate
      }
    let web3 = validate.web3
    let contractToken = new web3.eth.Contract(erc20Abi,data[i].token_address)
    let decimals = await contractToken.methods.decimals().call()
    let trx = await contractToken.methods.totalSupply(data[i].token_address).call()
    console.log('LOO',trx)
    let Supply = Number(trx) / 10 ** parseInt(decimals).toLocaleString("fullwide", {useGrouping: false})
    results.push(Supply)
    }
    return {
      status: true,
      data: results
    }
  }
     catch (error) {
    console.error('Error getting token price',error);
  }
}



export const NewPool2 = async (req) => {
  try{
    const {walletProvider,pairContractAddress, usdtAddress, tokenAddress, tokenAmount,networkId} = req
    const validate = await validateProvider(walletProvider)
    if (!validate.status) {
      return validate;
    }
    let web3 = validate.web3;
    let walletAddress = await validate.accounts
    let chain = await validate.chainId

    if(networkId != chain) {
      try{
        await window.ethereum.request({
            method: 'wallet_switchEthereumChain',
            params: [{chainId: networkId}]
          });
    } catch(switchError) {
        console.error('Error',switchError)
        return {
          message: switchError.toString()
        }
      }
    }

    let contract = new web3.eth.Contract(swapAbi,pairContractAddress)
    let erc20Contract = new web3.eth.Contract(erc20Abi,usdtAddress)
    let decimals = await erc20Contract.methods.decimals().call()
    
    let amountToken = (tokenAmount * 10**18).toLocaleString("fullwide", {useGrouping: false})

    let isExists = await contract.methods.doesTokenUsdtPoolExists(tokenAddress).call()
    if (isExists) {
      return {
        message: 'Pool already exist'
      }
    }
console.log('check',tokenAddress,amountToken)
    let trx = await contract.methods.createPool2(tokenAddress, amountToken)

    let gasPrice = await web3.eth.getGasPrice()
    let gasLimit = await web3.eth.estimateGas({
      gasPrice: web3.utils.toHex(gasPrice),
      to: pairContractAddress,
      from: walletAddress,
      data: trx.encodeABI(),
    })

    const trxData = await web3.eth.sendTransaction({
      gasPrice: web3.utils.toHex(gasPrice),
      gas: web3.utils.toHex(gasLimit),
      to: pairContractAddress,
      from: walletAddress,
      data: trx.encodeABI()
    })

    return {
      status: true,
      hash: trxData.transactionHash,
      message: "Pool created successfully. It will take a few minutes to confirm the transaction."
    }


  } catch(error) {
    console.log('Error creating preliquidity pool',error)
    return {
      status: false,
      message: `Error creating preliquidity pool: ${error.toString()}`
  }
  }
}


export const PoolBalances = async (req) => {
  try {
    const {pairContractAddress, walletProvider,tokenAddress,networkId} = req
    const validate = await validateProvider(walletProvider)
    if(!validate.status) {
      return validate
    }

    let web3 = validate.web3
    let walletAddress = await validate.accounts
    let chain = await validate.chainId

    if(networkId != chain) {
      try{
        await window.ethereum.request({
            method: 'wallet_switchEthereumChain',
            params: [{chainId: networkId}]
          });
    } catch(switchError) {
        console.error('Error',switchError)
        return {
          message: switchError.toString()
        }
      }
    }

    let pairContract = new web3.eth.Contract(swapAbi,pairContractAddress)
      let result = await pairContract.methods.getTokenBalances(tokenAddress).call()
      if(networkId == web3config.ChaiIdBinanceTestnet) {
        return [{
          staus: true,
          data:{
          Token: Number(result.tokenABalance) / 10**18,
          USDT: Number(result.usdtBalance) / 10**18
          }
        }]
      } else {
      return [{
        staus: true,
        data:{
        Token: Number(result.tokenABalance) / 10**18,
        USDT: Number(result.usdtBalance) / 10**6
        }
      }]
    }
  }   catch (error) {
      console.log('Error getting pool token balance',error);
  }
}

export const PoolDetails = async (req) => {
  try {
    const {pairContractAddress, walletProvider,tokenAddress,networkId} = req
    const validate = await validateProvider(walletProvider)
    if(!validate.status) {
      return validate
    }

    let web3 = validate.web3
    let walletAddress = await validate.accounts
    let chain = await validate.chainId

    if(networkId != chain) {
      try{
        await window.ethereum.request({
            method: 'wallet_switchEthereumChain',
            params: [{chainId: networkId}]
          });
          window.location.reload()
    } catch(switchError) {
        console.error('Error',switchError)
        return {
          message: switchError.toString()
        }
      }
    }

    let pairContract = new web3.eth.Contract(swapAbi,pairContractAddress)
      let result = await pairContract.methods.getPoolDetails(tokenAddress).call()
      if(networkId == web3config.ChaiIdBinanceTestnet) {
        return {
          staus: true,
          Owner: result.PoolOwner,
          CurrentTokenPrice: Number(result.tokenPrice) /10**18
        }
      } else {
      return {
        staus: true,
        Owner: result.PoolOwner,
        CurrentTokenPrice: Number(result.tokenPrice) /10**6
      }
    }
  }   catch (error) {
    console.error(error);
  }
}

export const ReceivableAmountAfterRemovingLiquidity = async (req) => {
  try {
    const {pairContractAddress, walletProvider,tokenAddress,networkId,lpAmount} = req
    const validate = await validateProvider(walletProvider)
    if(!validate.status) {
      return validate
    }

    let web3 = validate.web3
    let walletAddress = await validate.accounts
    let chain = await validate.chainId

    if(networkId != chain) {
      try{
        await window.ethereum.request({
            method: 'wallet_switchEthereumChain',
            params: [{chainId: networkId}]
          });
    } catch(switchError) {
        console.error('Error',switchError)
        return {
          message: switchError.toString()
        }
      }
    }

    let pairContract = new web3.eth.Contract(swapAbi,pairContractAddress)
    let amount = lpAmount * 10**18
      let result = await pairContract.methods.expectedReturnsAfterRemovingLiquidity(tokenAddress,amount).call()
      if(networkId == web3config.ChaiIdBinanceTestnet) {
        return [{
          staus: true,
          data: {
          Token: Number(result.tokenAReturned)/10**18,
          USDT: Number(result.usdtReturned)/10**18,
          PENALTY: Number(result.penalty) /10**18
          }
        }]
      } else {
      return [{
        staus: true,
        data: {
        Token: Number(result.tokenAReturned)/10**18,
        USDT: Number(result.usdtReturned)/10**6,
        PENALTY: Number(result.penalty) /10**6
        }
      }]
    }
  }   catch (error) {
    console.log('Error getting return amount',error);
  }
}

export const LPBalance = async (req) => {
  try {
    const {pairContractAddress, walletProvider,tokenAddress,networkId} = req
    const validate = await validateProvider(walletProvider)
    if(!validate.status) {
      return validate
    }

    let web3 = validate.web3
    let walletAddress = await validate.accounts
    let chain = await validate.chainId

    if(networkId != chain) {
      try{
        await window.ethereum.request({
            method: 'wallet_switchEthereumChain',
            params: [{chainId: networkId}]
          });
    } catch(switchError) {
        console.error('Error',switchError)
        return {
          message: switchError.toString()
        }
      }
    }

    let pairContract = new web3.eth.Contract(swapAbi,pairContractAddress)
      let result = await pairContract.methods.getLPBalance(tokenAddress).call({ from: walletAddress})
      return {
        status: true,
        balance: Number(result)/10**18
      }
  }   catch (error) {
    console.log('Error getting lp token balance',error);
  }
}

export const UsdtAmount = async (req) => {
  try {
    const {pairContractAddress, walletProvider,tokenAddress,tokenAmount,networkId} = req
    const validate = await validateProvider(walletProvider)
    if(!validate.status) {
      return validate
    }

    let web3 = validate.web3
    let walletAddress = await validate.accounts
    let chain = await validate.chainId

    if(networkId != chain) {
      try{
        await window.ethereum.request({
            method: 'wallet_switchEthereumChain',
            params: [{chainId: networkId}]
          });
    } catch(switchError) {
        console.error('Error',switchError)
        return {
          message: switchError.toString()
        }
      }
    }

    let pairContract = new web3.eth.Contract(swapAbi,pairContractAddress)
    let erc20Contract = new web3.eth.Contract(erc20Abi,tokenAddress)
    let tokenDecimals = await erc20Contract.methods.decimals().call()
    let amountToken = (tokenAmount * 10 ** parseInt(tokenDecimals)).toLocaleString("fullwide", {useGrouping: false})

      let result = await pairContract.methods.getUsdtAmount(tokenAddress,amountToken).call()
      if(networkId == web3config.ChaiIdBinanceTestnet) {
        return {
          staus: true,
          Bal: Number(result) / 10**18
        }
      } else {
      return {
        staus: true,
        Bal: Number(result) / 10**6
      }
    }
  }   catch (error) {
    console.error(error);
  }
}

export const LpToMint = async (req) => {
  try {
    const {pairContractAddress, walletProvider,tokenAddress,tokenAmount,usdtAddress,usdtAmount,networkId} = req
    const validate = await validateProvider(walletProvider)
    if(!validate.status) {
      return validate
    }

    let web3 = validate.web3
    let walletAddress = await validate.accounts
    let chain = await validate.chainId

    if(networkId != chain) {
      try{
        await window.ethereum.request({
            method: 'wallet_switchEthereumChain',
            params: [{chainId: networkId}]
          });
    } catch(switchError) {
        console.error('Error',switchError)
        return {
          message: switchError.toString()
        }
      }
    }

    let pairContract = new web3.eth.Contract(swapAbi,pairContractAddress)
    let erc20Contract = new web3.eth.Contract(erc20Abi,tokenAddress)
    let UsdtContract = new web3.eth.Contract(erc20Abi,usdtAddress)
    let tokenDecimals = await erc20Contract.methods.decimals().call()
    let usdtDecimals = await UsdtContract.methods.decimals().call()

    let amountToken = (tokenAmount * 10 ** parseInt(tokenDecimals)).toLocaleString("fullwide", {useGrouping: false})
    let amountUsdt = (usdtAmount * 10 ** parseInt(usdtDecimals)).toLocaleString("fullwide", {useGrouping: false})

      let result = await pairContract.methods.calculateLPTokensToMint(tokenAddress,amountToken,amountUsdt).call()
      return {
        staus: true,
        Bal: Number(result) / 10**18
      }
  }   catch (error) {
    console.log('Error getting lp to mint',error);
  }
}

export const PriceCalculator = async (req) => {
  try {
    const {walletProvider,usdtAmount,pairContractAddress,tokenAmount,tokenAddress,usdtAddress,networkId} = req
    const validate = await validateProvider(walletProvider)
    if(!validate.status) {
      return validate
    }

    let web3 = validate.web3
    let walletAddress = await validate.accounts
    let chain = await validate.chainId

    if(networkId != chain) {
      try{
        await window.ethereum.request({
            method: 'wallet_switchEthereumChain',
            params: [{chainId: networkId}]
          });
    } catch(switchError) {
        console.error('Error',switchError)
        return {
          message: switchError.toString()
        }
      }
    }

    let pairContract = new web3.eth.Contract(swapAbi,pairContractAddress)
    let erc20Contract = new web3.eth.Contract(erc20Abi,tokenAddress)
    let tokenDecimals = await erc20Contract.methods.decimals().call()
    let UsdtContract = new web3.eth.Contract(erc20Abi,usdtAddress)
    let usdtDecimals = await UsdtContract.methods.decimals().call()

    let amountToken = (tokenAmount * 10 ** parseInt(tokenDecimals)).toLocaleString("fullwide", {useGrouping: false})
    let amountUsdt = (tokenAmount * 10 ** parseInt(usdtDecimals)).toLocaleString("fullwide", {useGrouping: false})

      let result = await pairContract.methods.tokenPriceCalculator(amountToken,amountUsdt).call()
      if(networkId == web3config.ChaiIdBinanceTestnet) {
        return {
          staus: true,
          Price: Number(result) / 10**18
        }
      } else {
      return {
        staus: true,
        Price: Number(result) / 10**6
      }
    }
  }   catch (error) {
    console.error(error);
  }
}


export const RemovalStatus = async (req) => {
  try{
    const {walletProvider, pairContractAddress,tokenAddress,networkId} = req
    const validate = await validateProvider(walletProvider)
    if (!validate.status) {
      return validate;
    }
    let web3 = validate.web3;
    let walletAddress = await validate.accounts
    let chain = await validate.chainId

    if(networkId != chain) {
      try{
        await window.ethereum.request({
            method: 'wallet_switchEthereumChain',
            params: [{chainId: networkId}]
          });
    } catch(switchError) {
        console.error('Error',switchError)
        return {
          message: switchError.toString()
        }
    }
  }

    let contractSwap = new web3.eth.Contract(swapAbi,pairContractAddress)

    let trx = await contractSwap.methods.removalStatus(tokenAddress).call()

    return {
      status:trx
    }

  } catch(error) {
    return {
      status: false,
      message: error.toString()
  }
  }
}



const validateProvider2 = async (walletProvider) => {
  console.log("validateProvider2");
  try {
    if (!walletProvider) {
      console.log("walletProvider");

      return {
        status: false,
        message: `Please connect your web3 wallet`,
      };
    }
    const web3 = new Web3(walletProvider);

    return {
      status: true,
      web3
    };
  } catch (error) {
    console.log("Error in validateProvider :", error);
    return {
      status: false,
      message: "Error in validating provider. Please try again.",
    };
  }
};