import React, { useState, useEffect } from "react";
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { getActiveChains,submitInfiApplicationForm,getAllPairListed } from "../../Action/action";
import { checkContractOnChain } from "../../Web3/web3FunctionCall";
import toast, { Toaster } from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import config from "../../config";
import { useDisconnect, useAccount } from "wagmi";
import {PriceCalculator} from '../../Web3/Liquidity/infiSwapConnector'
import Web3WalletProvider from '../../ConnectWallet/walletProvider'


export default function InfiListingForm() {

  const [liqForm, setLiqForm] = useState({
    tokenAddress: "",
    liquidityValue: "",
    infiAmount:"",
//    estimatedPrice: "",
    agree: false,
});

  const [form, setForm] = useState([])
  //const [price,setPrice] = useState(0)
  const [errors, setErrors] = useState({});
  const [formErr, setFormErr] = useState('')
  const [chainOptions, setChainOptions] = useState([]);
  const [imagePreview, setImagePreview] = useState(null);
  const loginDetails = useSelector((state) => state.auth.LoginDetails);
  const navigate = useNavigate();
  const {address, isConnected} = useAccount()
  let _useWeb3WalletProvider = Web3WalletProvider()

  useEffect(() => {
    getChains()
  }, []);

  const getChains = async () => {
    try {
      const response = await getActiveChains(loginDetails);
      if (response.success) {
        setChainOptions(response.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleInputChange = async (e) => {
    const { name, value } = e.target;
    setLiqForm({...liqForm,[name]:value})

    if (name === "tokenAddress" ) {
      await formData(value)
    }
  }
    const formData = async (tokenAddress) => {
            try {
              const response = await getAllPairListed({tokenAddress})
              console.log('all FORM',response)
              if (response.success && response.data && response.data.length > 0) {
                setForm(response.data)
                setFormErr("")
              } else {
                setForm([])
                setFormErr('No data found for this token address. Please Note this listing is exclusively for Polygon Users. You must create token pair with USDT on Polygon network before creating pair with INFI')
              }
            } catch (error) {
              console.log(error)
            }
        }
    
    // const estimatedPrice = async (liquidityValue, infiAmount) => {
    //     try {
    //       let request = {
    //         walletProvider:  _useWeb3WalletProvider,
    //         tokenOwner: address,
    //         tokenAddress:form[0].token_address,
    //         tokenAmount:liquidityValue,
    //         infiAddress: form[0].infiContract,
    //         infiAmount:infiAmount,
    //         infipairContractAddress:form[0].infiPairContract,
    //         networkId:form[0].chainNetworkId
    //       }
    //       console.log('qqqqqqqQQ',request)
    //       const pp = await PriceCalculator(request)
    //       if (pp.status) {
    //         setForm(pp.Price)
    //       }
    //     } catch (error) {
    //       console.log(error)
    //     } 
    // }


  const handleFileChange = (e) => {
    setLiqForm({
      ...liqForm,
      logo: e.target.files[0],
    });
    setImagePreview(URL.createObjectURL(e.target.files[0]));
  };

  const handleCheckboxChange = (e) => {
    setLiqForm({
      ...liqForm,
      agree: e.target.checked,
    });
  };

  const validateForm = async () => {
    const newErrors = {};
    const urlPattern =
      /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?$/;
    if (!liqForm.tokenAddress)
      newErrors.tokenAddress = "Token Address is required";
    if (!liqForm.liquidityValue) {
      newErrors.liquidityValue = "Liquidity value is required";
    }  else if (liqForm.liquidityValue < 0) {
      newErrors.liquidityValue = "Liquidity value cannot be negative";      
    }
    if (!liqForm.infiAmount) {
      newErrors.infiAmount = "INFI amount is required";
    } else if (liqForm.infiAmount < 0) {
      newErrors.infiAmount = "Value cannot be negative";      
    }
    if (!liqForm.agree) newErrors.agree = "You must agree to the terms";

    // let result = await checkContractOnChain(getRpc, liqForm.tokenAddress);
    // console.log('pp',result)

    // if (!result.status) newErrors.tokenAddress = `${result.msg}`;

    console.log(newErrors)
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
}
   
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (Object.keys(loginDetails).length === 0) {
      navigate(`${config.baseUrl}login`)
    } else {

    if(!isConnected) {
      toast.error("Please connect with the registered wallet.");
      return
    }
    if(address.toUpperCase() != loginDetails.walletAddress.toUpperCase()) {
      toast.error("Please connect with registered wallet")
      return
    }

     if (await validateForm()) {
       console.log("Liquidity Form submitted", form);
       form[0].ownerId=loginDetails.id;
       form[0].infi_amount = liqForm.infiAmount
       form[0].liquidity = liqForm.liquidityValue
       //console.log('FFFF',form[0])
       let result = await submitInfiApplicationForm(form[0],loginDetails)
       if(result.success){
        toast.success(result.msg);
        setTimeout(() => {
          navigate(`${config.baseUrl}home`);
        }, 2000);
       }else{
        toast.error(result.msg)
       }

   } else {
       toast.error("Please check the validations required.");
     }
  }
  };

  return (
    <>
      <Toaster />
      <Header />
      <section className="process_form">
        <Container>
           <Form >
            <div className="from_box my-5">
              <h4 style={{fontStyle:"italic", fontSize:"15px"}}>*This listing is exclusively for Polygon Users. You must create token pair with USDT on polygon network before creating pair with INFI* Click <a href={`${config.baseUrl}liquidityListingForm`} target="_blank" rel="noopener noreferrer">here</a></h4>
              <h2>The Launch process:</h2>
              <p>
                Must be confirmed with the Owner wallet (the tokens will be
                transferred to the INFI CDEX Launchpool)
              </p>

              <p
                style={{
                  textAlign:"right",
                  fontSize: "12px",
                  fontStyle: "italic",
                  marginTop:"-10px"
                }}
              >Fields marked with * are compulsory</p>
              <Row className="mt-4">
              <Col lg={6}>
                  <Form.Label>Token Address *</Form.Label>
                  <Form.Control
                    type="text"
                    name="tokenAddress"
                    value={liqForm.tokenAddress}
                    onChange={handleInputChange}
                    isInvalid={!!errors.tokenAddress || !!formErr}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.tokenAddress || formErr}
                  </Form.Control.Feedback>
                </Col>

                {form.length > 0 &&  <Col lg={6}>
                  <Form.Label>Token Name *</Form.Label>
                  <Form.Control
                    type="text"
                    name="tokenName"
                    value={form[0].token_name}
                    onChange={handleInputChange}
                    disabled
                  />
                </Col>}

                 {form.length > 0 && <Col lg={6}>
                  <Form.Label>Chain Network *</Form.Label>
                  <Form.Control
                    type="text"
                    name="chainName"
                    value={form[0].chainName}
                    onChange={handleInputChange}
                    disabled
                  />
                </Col>}

                {form.length > 0 && <Col lg={12} className="mt-4">
                  <Form.Label>Description *</Form.Label>
                  <Form.Control
                    as="textarea"
                    name="description"
                    placeholder="max 1000 characters"
                    value={form[0].description}
                    onChange={handleInputChange}
                    disabled
                  />
                </Col>}

                {form.length > 0 && <Col lg={12} className="mt-4">
                  <Form.Label> Brand Logo </Form.Label>
                  <div className="position-relative">
                        <img
                          src={`${config.imagePath}${form[0].brand_logo}`}
                          alt="logo"
                          style={{ maxHeight: "200px", maxWidth: "100%" }}
                        />
                      </div>
                      </Col>}

                {form.length > 0 && <Col lg={6} className="mt-4">
                  <Form.Label>ADD KYC</Form.Label>
                  <Form.Control
                    type="text"
                    name="kyc"
                    value={form[0].kyc}
                    onChange={handleInputChange}
                    disabled
                  />
                </Col>}

                {form.length > 0 && <Col lg={6} className="mt-4">
                  <Form.Label>ADD Audit</Form.Label>
                  <Form.Control
                    type="text"
                    name="audit"
                    value={form[0].audit}
                    onChange={handleInputChange}
                    disabled
                  />
                </Col>}

              <Row className="mt-4">
                <h5>Tokenomics</h5></Row>

                <Col lg={6} className="mt-4">
                  <Form.Label>Token Liquidity *</Form.Label>
                  <Form.Control
                    type="text"
                    name="liquidityValue"
                    placeholder="Value"
                    value={liqForm.liquidityValue}
                    onChange={handleInputChange}
                    isInvalid={!!errors.liquidityValue}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.liquidityValue}
                  </Form.Control.Feedback>
                </Col>
                
                <Col lg={6} className="mt-4">
                  <Form.Label>INFI Liquidity Amount *</Form.Label>
                  <Form.Control
                    type="text"
                    name="infiAmount"
                    placeholder="Value"
                    value={liqForm.infiAmount}
                    onChange={handleInputChange}
                    isInvalid={!!errors.infiAmount}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.infiAmount}
                  </Form.Control.Feedback>
                </Col>
                <Col lg={6} className="mt-4">
                </Col>

                {/* <Col lg={6} className="mt-4">
                  <Form.Label>Estimated Token Price in INFI</Form.Label>
                  <Form.Control
                    type="text"
                    name="price"
                    placeholder="Value"
                    value={liqForm.price || ""}
                    onChange={handleInputChange}
                    disabled
                  />
                </Col> */}

                <Row className="mt-4">
                 {form.length > 0 && <Col lg={12}>
                  <Form.Label>Whitepaper *</Form.Label>
                  <Form.Control
                    type="text"
                    name="whitepaper"
                    placeholder="Add a whitepaper link here."
                    value={form[0].whitepaper}
                    onChange={handleInputChange}
                    disabled
                  />
                </Col>}
                </Row>
            
                  </Row>
              <div className="mt-5">
                <h6>
                  IMPORTANT: Special Liquidity Management SpecialLM - must agree *
                </h6>
                <p>
                  (Here is the description for the project owners, what they
                  have to agree upon prior to listing their project. This will
                  be a contract between INFI CDEX and the starting project that
                  needs to be <b>AGREE</b> and <b>CONFIRM</b> by the project
                  owner)
                </p>
              </div>

              <div className="d-flex align-items-baseline">
                <Form.Check
                  className="me-2"
                  checked={form.agree}
                  onChange={handleCheckboxChange}
                  isInvalid={!!errors.agree}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.agree}
                </Form.Control.Feedback>
                <span>
                  Project owners must lock liquidity for a minimum of six
                  months, during which the liquidity remains inaccessible.
                  Safeguards are in place for companies to investigate and halt
                  trading if a scam or suspicious transaction is spotted. In
                  case of a confirmed scam, investors take precedence, impacting
                  the locked liquidity. Post the lock period, project owners
                  need approval from INFI ADMIN to access the liquidity, and
                  must justify their request
                </span>
              </div>

              <hr />

              <div className="mt-4 text-center">
                <Button variant="dark" className="px-4 py-2"onClick={e=>{handleSubmit(e)}} >
                  Submit
                </Button>
                <p className="mt-2">
                  Never submit password or credit card details through workform
                </p>
              </div>
            </div>
          </Form>
      </Container>
      </section>
      <Footer />
    </>
  );
}