module.exports = {
  ChainIdMatic: '0x13882',
  ChaiIdEthereum: '0xaa36a7',
  ChaiIdBinance: '0x61',
  ChaiIdAvalanche: '0xa869',
  ChaiIdArbitrum: '0x66eed',
  ChainIdMaticTestnet: '0x13882',
  ChaiIdEthereumTestnet: '0xaa36a7',
  ChaiIdBinanceTestnet: '0x61',
  ChaiIdAvalancheTestnet: '0xa869',
  ChaiIdArbitrumTestnet: '0x66eed',
  PROJECTID: '15542c28ab0bb325c503e39971a06f2c',
};
