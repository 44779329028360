import React from 'react';
import Header from '../Layout/Header';
import Footer from '../Layout/Footer';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Row, Col } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import config from '../../config';

export default function Howitwork() {
  const loginDetails = useSelector((state) => state.auth.LoginDetails);
  const navigate = useNavigate();

  if (!loginDetails) {
    navigate(`${config.baseUrl}`);
  }
  return (
    <>
      <Header />

      <section className='howitwork py-4'>
        <Container>
          <Row>
            <Col lg={12}>
              <div>
                <h1>How it works? </h1>
                <div>
                  To launch your cryptocurrency-based project, please fill out the form and either list your project
                  directly or raise funds & launch on the INFI MultiChain CDEX Exchange. You can choose from six
                  different blockchains including Polygon, Binance Smart Chain, Ethereum, Solana, Arbitrum and
                  Avalanche.
                </div>
                <div className='mt-3'>
                  <h5 className='mb-0'>Please note:</h5>
                  <ul>
                    <li>Listing or launching is subject to your agreement to our Special Liquidity Management
                      (SpecialLM) feature.</li>
                    <li>Project owners must lock up liquidity for at least six months, making it inaccessible. Safeguards
                      allow companies to investigate and stop trading in the event of fraud or suspicious transactions.
                      If fraud is confirmed, investors will be prioritised and the locked liquidity will be affected. After
                      the freeze period, project owners need INFI ADMIN approval and must justify their request to
                      access locked.</li>
                    <li>We delist projects only if scammed or project owner wants to delist. Delisting fees attract 5% of
                      the total liquidity in USDT.</li>
                  </ul>
                </div>
                <div>
                  <h1>Benefits of launching on the INFI MultiChain CDEX Exchange </h1>
                  <p>The entire ecosystem is governed by our proprietary ©SbSe Protocol, recognised as a self-build
                    smart ecosystem.</p>
                  <div className='mt-3'>
                    <ul>
                      <li><b>Transparency</b> is a cornerstone of the INFI MultiChain CDEX Exchange, exemplified by the SbSe
                        PFP pool, where corporate profits from exchange platform fees, digital trading and transaction
                        fees are allocated. This pool rewards INFI holders with a 60% share of profits on a monthly
                        basis, distributed in Stable-Coins and Bitcoin. In addition, our commitment to transparency is
                        underscored by the <b>SbSe Smart Watch Account</b>, which gives our KYC and audit firm partners
                        access to critical informations.</li>
                      <li><b>Anti-price manipulation, price volatility, fee structure and rewards</b> are protected by several
                        specialised formulas built into the protocol. This feature monitors trading volumes accumulated
                        on the INFI CDEX exchange and informs traders of the maximum and minimum allocations
                        allowed for trading. Fee structures are set and regulated by the protocol, which also rewards
                        traders after each transaction, providing them with monthly passive income in Stable-Coins and
                        Bitcoin.</li>
                      <li>Another important aspect of the protocol is its ability to isolate <b>fraudulent transactions and
                        scams</b>, supported by our real-time cyber security system and <b>Special Liquidity Management</b>
                        feature. This provides a 48-hour compensation plan for investors, giving them peace of mind
                        about their investments. In conjunction with the INFI Launchpad, this feature allows
                        cryptocurrency-based projects to launch with no fees, a choice of six different blockchains, and
                        without the need for verification by other KYC firms. We are committed to supporting each
                        startup with in-house marketing and PR, project development, smart contracts and dApps at
                        very competitive rates. We also offer KYC and audit services as required.</li>
                      <li>Traders can access INFI MultiChain CDEX Exchange with their external wallets. We don't store
                        anyone's wallet keys or digital assets</li>
                      <li>We are committed to supporting each startup with in-house marketing and PR, project
                        development, smart contracts and dApps at very competitive rates. We also offer KYC and audit
                        services if required.</li>
                    </ul>

                  </div>
                </div>
              
                <div className='mt-3'>
                  <h1>Benefits When launching on the Polygon network </h1>
                  <p>Project owners will have the opportunity to list their token or coin with a trading pair of USDT and/
                    or INFI. The community will receive stable coin rewards after each transaction, in addition to the
                    potential for monthly passive income derived from company profits. This income will vary based
                    on the amount of staked INFI (our native token) that members have accumulated during their
                    trading period. To qualify for monthly passive income it is essential to accumulate at least 10
                    staked INFI during the month. Or maximize your profit by buy and stake at least 10 INFI. The plus
                    staked INFI allocations received during the trading period can be merged with the staked INFI
                    allocation.</p>
                </div>
                <div className='mt-3'>
                  <h1>Benefits launching on any other network (excluding Polygon
                    network)  </h1>
                  <p>Project owners have the opportunity to list their token or coin with one trading pair, USDT only.
                    The community will receive Stable Coin rewards after each transaction, but will not be able to
                    accumulate staked INFI during the trading period. However, INFI can be purchased and staked
                    directly from the INFI MultiChain CDEX Exchange or ICO.</p>
                </div>

                <div className="mt-3">
  <h1>What is Raise Funds?</h1>
  <p>
    <strong>Raise Funds</strong> is an intuitive presale platform that empowers project owners to raise capital by offering their tokens to investors prior to official listing on the <em>Infi Cdex</em>. 
    This mechanism provides an opportunity for projects to attract early supporters and secure essential funding before entering the broader market.
  </p>

  <p>
    Project owners are required to complete a carefully designed submission form, providing all critical project information, including the number of tokens they plan to offer in the presale. 
    Additionally, they can specify the <strong>price per token</strong>, set the <strong>duration of the presale</strong>, and define a <strong>tentative hard cap</strong> (in USD) representing the maximum amount they aim to raise.
  </p>

  <p>
    To streamline the post-presale process, all funds raised in <strong>USDT</strong> will be automatically directed into a liquidity pool, facilitating seamless token liquidity at the time of listing. 
    This ensures smoother market operations and eliminates the need for additional liquidity management by the project owners.
  </p>

  <p>
    In the event that the project fails to meet its milestones or commitments, all contributors will receive a full <strong>USDT refund</strong>. 
    Moreover, project owners will retain the ability to withdraw any unsold tokens, ensuring a fair and transparent process for both investors and project teams.
  </p>
</div>

<div className="mt-3">
  <h1>What is List Your Project?</h1>
  <p>
    <strong>List Your Project</strong> provides a streamlined process for project owners to directly list their tokens on the <em>Infi Cdex</em>. 
    This feature enables projects to quickly enter the market by ensuring all necessary information and liquidity requirements are fulfilled efficiently.
  </p>

  <p>
    Project owners are required to complete a well-structured form, providing essential project details along with the amount of <strong>tokens and USDT</strong> they intend to add to create the initial liquidity pool.
    This ensures the token will have sufficient liquidity to support smooth trading once listed.
    The price of the token will be determined by the amount of tokens and usdt in the liquidity pool.
  </p>

  <p>
    For projects that have already conducted a <strong>presale</strong>, the listing process is further simplified. 
    In such cases, project owners only need to specify the amount of tokens they wish to allocate to the liquidity pool, as the remaining information—such as USDT liquidity and other project details—will be automatically pre-filled from the presale data.
  </p>

  <p>
    Upon <strong>admin approval</strong>, the token will become available for users to swap, marking the project’s official entry into the decentralized exchange. 
    This smooth transition ensures an efficient and transparent listing experience for both project teams and investors.
  </p>
</div>

<div className="mt-3">
  <h1>What is Special Infi Listing?</h1>
  <p>
    <strong>Special Infi Listing</strong> offers an exclusive opportunity for project owners to enhance their token liquidity by pairing it with the <strong>INFI token</strong> and listing it on the <em>Infi Cdex</em>. 
    This listing option allows projects to diversify their liquidity pools, promoting deeper market integration and increasing trading flexibility for users.
  </p>

  <p>
    However, this listing feature is available only to projects that are <strong>already listed</strong> on the Infi Cdex with USDT liquidity on the <strong>Polygon Network</strong>. 
    It ensures that only established projects with a proven track record can participate, providing added security and stability to the platform’s ecosystem.
  </p>
</div>

                
              </div>
            </Col>
          </Row>
        </Container>

      </section>
      <Footer />
    </>
  );
}
