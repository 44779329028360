import React, { useEffect, useState, useRef } from "react";
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
import { useSelector } from "react-redux";
import { useNavigate} from "react-router-dom";
import { Container, Row, Col, Form,Button,Table, Modal } from 'react-bootstrap';
import "./detail.css"
import { getAllPresaleListedByAddress,submitWithdrawRequest } from "../../Action/action"
import Web3WalletProvider from "../../ConnectWallet/walletProvider"
import { useDisconnect, useAccount } from "wagmi";
import { UpdateSaleDuration,StartSale, StopSale,WithdrawTokens,WithdrawStatus,SaleStatus,TokenSaleDuration} from "../../Web3/Connector"
import { toast, Toaster } from 'react-hot-toast';
import "../Detail/detail.css"
import { showLoader,hideLoader } from "../loader";




export default function Dashboard() {
    const [request, setRequest] = useState({
        reason: "",
        duration: "",
        agree: false,
    });
    const [errors, setErrors] = useState({});

    const [form, setForm] = useState([]);
    const loginDetails = useSelector((state) => state.auth.LoginDetails);
    const walletConnnected = useSelector((state) => state.auth.walletConnnected);
    const navigate = useNavigate();
    const _useWeb3WalletProvider = Web3WalletProvider()
    const { address, isConnected } = useAccount()
    const [connectWalletNow, setconnectWalletNow] = useState(false);
    const [Waddress, setAddress] = useState("");
    const _useDisconnect = useDisconnect()
    const [isProviderLoaded, setisProviderLoaded] = useState(false);
    const [loader, setLoader] = useState(false);
    // -------------------
    const [show, setShow] = useState(false);
    const [withdraw, setWithdraw] = useState(false);
    const[selectedItem,setItem] = useState({})

    const handleClose = () => setShow(false);
    const handleShow = (e,item) =>  {
        e.preventDefault()
        setItem(item)
        setShow(true);
    }

    const handleClose2 = () => setWithdraw(false);
    const handleShow2 = (e,item) => {
        e.preventDefault()
        setItem(item)
        setWithdraw(true);
    }

    useEffect(() => {
        console.log(isConnected, "IsConnect")
        console.log(address, "ADDRESS")
        console.log(walletConnnected, "WALLET")
        console.log(loginDetails.walletAddress,'LOG')

        if (walletConnnected) {
            setconnectWalletNow(false)
        }
        setAddress(address)
    }, [walletConnnected])

    const disconnectWallet = () => {
        _useDisconnect.disconnect()
    }

    useEffect(() => {
        if (!isProviderLoaded) {
            _useWeb3WalletProvider.then((data) => {
                if (data) {
                    setisProviderLoaded(true)
                }
            })
        }
    }, [_useWeb3WalletProvider])
    const buttonRef = useRef(null); // Add a ref for the button

    useEffect(() => {
        // AutoBNBally click the button after 2 seconds
        const timer = setTimeout(() => {
            if (buttonRef.current) {
                buttonRef.current.click();
            }
        }, 2000);

        return () => clearTimeout(timer); // Cleanup the timer
    }, []);

    useEffect(() => {
        setLoader(true)
        let result = getFormData()
        setLoader(false)
    }, [])

    const getFormData = async () => {
        try {
            let walletAddress = await loginDetails.walletAddress
            const response = await getAllPresaleListedByAddress({walletAddress});
            console.log('FORM', response)
            if (response.success) {
                if (response.data.length > 0) {

                    let dResult = await checkDuration(response.data[0])
                    response.data[0].newD = dResult

                    let withdraw = await withdrawStat(response.data[0])
                    response.data[0].canWithdraw = withdraw

                    let status = await saleStat(response.data[0])
                    response.data[0].isActive = status
            }
            setForm(response.data)
                }
        } catch (error) {
            console.log(error);
        }
    }


    const checkDuration = async (formData) => {
        let request = {
            walletProvider: formData.chainRPCUrl,
            tokenAddress: formData.token_address,
            preSaleAddress: formData.presaleContract,
            networkId: formData.chainNetworkId
        }
        //console.log('TRE', request)
        let endTime = await TokenSaleDuration(request)
        let currentTime = Math.floor(Date.now() / 1000);
        //console.log('TIME', (Number(endTime.duration)), currentTime)
        return currentTime > Number(endTime.duration)
    }


    const validateRequest = async () => {
        const newErrors = {}
        if(!request.reason) newErrors.request = 'Reason is required';
        setErrors(newErrors)
        return Object.keys(newErrors).length === 0;
    }

    const validateDuration = async () => {
        const newErrors = {}
        if(!request.duration) newErrors.duration = 'Duration is required';
        setErrors(newErrors)
        return Object.keys(newErrors).length === 0;
    }

    const handleInputChange = async(e)  => {
        const {name, value} = e.target;

        setRequest ({
            ...request,
            [name]:value
        })

        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]:''
        }))
    }

    const handleStartSale = async (item) => {
        if(!isConnected) {
            toast.error("Please connect with the registered wallet.");
            setconnectWalletNow(true)
            return
          }
          if(address.toUpperCase() != loginDetails.walletAddress.toUpperCase()) {
            toast.error("Please connect with registered wallet")
            return
          }
        showLoader()
        try {
            let request = {
                walletProvider:  _useWeb3WalletProvider,
                tokenAddress:item.token_address,
                preSaleAddress:item.presaleContract,
                networkId:item.chainNetworkId
              }

              let res = await StartSale(request)
              if (res.status) {
                toast.success(res.message)
              } else {
                toast.error(res.message)
              }
        } catch (error) {
            console.log('Start Error',error)
            toast.error(error)
        } finally {
            hideLoader()
        }
    }

    const handleStopSale = async (item) => {
        if(!isConnected) {
            toast.error("Please connect with the registered wallet.");
            setconnectWalletNow(true)
            return
          }
          if(address.toUpperCase() != loginDetails.walletAddress.toUpperCase()) {
            toast.error("Please connect with registered wallet")
            return
          }
        showLoader()
    try {
        let request = {
            walletProvider:  _useWeb3WalletProvider,
            tokenAddress:item.token_address,
            preSaleAddress:item.presaleContract,
            networkId:item.chainNetworkId
          }

          let res = await StopSale(request)
          if (res.status) {
            toast.success(res.message)
          } else {
            toast.error(res.message)
          }
    } catch (error) {
        console.log('Stop Error',error)
        toast.error(error)
    } finally {
        hideLoader()
    }
}

const handleWithdrawal = async (e) => {
    e.preventDefault()
    if(!isConnected) {
        toast.error("Please connect with the registered wallet.");
        setconnectWalletNow(true)
        return
      }
      if(address.toUpperCase() != loginDetails.walletAddress.toUpperCase()) {
        toast.error("Please connect with registered wallet")
        return
      }
    showLoader()
try {
    let request = {
        walletProvider:  _useWeb3WalletProvider,
        tokenAddress:form[0].token_address,
        preSaleAddress:form[0].presaleContract,
        networkId:form[0].chainNetworkId
      }

      let res = await WithdrawTokens(request)
      if (res.status) {
        toast.success(res.message)
      } else {
        toast.error(res.message)
      }
} catch (error) {
    console.log('Stop Error',error)
    toast.error(error)
} finally {
    hideLoader()
}
}

const withdrawStat = async (formData) => {
    try {
        let request = {
            walletProvider: formData.chainRPCUrl,
            tokenAddress: formData.token_address,
            preSaleAddress: formData.presaleContract,
            networkId: formData.chainNetworkId
        }
        let status = await WithdrawStatus(request)
        console.log('withdraw?', status)
        return status
    } catch (error) {
        console.log(error)
    }
}

const saleStat = async (formData) => {
    try {
        let request = {
            walletProvider: formData.chainRPCUrl,
            tokenAddress: formData.token_address,
            preSaleAddress: formData.presaleContract,
            networkId: formData.chainNetworkId
        }
        let status = await SaleStatus(request)
        console.log('active?', status)
        return status
    } catch (error) {
        console.log(error)
    }
}


const handleWithdrawRequest = async (e) => {
        e.preventDefault()
        if(await validateRequest()) {
            let data = {
               owner_id:loginDetails.id,
               token_name: selectedItem.token_name,
               token_address:selectedItem.token_address,
               reason: request.reason,
               chain: selectedItem.chain
            }
            console.log('SUB',data)
        let result = await submitWithdrawRequest(data,loginDetails)
            setWithdraw(false)
            setRequest({reason:""})
        console.log('SUB',result)
        if (result.success) {
            toast.success(result.msg)
        } else {
            toast.error(result.msg)
        } 
        } else {
            toast.error("Please check validations required")
     }
    }

const handleUpdateDuration = async (e) => {
    e.preventDefault()
    if(!isConnected) {
        toast.error("Please connect with the registered wallet.");
        setconnectWalletNow(true)
        return
      }
      if(address.toUpperCase() != loginDetails.walletAddress.toUpperCase()) {
        toast.error("Please connect with registered wallet")
        return
      }
        if(await validateDuration()) {
            showLoader()
        try {
        let input = {
          walletProvider:  _useWeb3WalletProvider,
          tokenAddress:selectedItem.token_address,
          preSaleAddress:selectedItem.presaleContract,
          duration:request.duration,
          networkId:selectedItem.chainNetworkId
        }       
          let res = await UpdateSaleDuration(input)
          setShow(false)
          setRequest({duration:""})
          //console.log('FORMRES',res)
          if (res.status) {
            toast.success (res.message)
          } else {
          toast.error(res.message)
          }
    } catch (error) {
      console.log('Error Updating duration',error)
      alert('Duration Update Error',error)
    } finally {
        hideLoader()
    }
        }  else {
         toast.error("Please check validations required")
            }
    
    }


    return (
        <>
            <Header />
            <Toaster></Toaster>
            <div className="detail process_form my-5" style={{ height: "500px" }}>
                <Container>
                    <Row>
                        <Col lg={12}>
                            <div className="mt-0 mb-4">
                                <h3 className="mb-4">Settings</h3>
                                <Table striped bordered hover responsive>
                                    <thead>
                                        <tr>
                                            <th>S.No</th>
                                            <th>Token Name</th>
                                            <th>Token Address</th>
                                            <th>Duration</th>
                                            <th>Sale Status</th>
                                            <th>Update Presale Duration</th>
                                            <th>Withdrawal</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {form && form.length > 0 ? (
                                            form.map((item, index) => (
                                                <tr key={index}>
                                                <td>{index + 1}</td>
                                                <td>{item.token_name}</td>
                                                <td>{item.token_address}</td>
                                                <td><b>{item.duration} Days</b></td>
                                                <td>
                                                    <div>
                                                    {form[0].isActive ? (
                                                        <Button variant="dark" className="h-auto btn-sm rounded-5 px-3" disabled={form[0].newD} onClick={() => handleStopSale(item)}>
                                                            Stop Sale
                                                        </Button>
                                                    ) : (
                                                        <Button variant="dark" className="h-auto btn-sm rounded-5 px-3 me-2"  disabled={form[0].newD} onClick={() => handleStartSale(item)}>
                                                            Start Sale
                                                        </Button>
                                                    )}
                                                    </div>
                                                </td>
                                                <td>
                                                    <div>
                                                        <Button variant="dark" disabled={form[0].newD} onClick={(e) => handleShow(e,item)} className="h-auto btn-sm rounded-5 px-3 me-2">
                                                            Update 
                                                        </Button>
    
                                                    </div>
                                                </td>
                                                <td>
                                                    <div>
                                                    {(form.length > 0 && form[0].canWithdraw ? (
                                                        <Button variant="dark" onClick={(e) => handleShow2(e,item)} className="h-auto btn-sm rounded-5 px-3 me-2">
                                                            Request Withdrawal
                                                        </Button>
                                                    ): (
                                                        <Button variant="dark" onClick={handleWithdrawal} className="h-auto btn-sm rounded-5 px-3 me-2">
                                                            Withdraw
                                                        </Button>
                                                    )
                                                )}
    
                                                    </div>
                                                </td>
                                            </tr>
                                            ))
                                                                                   
                                        ) : (
                                            <tr>
                                            <td colSpan="6">No data available</td>
                                        </tr>
                                        )}

                                    </tbody>
                                </Table>
                            </div>
                        </Col>
                    </Row>
                </Container>
                {/* --------Duration Modal Start-------- */}
                <Modal show={show} onHide={handleClose} className="modalStyle">
                    <Modal.Header closeButton>
                        <Modal.Title>Duration</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div>
                            <div  className="fw-medium mb-3">Token Address: {selectedItem.token_address}</div>
                            <Form.Group>
                                <Form.Label>Enter New Duration</Form.Label>
                              <Form.Control 
                                type="text" 
                                name="duration"
                                placeholder="Value" 
                                value={request.duration}
                                onChange={handleInputChange}
                                isInvalid={!!errors.duration}
                                 />
                                 <Form.Control.Feedback type="invalid">
                                    {errors.duration}
                                 </Form.Control.Feedback>
                            </Form.Group>
                        </div>

                    </Modal.Body>
                    <Modal.Footer>
                    <p className="text-muted small fst-italic">Note - New duration will not be added to existing duration</p>
                        <Button variant="dark" onClick={(e) => handleUpdateDuration(e)}>
                            UPDATE
                        </Button>
                    </Modal.Footer>
                </Modal>
                {/* -------Withdraw  Modal Start------- */}
                <Modal show={withdraw} onHide={handleClose2} className="modalStyle">

                    <Modal.Header closeButton>

                    <Modal.Title>Withdraw</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div>
                            <div className="fw-medium mb-3">Token Address: {selectedItem.token_address}</div>
                            <Form.Group>
                                <Form.Label>Reason for Withdrawal</Form.Label>
                                <Form.Control as="textarea" 
                                              rows={3}
                                              type="text"
                                              name="reason"
                                              placeholder="text"
                                              value={request.reason}
                                              onChange={handleInputChange}
                                              isInvalid={!!errors.reason}
                                 />
                                 <Form.Control.Feedback type="invalid">
                                    {errors.reason}
                                 </Form.Control.Feedback>
                            </Form.Group>
                        </div>

                    </Modal.Body>
                    <Modal.Footer>

                        <Button variant="dark" onClick={handleWithdrawRequest}>
                            SEND
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
            <Footer />
        </>
    )
}


//if duration over ==> show list button but disabled.
//if soft cap reached after duration ===> enable list button for owner and show claim page to user. remove buy page
//if soft cap not reached after duration ===> show list button but disabled. show refund page. remove buy page