import React, { useState, useEffect,useRef } from "react";
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { getActiveChains,updateLiquidityApplicationForm,getInfiFormDetailsById,changeInfiListingStatus} from "../../Action/action";
import { checkContractOnChain } from "../../Web3/web3FunctionCall";
import toast, { Toaster } from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate,useParams } from "react-router-dom";
import config from "../../config";
import { ApproveInfiToken,ApproveToken,NewPool } from "../../Web3/Liquidity/infiSwapConnector"
import Web3WalletProvider from '../../ConnectWallet/walletProvider'
import { useDisconnect, useAccount } from "wagmi";
import Web3 from "web3";
import "../Detail/detail.css"
import { showLoader,hideLoader } from "../loader";

export default function InfiLaunchForm() {
  const {id} = useParams()

  const [form, setForm] = useState([]);
  
  const [update, setUpdate] = useState(false);

  const [errors, setErrors] = useState({});
  const [chainOptions, setChainOptions] = useState([]);
  const [imagePreview, setImagePreview] = useState(null);

  const [getRpc, setRpc] = useState();
  const dispatch = useDispatch();
  const loginDetails = useSelector((state) => state.auth.LoginDetails);
  const navigate = useNavigate();

  let _useWeb3WalletProvider = Web3WalletProvider()
  const walletConnnected = useSelector((state) => state.auth.walletConnnected);
  const [isProviderLoaded, setisProviderLoaded] = useState(false);
  const [presaleLoading, setPresaleLoading] = useState(false)
  const {address, isConnected} = useAccount()
  const _useDisconnect = useDisconnect()
  const [connectWalletNow, setconnectWalletNow] = useState(false);
  const [Waddress, setAddress] = useState("");




useEffect(() => {
  console.log(isConnected, "IsConnect")
  console.log(address, "ADDRESS")
  console.log(walletConnnected, "WALLET")

  if(walletConnnected) {
    setconnectWalletNow(false)
  }
  setAddress(address)
},[walletConnnected])

const disconnectWallet = () => {
  _useDisconnect.disconnect()
}

useEffect(() => {
  if(!isProviderLoaded) {_useWeb3WalletProvider.then((data) => {
    if(data) {
      setisProviderLoaded(true)
    }
  })}
},[_useWeb3WalletProvider])
const buttonRef = useRef(null); // Add a ref for the button

useEffect(() => {
  // AutoBNBally click the button after 2 seconds
  const timer = setTimeout(() => {
    if (buttonRef.current) {
      buttonRef.current.click();
    }
  }, 2000);

  return () => clearTimeout(timer); // Cleanup the timer
}, []);




  useEffect(() => {
    getChains();
    getFormData()
  }, []);

  const getChains = async () => {
    try {
      const response = await getActiveChains(loginDetails);
      if (response.success) {
        setChainOptions(response.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getFormData = async () => {
    try {
      const response = await getInfiFormDetailsById({id},loginDetails);
      console.log('Infi FORM',response)
      if (response.success) {
        setForm(response.data);

      }
    } catch (error) {
      console.log(error);
    }
  };



  const handleInputChange = (e) => {
    const { name, value } = e.target;
    console.log({name, value})

    const index = 0
    setForm(prevForm => {
      const newForm = [...prevForm];
      newForm[index] = { ...newForm[index], [name]: value };
      return newForm;
    });
  };

  const handleFileChange = (e) => {

    const index = 0
    console.log(e.target.files[0])
    setForm(prevForm => {
      const newForm = [...prevForm];
      newForm[index] = { ...newForm[index], brand_logo: e.target.files[0]};
      return newForm;
    });
    setImagePreview(URL.createObjectURL(e.target.files[0]));
  };

 

  const validateForm = async () => {
    const newErrors = {};
    const urlPattern =
      /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?$/;
      console.log(form)

    if (!form[0].token_name) newErrors.token_name = "Token Name is required";

    if (!form[0].token_address)
      newErrors.token_address = "Token Address is required";
    if (!form[0].description) newErrors.description = "Description is required";
    if (!form[0].brand_logo) newErrors.brand_logo = "Logo is required";
    if (!form[0].kyc) newErrors.kyc = "KYC is required";
    if (!form[0].audit) newErrors.audit = "Audit is required";
    if (!form[0].liquidity)
      newErrors.liquidity = "Liquidity value is required";
    if (!form[0].infi_amount) newErrors.infi_amount = "INFI amount is required";
    
   
    let result = await checkContractOnChain(getRpc, form[0].token_address);

    if (!result.status) newErrors.token_address = `${result.msg}`;
  };


  const handleSubmitPool = async (e) => {
    e.preventDefault();

    if(!isConnected) {
      toast.error("Please connect with the registered wallet address");
      setconnectWalletNow(true)
      return
    }
    if(address.toUpperCase() != loginDetails.walletAddress.toUpperCase()) {
      toast.error("Please connect with registered wallet address")
      return
    }
    setPresaleLoading(true)
    showLoader()
    try {
  
    let request = {
      walletProvider:  _useWeb3WalletProvider,
      tokenOwner: address,
      tokenAddress:form[0].token_address,
      tokenAmount:form[0].liquidity,
      infiAddress: form[0].infiContract,
      infiAmount:form[0].infi_amount,
      pairContractAddress: form[0].pairContractAddress,
      infipairContractAddress:form[0].infiPairContract,
      networkId:form[0].chainNetworkId
    }
    console.log('RRR',request)
    let tokenApproval = await ApproveToken(request)
    console.log('tok approval',tokenApproval)
    if (tokenApproval.status) {
      let infiApproval = await ApproveInfiToken(request)
      console.log('liquifdity FORMRES',infiApproval)
        if (infiApproval.status) {
      let res = await NewPool(request)
      console.log('new liquid',res)
      if (res.status) {
        toast.success (res.message)
        let list = await changeInfiListingStatus({id})
        if (list.success) {
          console.log('LISTED',list)
        navigate(`${config.baseUrl}home`, {replace: true})
        }
      } else {
      toast.error(res.message)
      }
    } else {
      toast.error(infiApproval.message)
      console.log('INFI approval error',infiApproval.message)
    } 
  }
    else {
      toast.error(tokenApproval.message)
       console.log('Token approval error',tokenApproval.message)
    }
} catch (error) {
  console.log('Error Infi Pool',error)
  toast.error('Infi Pool',error)
} finally {
  hideLoader()
}

}
  return (
    <>
      <Toaster />
      <Header />
      <section className="process_form">
        <Container>
       
         {form.length > 0 && <Form >
            <div className="from_box my-5">
              <h2>The Launch process:</h2>
              <p>
                Must be confirmed with the Owner wallet (the tokens will be
                transferred to the INFI CDEX Launchpool)
              </p>
              <Row className="mt-4">
                <Col lg={6}>
                  <Form.Label>Token Name</Form.Label>
                  <Form.Control
                    type="text"
                    name="token_name"
                    value={form[0].token_name}
                    onChange={handleInputChange}
                    isInvalid={!!errors.token_name}
                    disabled={!update?true:false}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.token_name}
                  </Form.Control.Feedback>
                </Col>

                <Col lg={6}>
                  <Form.Label>Token Address</Form.Label>
                  <Form.Control
                    type="text"
                    name="token_address"
                    value={form[0].token_address}
                    onChange={handleInputChange}
                    isInvalid={!!errors.token_address}
                    disabled={!update?true:false}

                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.token_address}
                  </Form.Control.Feedback>
                </Col>

                {form.length > 0 && <Col lg={6}>
                  <Form.Label>Chain Network</Form.Label>
                  <Form.Control
                    type="text"
                    name="chainName"
                    value={form[0].chainName}
                    onChange={handleInputChange}
                    disabled
                  />
                </Col>}

                <Col lg={12} className="mt-4">
                  <Form.Label>Description</Form.Label>
                  <Form.Control
                    as="textarea"
                    name="description"
                    placeholder="max 1000 characters"
                    value={form[0].description}
                    onChange={handleInputChange}
                    isInvalid={!!errors.description}
                    disabled={!update?true:false}

                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.description}
                  </Form.Control.Feedback>
                </Col>

                {update?<Col lg={12} className="mt-4">
                  <Form.Label>ADD a Brand Logo/Upload a Logo</Form.Label>
                  <div className="position-relative">
                    <Form.Control
                      type="file"
                      className="custom_dragdrop_form"
                      onChange={handleFileChange}
                      isInvalid={!!errors.brand_logo}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.brand_logo}
                    </Form.Control.Feedback>
                    <div className="custom_dragdrop my-3">
                      <span>
                        Click to upload files, or drag & drop files here
                      </span>
                    </div>
                    {imagePreview && (
                      <div className="image-preview mt-3">
                        <img
                          src={imagePreview}
                          alt="Preview"
                          style={{ maxHeight: "200px", maxWidth: "100%" }}
                        />
                      </div>
                    )}
                  </div>
                </Col>:
                <Col lg={12} className="mt-4">
                <Form.Label className="me-2">Brand Logo</Form.Label>
               
                  
                      <img
                        src={`${config.imagePath}${form[0].brand_logo}`}
                       // alt="Preview"
                        style={{ maxHeight: "200px", maxWidth: "100%" }}
                      />
               
              </Col>}

                <Col lg={6} className="mt-4">
                  <Form.Label>KYC</Form.Label>
                  <Form.Control
                    type="text"
                    name="kyc"
                    value={form[0].kyc}
                    onChange={handleInputChange}
                    isInvalid={!!errors.kyc}
                    disabled={!update?true:false}

                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.kyc}
                  </Form.Control.Feedback>
                </Col>

                <Col lg={6} className="mt-4">
                  <Form.Label>Audit</Form.Label>
                  <Form.Control
                    type="text"
                    name="audit"
                    value={form[0].audit}
                    onChange={handleInputChange}
                    isInvalid={!!errors.audit}
                    disabled={!update?true:false}

                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.audit}
                  </Form.Control.Feedback>
                </Col>
              </Row>

              <Row className="mt-4">
                <Form.Label>Tokenomics</Form.Label>
                <Col lg={6}>
                  <Form.Label>Token Amount </Form.Label>
                 
                </Col>

                <Col lg={6}>
                  <Form.Control
                    type="text"
                    name="liquidity"
                    placeholder="value"
                    value={form[0].liquidity}
                    onChange={handleInputChange}
                    isInvalid={!!errors.liquidity}
                    disabled={!update?true:false}

                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.liquidity}
                  </Form.Control.Feedback>
                </Col>

                <Col lg={6} className="mt-4">
                  <Form.Label>INFI Amount</Form.Label>
                </Col>

                <Col lg={6} className="mt-4">
                  <Form.Control
                    type="text"
                    name="infi_amount"
                    placeholder="Value"
                    value={form[0].infi_amount}
                    onChange={handleInputChange}
                    disabled={!update?true:false}
                    isInvalid={!!errors.infi_amount}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.infi_amount}
                  </Form.Control.Feedback>
                </Col>
              </Row>

              <Row className="mt-4">
                <Col lg={12}>
                  <Form.Label>Whitepaper</Form.Label>
                  <Form.Control
                    type="text"
                    name="whitepaper"
                    placeholder="Add a whitepaper link here."
                    value={form[0].whitepaper}
                    onChange={handleInputChange}
                    disabled={!update?true:false}

                  />
                </Col>
              </Row>
              <hr />

              <div className="mt-4 text-center">
              <Button variant="dark" className="px-4 me-2 py-2"onClick={(e)=>handleSubmitPool(e)} >
                  Deploy Project
                </Button>
                {/* <Button variant="dark" className="px-4 py-2"onClick={()=>setUpdate(true)} >
                  Update
                </Button> */}
                <p className="mt-2">
                  Never submit password or credit card details through workform
                </p>
              </div>
            </div>
          </Form>}
        </Container>
      </section>
      <Footer />
    </>
  );
}
