import React, { useState, useEffect } from "react";
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { getActiveChains,submitLiquidityApplicationForm } from "../../Action/action";
import { checkContractOnChain } from "../../Web3/web3FunctionCall";
import toast, { Toaster } from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import config from "../../config";
import { useDisconnect, useAccount } from "wagmi";

export default function LiquidityListingForm() {
  const [form, setForm] = useState({
    tokenName: "",
    tokenAddress: "",
    chainNetwork: "",
    description: "",
    logo: null,
    kyc: "",
    audit: "",
    liquidityValue: "",
    usdtAmount:"",
    whitepaper: "",
    agree: false,
  });

  const [errors, setErrors] = useState({});
  const [chainOptions, setChainOptions] = useState([]);
  const [imagePreview, setImagePreview] = useState(null);

  const [getRpc, setRpc] = useState();
  const dispatch = useDispatch();
  const loginDetails = useSelector((state) => state.auth.LoginDetails);
  const navigate = useNavigate();
  const {address, isConnected} = useAccount()

  useEffect(() => {
    getChains();
  }, []);

  const getChains = async () => {
    try {
      const response = await getActiveChains(loginDetails);
      if (response.success) {
        setChainOptions(response.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name == "chainNetwork") {
      for (let i = 0; i < chainOptions.length; i++) {
        if (chainOptions[i].id == value) {
          setRpc(chainOptions[i].chainRPCUrl);
        }
      }
    }
    setForm({
      ...form,
      [name]: value,
    });
  };

  const handleFileChange = (e) => {
    setForm({
      ...form,
      logo: e.target.files[0],
    });
    setImagePreview(URL.createObjectURL(e.target.files[0]));
  };

  const handleCheckboxChange = (e) => {
    setForm({
      ...form,
      agree: e.target.checked,
    });
  };

  const validateForm = async () => {
    const newErrors = {};
    const urlPattern =
      /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?$/;

    if (!form.tokenName) newErrors.tokenName = "Token Name is required";

    if (!form.tokenAddress)
      newErrors.tokenAddress = "Token Address is required";
    if (!form.description) newErrors.description = "Description is required";
    if (!form.logo) newErrors.logo = "Logo is required";
    // if (!form.kyc) newErrors.kyc = "KYC is required";
    // if (!form.audit) newErrors.audit = "Audit is required";
    if (!form.liquidityValue) {
      newErrors.liquidityValue = "Liquidity value is required";
    }  else if (form.liquidityValue < 0) {
      newErrors.liquidityValue = "Liquidity value cannot be negative";      
    }
    if (!form.usdtAmount) {
      newErrors.usdtAmount = "USDT amount is required";
    } else if (form.usdtAmount < 0) {
      newErrors.usdtAmount = "Value cannot be negative";      
    }
    if (!form.whitepaper) newErrors.whitepaper = "Whitepaper is required";
    if (!form.agree) newErrors.agree = "You must agree to the terms";

    let result = await checkContractOnChain(getRpc, form.tokenAddress);
    //console.log('pp',result)

    if (!result.status) newErrors.tokenAddress = `${result.msg}`;

    console.log(newErrors)
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
}
   
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (Object.keys(loginDetails).length === 0) {
      navigate(`${config.baseUrl}login`)
    } else {

    if(!isConnected) {
      toast.error("Please connect with the registered wallet.");
      return
    }
    if(address.toUpperCase() != loginDetails.walletAddress.toUpperCase()) {
      toast.error("Please connect with registered wallet")
      return
    }

     if (await validateForm()) {
       console.log("Liquidity Form submitted", form);
     
       form.ownerId=loginDetails.id;
       let result = await submitLiquidityApplicationForm(form,loginDetails)
 
       if(result.success){
        toast.success(result.msg);
        setTimeout(() => {
          navigate(`${config.baseUrl}home`);
        }, 2000);
       }else{
        toast.error(result.msg)
       }

   } else {
       toast.error("Please check the validations required.");
     }
  }
  };

  return (
    <>
      <Toaster />
      <Header />
      <section className="process_form">
        <Container>
          <Form >
            <div className="from_box my-5">
              <h2>The Launch process:</h2>
              <p>
                Must be confirmed with the Owner wallet (the tokens will be
                transferred to the INFI CDEX Launchpool)
              </p>

              <p
                style={{
                  textAlign:"right",
                  fontSize: "12px",
                  fontStyle: "italic",
                  marginTop:"-10px"
                }}
              >Fields marked with * are compulsory</p>

              <Row>
                <Col lg={6} className="mt-4">
                  <Form.Label>Token Name *</Form.Label>
                  <Form.Control
                    type="text"
                    name="tokenName"
                    value={form.tokenName}
                    onChange={handleInputChange}
                    isInvalid={!!errors.tokenName}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.tokenName}
                  </Form.Control.Feedback>
                </Col>

                <Col lg={6} className="mt-4">
                  <Form.Label>Chain Network *</Form.Label>
                  <Form.Control
                    as="select"
                    name="chainNetwork"
                    value={form.chainNetwork}
                    onChange={handleInputChange}
                    isInvalid={!!errors.chainNetwork}
                  >
                    <option value="">Select Network</option>
                    {chainOptions.map((option, index) => (
                      <option key={index} value={option.id}>
                        {option.chainName}
                      </option>
                    ))}
                  </Form.Control>
                  <Form.Control.Feedback type="invalid">
                    {errors.chainNetwork}
                  </Form.Control.Feedback>
                </Col>

                <Col lg={6} className="mt-4">
                  <Form.Label>Token Address *</Form.Label>
                  <Form.Control
                    type="text"
                    name="tokenAddress"
                    value={form.tokenAddress}
                    onChange={handleInputChange}
                    isInvalid={!!errors.tokenAddress}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.tokenAddress}
                  </Form.Control.Feedback>
                </Col>

                <Col lg={12} className="mt-4">
                  <Form.Label>Description *</Form.Label>
                  <Form.Control
                    as="textarea"
                    name="description"
                    placeholder="max 1000 characters"
                    value={form.description}
                    onChange={handleInputChange}
                    isInvalid={!!errors.description}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.description}
                  </Form.Control.Feedback>
                </Col>

                  <Col lg={12} className="mt-4">
                  <Form.Label>ADD a Brand Logo/Upload a Logo *</Form.Label>
                  <div className="position-relative">
                    <Form.Control
                      type="file"
                      className="custom_dragdrop_form"
                      onChange={handleFileChange}
                      isInvalid={!!errors.logo}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.logo}
                    </Form.Control.Feedback>
                    <div className="custom_dragdrop my-3">
                      <span>
                        Click to upload files, or drag & drop files here
                      </span>
                    </div>
                    {imagePreview && (
                      <div className="image-preview mt-3">
                        <img
                          src={imagePreview}
                          alt="Preview"
                          style={{ maxHeight: "200px", maxWidth: "100%" }}
                        />
                      </div>
                    )}
                  </div>
                </Col>

                <Col lg={6} className="mt-4">
                  <Form.Label>ADD KYC</Form.Label>
                  <Form.Control
                    type="text"
                    name="kyc"
                    value={form.kyc}
                    onChange={handleInputChange}
                    isInvalid={!!errors.kyc}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.kyc}
                  </Form.Control.Feedback>
                </Col>

                <Col lg={6} className="mt-4">
                  <Form.Label>ADD Audit</Form.Label>
                  <Form.Control
                    type="text"
                    name="audit"
                    value={form.audit}
                    onChange={handleInputChange}
                    isInvalid={!!errors.audit}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.audit}
                  </Form.Control.Feedback>
                </Col>

              <Row className="mt-4">
                <h5>Tokenomics</h5></Row>

                <Col lg={6} className="mt-4">
                  <Form.Label>Token Liquidity *</Form.Label>
                  <Form.Control
                    type="text"
                    name="liquidityValue"
                    placeholder="Value"
                    value={form.liquidityValue}
                    onChange={handleInputChange}
                    isInvalid={!!errors.liquidityValue}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.liquidityValue}
                  </Form.Control.Feedback>
                </Col>
                
                <Col lg={6} className="mt-4">
                  <Form.Label>USDT Liquidity Amount *</Form.Label>
                  <Form.Control
                    type="text"
                    name="usdtAmount"
                    placeholder="Value"
                    value={form.usdtAmount}
                    onChange={handleInputChange}
                    isInvalid={!!errors.usdtAmount}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.usdtAmount}
                  </Form.Control.Feedback>
                </Col>
                <Col lg={6} className="mt-4">
                </Col>

              <Row className="mt-4">
                <Col lg={12}>
                  <Form.Label>Whitepaper *</Form.Label>
                  <Form.Control
                    type="text"
                    name="whitepaper"
                    placeholder="Add a whitepaper link here."
                    value={form.whitepaper}
                    onChange={handleInputChange}
                  />
                </Col>
                </Row>
            
              </Row>
              <div className="mt-5">
                <h6>
                  IMPORTANT: Special Liquidity Management SpecialLM - must agree *
                </h6>
                <p>
                  (Here is the description for the project owners, what they
                  have to agree upon prior to listing their project. This will
                  be a contract between INFI CDEX and the starting project that
                  needs to be <b>AGREE</b> and <b>CONFIRM</b> by the project
                  owner)
                </p>
              </div>

              <div className="d-flex align-items-baseline">
                <Form.Check
                  className="me-2"
                  checked={form.agree}
                  onChange={handleCheckboxChange}
                  isInvalid={!!errors.agree}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.agree}
                </Form.Control.Feedback>
                <span>
                  Project owners must lock liquidity for a minimum of six
                  months, during which the liquidity remains inaccessible.
                  Safeguards are in place for companies to investigate and halt
                  trading if a scam or suspicious transaction is spotted. In
                  case of a confirmed scam, investors take precedence, impacting
                  the locked liquidity. Post the lock period, project owners
                  need approval from INFI ADMIN to access the liquidity, and
                  must justify their request
                </span>
              </div>

              <hr />

              <div className="mt-4 text-center">
                <Button variant="dark" className="px-4 py-2"onClick={e=>{handleSubmit(e)}} >
                  Submit
                </Button>
                <p className="mt-2">
                  Never submit password or credit card details through workform
                </p>
              </div>
            </div>
          </Form>
        </Container>
      </section>
      <Footer />
    </>
  );
}