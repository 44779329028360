const { useWalletClient, useAccount } = require("wagmi")

const Web3WalletProvider = async() => {
    // const _useWeb3ModalProvider = useWalletClient()
    // return _useWeb3ModalProvider.data;

    const _useAccount = useAccount()
    // exports.provider = async () => {
        const data = await _useAccount.connector?.getProvider()
       // console.log('DATA',data)
        return data
        
    // }
}
export default Web3WalletProvider;