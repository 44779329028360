import React, { useState } from "react";

const ReadMore = ({ text, maxLength }) => {
  const [isReadMore, setIsReadMore] = useState(true);

  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };

  return (
    <div>
      {isReadMore ? text.slice(0, maxLength) : text}
      <span onClick={toggleReadMore} className="fw-medium" style={{ color: "rgb(114 197 142)", cursor: "pointer" }}>
        {isReadMore ? "...Read More" : " Show Less"}
      </span>
    </div>
  );
};

export default ReadMore;
