import * as Yup from 'yup';

export const loginSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email').required('Email is required.'),
  password: Yup.string()
    .required('Password is required.')
    .matches(
      /\b([A-Z])/g,
      'Password must contain at least one uppercase letter.'
    )
    .max(18, 'Password is too long - should be maximum 20 chars.')
    .min(7, 'Password must be at least 8 characters long..')
    .matches(/[0-9]/, 'Password must contain at least one numeric value.')
    .matches(/[a-z]/, 'Password must contain at least one lowercase letter.')
    .matches(
      /[^\w]/,
      'Password must contain at least one special character (e.g., !@#$%^&*).'
    ),
});
