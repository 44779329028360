import React, { useEffect, useState } from "react";
import { Sidebar, Menu, MenuItem } from 'react-pro-sidebar';
import { LuDroplets } from "react-icons/lu";
;

export default function SidebarInfi(props) {
    const { collapsed, setCollapsed, width } = props
    const [location, setLocation] = useState('')

    console.log("location", props)

    useEffect(() => {
        setLocation(window.location.pathname)
    }, [location])
    return (
        <>
            <section className="sidebar">
                <Sidebar collapsed={collapsed}>
                    <Menu>
                        <MenuItem icon={<LuDroplets />} >Liquidity</MenuItem>
                    </Menu>

                </Sidebar>
            </section>
        </>
    )
}