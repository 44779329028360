import Web3 from 'web3';
import presaleAbi from '../Web3/abi/presaleAbi.json'
import erc20Abi from '../Web3/abi/erc20Abi.json'
import web3config from './web3config';


const validateProvider = async (walletProvider) => {
      console.log("validateProviderNew");
      try {
        if (!walletProvider) {
        console.log("walletProvider");

          return {
            status: false,
            message: `Please connect your web3 wallet`,
          };
        }
        //console.log('WALLET', walletProvider)
        const web3 = new Web3(window.ethereum);

        let accounts = await window.ethereum.request({
          method: "eth_requestAccounts"
        });

        if (!accounts || accounts.length === 0) {
          return {
            status: false,
            message: "No accounts found. Please connect your wallet.",
          };
        }
       const currentProvider = await web3.currentProvider;
       const chainId = await web3.currentProvider.chainId;
       console.log({
        status: true,
        web3,chainId
      })
        return {
          status: true,
          web3,
          accounts:accounts[0],
        chainId
        };
      } catch (error) {
        console.log("Error in validateProvider :", error);
        return {
          status: false,
          message: "Error in validating provider. Please try again.",
        };
      }
    };

export const TokenSupply = async (req) => {
  try {
   const {walletProvider, tokenAddress,networkId} = req 
   const validate = await validateProvider(walletProvider)
   if (!validate.status) {
     console.log("Error in provider",validate)
     return validate;
   }
   let web3 = validate.web3;
   let walletAddress=validate.accounts
         let chain = await validate.chainId

      if(networkId != chain) {
        try{
          await window.ethereum.request({
              method: 'wallet_switchEthereumChain',
              params: [{chainId: networkId}]
            });
      } catch(switchError) {
          console.error('Error',switchError)
          return {
            message: switchError.toString()
          }
      }
    }
   
   let erc20Contract = new web3.eth.Contract(erc20Abi,tokenAddress)
   let decimals = await erc20Contract.methods.decimals().call()
  let total = erc20Contract.methods.totalSupply().call()

  return {
    status: true,
    supply: await total
  }
  } catch (error) {
    return {
      status: false,
      message: error.toString()
  }
  }
}

export const NewSale = async (req) => {
  try{
    let {walletProvider,preSaleAddress, usdtAddress,UsdtPrice, tokenAddress, tokenAmount,duration,networkId} = req
    const validate = await validateProvider(walletProvider)
    if (!validate.status) {
      console.log("Error in provider",validate)
      return validate;
    }
    let web3 = await validate.web3;
    let walletAddress = await validate.accounts
    let chain = await validate.chainId

    if(networkId != chain) {
      try{
        await window.ethereum.request({
            method: 'wallet_switchEthereumChain',
            params: [{chainId: networkId}]
          });
    } catch(switchError) {
        console.error('Error',switchError)
        return {
          message: switchError.toString()
        }
      }
    }

    let contractPresale = new web3.eth.Contract(presaleAbi,preSaleAddress)
    let erc20Contract = new web3.eth.Contract(erc20Abi,usdtAddress)
    let decimals = await erc20Contract.methods.decimals().call()

    let priceUsdt = parseInt(UsdtPrice * 10 ** parseInt(decimals)).toLocaleString("fullwide", {useGrouping: false,})
    //let priceNative = (NativePrice * 10 ** 18).toLocaleString("fullwide",{useGrouping: false})
    let amount = (tokenAmount * 10**18).toLocaleString("fullwide",{useGrouping: false})


    let durationInseconds = Math.floor(duration * 24 * 60 * 60) //86400
    // let currentTime = Math.floor(Date.now() / 1000) 
    // duration = currentTime + durationInseconds 
    console.log('DUUU',durationInseconds) 

    let isBlacklisted = await contractPresale.methods.isBlacklisted(walletAddress).call()
    if (isBlacklisted) {
      return {
        //status: true,
        message: 'Address blacklisted. Contact admin'
      }
    }

    let doesExist = await contractPresale.methods.doesSaleExist(tokenAddress).call()
    // console.log('STA',doesExist,tokenAddress,!doesExist)
    if (doesExist) {
      return {
        //status: true,
        message: "Sale already exists for this token"
      }
    }

    let trx = await contractPresale.methods.createPreSale(tokenAddress, amount, priceUsdt,durationInseconds)
    //console.log('tRXXX',duration)
    let gasPrice = await web3.eth.getGasPrice()
    let gasLimit = await web3.eth.estimateGas({
      gasPrice: web3.utils.toHex(gasPrice),
      to: preSaleAddress,
      from: walletAddress,
      data: trx.encodeABI(),
    })

    const trxData = await web3.eth.sendTransaction({
      gasPrice: web3.utils.toHex(gasPrice),
      gas: web3.utils.toHex(gasLimit),
      to: preSaleAddress,
      from: walletAddress,
      data: trx.encodeABI(),
    })

    return {
      status: true,
      hash: trxData.transactionHash,
      message: "PreSale started successfully. It will take a few minutes to confirm the transaction."

    }


  } catch(error) {
    console.log('ERRR',error)
    return {
      status: false,
      message: error.toString()
  }
  }
}
    
export const ApproveUsdtToken = async (req) => {
try{
  const {walletProvider, usdtAddress, preSaleAddress, networkId,buyAmount} = req
  const validate = await validateProvider(walletProvider)
  if (!validate.status) {
    return validate;
  }
  let web3 = validate.web3;
  let walletAddress = validate.accounts
//   let chain = await validate.chainId

//   if(networkId != chain) {
//     try{
//       await window.ethereum.request({
//           method: 'wallet_switchEthereumChain',
//           params: [{chainId: networkId}]
//         });
//   } catch(switchError) {
//       console.error('Error',switchError)
//       return {
//         message: switchError.toString()
//       }
//   }
// }


  let erc20Contract = new web3.eth.Contract(erc20Abi, usdtAddress)

  let decimals = await erc20Contract.methods.decimals().call()


  //let checkAllowance = await erc20Contract.methods.allowance(walletAddress,preSaleAddress).call()
  //let allowanceAmt = parseFloat((parseInt(checkAllowance) / 10 ** parseInt(decimals)).toFixed(6))
  //if (parseFloat(allowanceAmt) < parseFloat(buyAmount)) {
   let  amount = (buyAmount * 10 ** parseInt(decimals)).toLocaleString("fullwide", {useGrouping: false})
   let balance = await erc20Contract.methods.balanceOf(walletAddress).call();
   if (parseInt(balance) < parseInt(amount)) {
     return {
       status: false,
       message: `Insufficient balance. You need ${amount} tokens, but only have ${balance / 10 ** decimals}`
     };
   }
  let trx = await erc20Contract.methods.approve(preSaleAddress, amount)
  let gasPrice = await web3.eth.getGasPrice()
  let gasLimit = await web3.eth.estimateGas({
    gasPrice: web3.utils.toHex(gasPrice),
    to: usdtAddress,
    from: walletAddress,
    data: trx.encodeABI()
  })

  const trxData = await web3.eth.sendTransaction({
    gasPrice: web3.utils.toHex(gasPrice),
    gas: web3.utils.toHex(gasLimit),
    to: usdtAddress,
    from: walletAddress,
    data: trx.encodeABI(),
  })

  return {
    status: true,
    hash: trxData.transactionHash
  }
} catch(error) {
        return {
            status: false,
            message: error.toString()
        }
    }
}

export const ApproveToken = async (req) => {
  try{
    const {walletProvider, tokenAddress, preSaleAddress, tokenAmount,networkId} = req
    
    const validate = await validateProvider(walletProvider)
    if (!validate.status) {
      console.log("Error in provider",validate)
      return validate;
    }
    let web3 = validate.web3;
    let walletAddress=validate.accounts
    let chain = await validate.chainId

    if(networkId != chain) {
      try{
        await window.ethereum.request({
            method: 'wallet_switchEthereumChain',
            params: [{chainId: networkId}]
          });
    } catch(switchError) {
        console.error('Error',switchError)
        return {
          message: switchError.toString()
        }
    }
    }
   
    let erc20Contract = new web3.eth.Contract(erc20Abi, tokenAddress)
    let decimals = await erc20Contract.methods.decimals().call()
    console.log({decimals})

    //let checkAllowance = await erc20Contract.methods.allowance(walletAddress,preSaleAddress).call()
    //let allowanceAmt = parseFloat((parseInt(checkAllowance) / 10 ** parseInt(decimals)))
    //if (parseFloat(allowanceAmt) < parseFloat(tokenAmount)) {
    let amount = (tokenAmount * 10 ** parseInt(decimals)).toLocaleString("fullwide", {useGrouping: false})
    let balance = await erc20Contract.methods.balanceOf(walletAddress).call();
    if (parseInt(balance) < parseInt(amount)) {
      return {
        status: false,
        message: `Insufficient balance. You need ${amount} tokens, but only have ${balance / 10 ** decimals}`
      };
    }
    let trx = await erc20Contract.methods.approve(preSaleAddress, amount)

    let gasPrice = await web3.eth.getGasPrice()
    let gasLimit = await web3.eth.estimateGas({
      gasPrice: web3.utils.toHex(gasPrice),
      to: tokenAddress,
      from: walletAddress,
      data: trx.encodeABI(),
      ///chainId: chainId
    })
  
    const trxData = await web3.eth.sendTransaction({
      gasPrice: web3.utils.toHex(gasPrice),
      gas: web3.utils.toHex(gasLimit),
      to: tokenAddress,
      from: walletAddress,
      data: trx.encodeABI(),
     // chainId: chainId
    })
  
    return {
      status: true,
      hash: trxData.transactionHash
    }
    
  } catch(error) {
          return {
              status: false,
              message: error.toString()
          }
      }
  }

export const BuyTokenUsdt = async (req) => {
  try{
    const {walletProvider, usdtAddress, preSaleAddress, buyAmount, tokenAddress,networkId} = req
    const validate = await validateProvider(walletProvider)
    if (!validate.status) {
      return validate;
    }
    let web3 = validate.web3;
    let walletAddress = validate.accounts
    let chain = await validate.chainId

    if(networkId != chain) {
      try{
        await window.ethereum.request({
            method: 'wallet_switchEthereumChain',
            params: [{chainId: networkId}]
          });
    } catch(switchError) {
        console.error('Error',switchError)
        return {
          message: switchError.toString()
        }
    }
  }

    let contractPresale = new web3.eth.Contract(presaleAbi,preSaleAddress)
    let erc20Contract = new web3.eth.Contract(erc20Abi,usdtAddress)
    let decimals = await erc20Contract.methods.decimals().call()

    let amount = (buyAmount * 10 ** parseInt(decimals)).toLocaleString("fullwide", {
      useGrouping: false,
    })

    let isPresaleActive = await contractPresale.methods.isPresaleActive(tokenAddress).call()
    if(!isPresaleActive) {
      return {
        status: false,
        message: 'Token buying not started'
      }
    }

    let trx = await contractPresale.methods.buyTokenWithUSDT(tokenAddress,amount)

    let gasPrice = await web3.eth.getGasPrice()
    let gasLimit = await web3.eth.estimateGas({
      gasPrice: web3.utils.toHex(gasPrice),
      to: preSaleAddress,
      from: walletAddress,
      data: trx.encodeABI(),
    })

    const trxData = await web3.eth.sendTransaction({
      gasPrice: web3.utils.toHex(gasPrice),
      gas: web3.utils.toHex(gasLimit),
      to: preSaleAddress,
      from: walletAddress,
      data: trx.encodeABI(),
    })

    return {
      status: true,
      hash: trxData.transactionHash,
      message: "Token bought successfully.Come back here to claim your tokens after presale is over"
    }


  } catch(error) {
    return {
      status: false,
      message: error.toString()
  }
  }
}


  export const ClaimToken = async (req) => {
    try{
      const {walletProvider,preSaleAddress,tokenAddress,networkId} = req
      const validate = await validateProvider(walletProvider)
      if (!validate.status) {
        return validate;
      }
      let web3 = validate.web3;
      let walletAddress = validate.accounts
      let chain = await validate.chainId

      if(networkId != chain) {
        try{
          await window.ethereum.request({
              method: 'wallet_switchEthereumChain',
              params: [{chainId: networkId}]
            });
      } catch(switchError) {
          console.error('Error',switchError)
          return {
            message: switchError.toString()
          }
      }
    }
  
      let contractPresale = new web3.eth.Contract(presaleAbi,preSaleAddress)
      let erc20Contract = new web3.eth.Contract(erc20Abi, tokenAddress)
    let decimals = await erc20Contract.methods.decimals().call()
    console.log({decimals})

    let isClaimed = await contractPresale.methods.claimStatus(tokenAddress).call()
    if(!isClaimed) {
      return {
        message: 'Already claimed'
      }
    }

      let trx = await contractPresale.methods.claimTokens(tokenAddress)

      let gasPrice = await web3.eth.getGasPrice()
      let gasLimit = await web3.eth.estimateGas({
        gasPrice: web3.utils.toHex(gasPrice),
        to: preSaleAddress,
        from: walletAddress,
        data: trx.encodeABI()
      })

      const trxData = await web3.eth.sendTransaction({
        gasPrice: web3.utils.toHex(gasPrice),
        gas: web3.utils.toHex(gasLimit),
        to: preSaleAddress,
        from: walletAddress,
        data: trx.encodeABI(),
      })
  
      return {
        status: true,
        hash: trxData.transactionHash,
        message: "Token claimed successfully. It will take a few minutes to confirm the transaction.Please check your wallet after some time"
      }
  
  
    } catch(error) {
      console.log('CLAIM',error)
      return {
        status: false,
        message: error.toString()
    }
    }
  }


export const RefundTokens = async (req) => {
    try{
      const {walletProvider,preSaleAddress,tokenAddress,networkId} = req
      const validate = await validateProvider(walletProvider)
      if (!validate.status) {
        return validate;
      }
      let web3 = validate.web3;
      let walletAddress = validate.accounts
      let chain = await validate.chainId

      if(networkId != chain) {
        try{
          await window.ethereum.request({
              method: 'wallet_switchEthereumChain',
              params: [{chainId: networkId}]
            });
      } catch(switchError) {
          console.error('Error',switchError)
          return {
            message: switchError.toString()
          }
      }
    }
  
      let contractPresale = new web3.eth.Contract(presaleAbi,preSaleAddress)
      let erc20Contract = new web3.eth.Contract(erc20Abi, tokenAddress)
    let decimals = await erc20Contract.methods.decimals().call()

    let isRefunded = await contractPresale.methods.refundStatus(tokenAddress).call()
    if(!isRefunded) {
      return {
        message: 'Already refunded'
      }
    }
  
      let trx = await contractPresale.methods.refund(tokenAddress)

      let gasPrice = await web3.eth.getGasPrice()
      let gasLimit = await web3.eth.estimateGas({
        gasPrice: web3.utils.toHex(gasPrice),
        to: preSaleAddress,
        from: walletAddress,
        data: trx.encodeABI(),
      })
  
      const trxData = await web3.eth.sendTransaction({
        gasPrice: web3.utils.toHex(gasPrice),
        gas: web3.utils.toHex(gasLimit),
        to: preSaleAddress,
        from: walletAddress,
        data: trx.encodeABI(),
      })
  
      return {
        status: true,
        hash: trxData.transactionHash,
        message: "USDT refunded successfully. It will take a few minutes to confirm the transaction. Please check your wallet after sometime"
      }
  
    } catch(error) {
      console.log('REFUND',error)
      return {
        status: false,
        message: error.toString()
    }
    }
  }

export const WithdrawTokens = async (req) => {
    try{
      const {walletProvider,preSaleAddress,tokenAddress,networkId} = req
      const validate = await validateProvider(walletProvider)
      if (!validate.status) {
        return validate;
      }
      let web3 = validate.web3;
      let walletAddress = validate.accounts
      let chain = await validate.chainId

      if(networkId != chain) {
        try{
          await window.ethereum.request({
              method: 'wallet_switchEthereumChain',
              params: [{chainId: networkId}]
            });
      } catch(switchError) {
          console.error('Error',switchError)
          return {
            message: switchError.toString()
          }
      }
    }
  
      let contractPresale = new web3.eth.Contract(presaleAbi,preSaleAddress)
      let erc20Contract = new web3.eth.Contract(erc20Abi, tokenAddress)
    let decimals = await erc20Contract.methods.decimals().call()

  
      let trx = await contractPresale.methods.withdrawTokensUser(tokenAddress)

      let gasPrice = await web3.eth.getGasPrice()
      let gasLimit = await web3.eth.estimateGas({
        gasPrice: web3.utils.toHex(gasPrice),
        to: preSaleAddress,
        from: walletAddress,
        data: trx.encodeABI(),
      })
  
      const trxData = await web3.eth.sendTransaction({
        gasPrice: web3.utils.toHex(gasPrice),
        gas: web3.utils.toHex(gasLimit),
        to: preSaleAddress,
        from: walletAddress,
        data: trx.encodeABI(),
      })
  
      return {
        status: true,
        hash: trxData.transactionHash,
        message: "Tokens withdrawn successfully. It will take a few minutes to confirm the transaction. Please check your wallet after sometime"
      }
  
    } catch(error) {
      console.log('withdraw',error)
      return {
        status: false,
        message: error.toString()
    }
    }
  }


export const PreSaleData = async (req) => {
    try{
      const {walletProvider, preSaleAddress,tokenAddress,networkId} = req
      const validate = await validateProvider(walletProvider)
      if (!validate.status) {
        return validate;
      }
      let web3 = validate.web3;
      let walletAddress = await validate.accounts
      let chain = await validate.chainId

      if(networkId != chain) {
        try{
          await window.ethereum.request({
              method: 'wallet_switchEthereumChain',
              params: [{chainId: networkId}]
            });
      } catch(switchError) {
          console.error('Error',switchError)
          return {
            message: switchError.toString()
          }
      }
    }
  
      let contractPresale = new web3.eth.Contract(presaleAbi,preSaleAddress)
  
      let trx = await contractPresale.methods.getPreSaleDetails(tokenAddress).call()
  
      return {
        status: true,
        hash: trx,
      }
  
    } catch(error) {
      return {
        status: false,
        message: error.toString()
    }
    }
  }

  export const TokenPriceUsdt = async (req) => {
    try {
      let data = req
      let results = []
      for(let i = 0; i < data.length; i++) {
        const validate = await validateProvider2(data[i].chainRPCUrl)
        if(!validate.status) {
          return validate
        }
      let web3 = validate.web3
      let presaleContract = new web3.eth.Contract(presaleAbi,data[i].presaleContract)
      let contractUsdt = new web3.eth.Contract(erc20Abi,data[i].usdtContract)
      let decimals = await contractUsdt.methods.decimals().call()
      let trx = await presaleContract.methods.getTokenPriceInUsdt(data[i].token_address).call()
      console.log('POX',data[i].presaleContract,data[i].usdtContract, data[i].token_address)
      let Price = Number(trx) / 10 ** parseInt(decimals).toLocaleString("fullwide", {useGrouping: false})
      results.push(Price)
      }
      return {
        status: true,
        data: results
      }
    }
       catch (error) {
      console.error('Error getting token price',error);
    }
  }

  export const getTokenSupplyPresale = async (req) => {
    try {
      let data = req
      let results = []
      for(let i = 0; i < data.length; i++) {
        const validate = await validateProvider2(data[i].chainRPCUrl)
        if(!validate.status) {
          return validate
        }
      let web3 = validate.web3
      let contractToken = new web3.eth.Contract(erc20Abi,data[i].token_address)
      let decimals = await contractToken.methods.decimals().call()
      let trx = await contractToken.methods.totalSupply(data[i].token_address).call()
      console.log('LOO',trx)
      let Supply = Number(trx) / 10 ** parseInt(decimals).toLocaleString("fullwide", {useGrouping: false})
      results.push(Supply)
      }
      return {
        status: true,
        data: results
      }
    }
       catch (error) {
      console.error('Error getting token price',error);
    }
  }
  

  export const PresaleTokenSupply = async (req) => {
    try {
      let data = req
      let results = []
      for(let i = 0; i < data.length; i++) {
        const validate = await validateProvider2(data[i].chainRPCUrl)
        if(!validate.status) {
          return validate
        }
      let web3 = validate.web3
      let contractToken = new web3.eth.Contract(erc20Abi,data[i].token_address)
      let decimals = await contractToken.methods.decimals().call()
      let trx = await contractToken.methods.totalSupply(data[i].token_address).call()
      console.log('LOO',trx)
      let Supply = Number(trx) / 10 ** parseInt(decimals).toLocaleString("fullwide", {useGrouping: false})
      results.push(Supply)
      }
      return {
        status: true,
        data: results
      }
    }
       catch (error) {
      console.error('Error getting token price',error);
    }
  }


export const TokenSaleDuration = async (req) => {
    try{
      const {walletProvider, preSaleAddress,tokenAddress,networkId} = req
      const validate = await validateProvider(walletProvider)
      if (!validate.status) {
        return validate;
      }
      let web3 = validate.web3;
      let walletAddress = await validate.accounts
    //   let chain = await validate.chainId

    //   if(networkId != chain) {
    //     try{
    //       await window.ethereum.request({
    //           method: 'wallet_switchEthereumChain',
    //           params: [{chainId: networkId}]
    //         });
    //   } catch(switchError) {
    //       console.error('Error',switchError)
    //       return {
    //         message: switchError.toString()
    //       }
    //   }
    // }
  
      let contractPresale = new web3.eth.Contract(presaleAbi,preSaleAddress)
  
      let trx = await contractPresale.methods.getPresaleDuration(tokenAddress).call()
  
      return {
        status: true,
        duration:trx
      }
  
    } catch(error) {
      return {
        status: false,
        message: error.toString()
    }
    }
  }

export const StartSale = async (req) => {
    try{
      const {walletProvider, preSaleAddress,tokenAddress,networkId} = req
      const validate = await validateProvider(walletProvider)
      if (!validate.status) {
        return validate;
      }
      let web3 = validate.web3;
      let walletAddress = validate.accounts
      let chain = await validate.chainId

      if(networkId != chain) {
        try{
          await window.ethereum.request({
              method: 'wallet_switchEthereumChain',
              params: [{chainId: networkId}]
            });
      } catch(switchError) {
          console.error('Error',switchError)
          return {
            message: switchError.toString()
          }
      }
    }
  
      let contractPresale = new web3.eth.Contract(presaleAbi,preSaleAddress)
      let erc20Contract = new web3.eth.Contract(erc20Abi, tokenAddress)

      let isActive = await contractPresale.methods.isPresaleActive(tokenAddress).call()
      if (isActive) {
        return {
          //status: true,
          message: 'Sale already active'
        }
      }
  
      let trx = await contractPresale.methods.startPreSale(tokenAddress)

      let gasPrice = await web3.eth.getGasPrice()
      let gasLimit = await web3.eth.estimateGas({
        gasPrice: web3.utils.toHex(gasPrice),
        to: preSaleAddress,
        from: walletAddress,
        data: trx.encodeABI(),
      })
  
      const trxData = await web3.eth.sendTransaction({
        gasPrice: web3.utils.toHex(gasPrice),
        gas: web3.utils.toHex(gasLimit),
        to: preSaleAddress,
        from: walletAddress,
        data: trx.encodeABI(),
      })
  
      return {
        status: true,
        hash: trxData.transactionHash
      }
  
    } catch(error) {
      return {
        status: false,
        message: error.toString()
    }
    }
  }

export const StopSale = async (req) => {
    try{
      const {walletProvider, preSaleAddress,tokenAddress,networkId} = req
      const validate = await validateProvider(walletProvider)
      if (!validate.status) {
        return validate;
      }
      let web3 = validate.web3;
      let walletAddress = validate.accounts
      let chain = await validate.chainId

      if(networkId != chain) {
        try{
          await window.ethereum.request({
              method: 'wallet_switchEthereumChain',
              params: [{chainId: networkId}]
            });
      } catch(switchError) {
          console.error('Error',switchError)
          return {
            message: switchError.toString()
          }
      }
    }
  
      let contractPresale = new web3.eth.Contract(presaleAbi,preSaleAddress)
      let erc20Contract = new web3.eth.Contract(erc20Abi, tokenAddress)

      let isActive = await contractPresale.methods.isPresaleActive(tokenAddress).call()
      if (!isActive) {
        return {
          //status: true,
          message: 'Sale not active'
        }
      }

      let trx = await contractPresale.methods.stopPreSale(tokenAddress)

      let gasPrice = await web3.eth.getGasPrice()
      let gasLimit = await web3.eth.estimateGas({
        gasPrice: web3.utils.toHex(gasPrice),
        to: preSaleAddress,
        from: walletAddress,
        data: trx.encodeABI(),
      })
  
      const trxData = await web3.eth.sendTransaction({
        gasPrice: web3.utils.toHex(gasPrice),
        gas: web3.utils.toHex(gasLimit),
        to: preSaleAddress,
        from: walletAddress,
        data: trx.encodeABI(),
      })
  
      return {
        status: true,
        hash: trxData.transactionHash
      }
  
    } catch(error) {
      return {
        status: false,
        message: error.toString()
    }
    }
  }

  export const UpdateSaleDuration = async (req) => {
    try{
      const {walletProvider, preSaleAddress,tokenAddress,networkId,duration} = req
      const validate = await validateProvider(walletProvider)
      if (!validate.status) {
        return validate;
      }
      let web3 = validate.web3;
      let walletAddress = validate.accounts
      let chain = await validate.chainId

      if(networkId != chain) {
        try{
          await window.ethereum.request({
              method: 'wallet_switchEthereumChain',
              params: [{chainId: networkId}]
            });
      } catch(switchError) {
          console.error('Error',switchError)
          return {
            message: switchError.toString()
          }
      }
    }

      let contractPresale = new web3.eth.Contract(presaleAbi,preSaleAddress)
      let erc20Contract = new web3.eth.Contract(erc20Abi, tokenAddress)
      let newDuration = Math.floor(duration * 24 * 60 * 60) //86400

      let trx = await contractPresale.methods.updatePresaleDuration(tokenAddress,newDuration)

      let gasPrice = await web3.eth.getGasPrice()
      let gasLimit = await web3.eth.estimateGas({
        gasPrice: web3.utils.toHex(gasPrice),
        to: preSaleAddress,
        from: walletAddress,
        data: trx.encodeABI(),
      })
  
      const trxData = await web3.eth.sendTransaction({
        gasPrice: web3.utils.toHex(gasPrice),
        gas: web3.utils.toHex(gasLimit),
        to: preSaleAddress,
        from: walletAddress,
        data: trx.encodeABI(),
      })
  
      return {
        status: true,
        hash: trxData.transactionHash
      }
  
    } catch(error) {
      return {
        status: false,
        message: error.toString()
    }
    }
  }


  export const getUsdtBalance = async (req) => {
    try {
        const {walletProvider, usdtAddress,preSaleAddress,networkId} = req
        const validate = await validateProvider(walletProvider);
        if (!validate.status) {
            return validate;
        }
        let web3 = validate.web3;
        let walletAddress = await validate.accounts
        let chain = await validate.chainId

        if(networkId != chain) {
          try{
            await window.ethereum.request({
                method: 'wallet_switchEthereumChain',
                params: [{chainId: networkId}]
              });
        } catch(switchError) {
            console.error('Error',switchError)
            return {
              message: switchError.toString()
            }
        }
      }

        let usdtContract = new web3.eth.Contract(erc20Abi, usdtAddress);

        let balance = await usdtContract.methods.balanceOf(walletAddress).call();
        //let contractPresale = new web3.eth.Contract(presaleAbi,preSaleAddress)

        let decimals = await usdtContract.methods.decimals().call();
        let allowance = await usdtContract.methods.allowance(walletAddress, preSaleAddress).call();
        console.log({walletAddress,usdtAddress,preSaleAddress,walletProvider,
        status: true,
        balance: parseInt(balance) / 10 ** parseInt(decimals),
        allowance: parseInt(allowance) / 10 ** parseInt(decimals)
})
        return {
            status: true,
            balance: parseInt(balance) / 10 ** parseInt(decimals),
            allowance: parseInt(allowance) / 10 ** parseInt(decimals)
        }
    } catch (error) {
        console.error(error);
    }
};

export const UsdtEarned = async (req) => {
  try{
    const {walletProvider, preSaleAddress,tokenAddress,networkId} = req
    const validate = await validateProvider(walletProvider)
    if (!validate.status) {
      return validate;
    }
    let web3 = validate.web3;
    let walletAddress = await validate.accounts
    let chain = await validate.chainId

    if(networkId != chain) {
      try{
        await window.ethereum.request({
            method: 'wallet_switchEthereumChain',
            params: [{chainId: networkId}]
          });
    } catch(switchError) {
        console.error('Error',switchError)
        return {
          message: switchError.toString()
        }
    }
  }

    let contractPresale = new web3.eth.Contract(presaleAbi,preSaleAddress)

    let trx = await contractPresale.methods.getTotalUsdtEarned(tokenAddress).call()
  if(networkId == web3config.ChaiIdBinanceTestnet) {
    return {
      status: true,
      usdtAmount: Number(trx) / 10**18
    }
  } else {
    return {
      status: true,
      usdtAmount: Number(trx) / 10**6
    }
  }

  } catch(error) {
    return {
      status: false,
      message: error.toString()
  }
  }
}

export const TransferUsdt = async (req) => {
  try{
    const {walletProvider, preSaleAddress,tokenAddress,networkId} = req
    const validate = await validateProvider(walletProvider)
    if (!validate.status) {
      return validate;
    }
    let web3 = validate.web3;
    let walletAddress = validate.accounts
    let chain = await validate.chainId

    if(networkId != chain) {
      try{
        await window.ethereum.request({
            method: 'wallet_switchEthereumChain',
            params: [{chainId: networkId}]
          });
    } catch(switchError) {
        console.error('Error',switchError)
        return {
          message: switchError.toString()
        }
    }
  }
    let contractPresale = new web3.eth.Contract(presaleAbi,preSaleAddress)

    let trx = await contractPresale.methods.sendUsdtToPool(tokenAddress)


    let gasPrice = await web3.eth.getGasPrice()
    let gasLimit = await web3.eth.estimateGas({
      gasPrice: web3.utils.toHex(gasPrice),
      to: preSaleAddress,
      from: walletAddress,
      data: trx.encodeABI(),
    })

    const trxData = await web3.eth.sendTransaction({
      gasPrice: web3.utils.toHex(gasPrice),
      gas: web3.utils.toHex(gasLimit),
      to: preSaleAddress,
      from: walletAddress,
      data: trx.encodeABI(),
    })

    return {
      status: true,
      hash: trxData.transactionHash
    }

  } catch(error) {
    return {
      status: false,
      message: error.toString()
  }
  }
}


export const UserToken = async (req) => {
  try {
   const {walletProvider, preSaleAddress,tokenAddress,networkId} = req 
   const validate = await validateProvider(walletProvider)
   if (!validate.status) {
     console.log("Error in provider",validate)
     return validate;
   }
   let web3 = validate.web3;
   let walletAddress=validate.accounts
   let chain = await validate.chainId

   if(networkId != chain) {
     try{
       await window.ethereum.request({
           method: 'wallet_switchEthereumChain',
           params: [{chainId: networkId}]
         });
   } catch(switchError) {
       console.error('Error',switchError)
       return {
         message: switchError.toString()
       }
   }
   }

   let contractPresale = new web3.eth.Contract(presaleAbi,preSaleAddress)
  let token = await contractPresale.methods.getUserToken(tokenAddress).call({from: walletAddress})

  return {
    status: true,
    tokenAmt: Number(token)
  }
  } catch (error) {
    return {
      status: false,
      message: error.toString()
  }
  }
}

export const UserUsdt = async (req) => {
  try {
   const {walletProvider, preSaleAddress,tokenAddress,networkId} = req 
   const validate = await validateProvider(walletProvider)
   if (!validate.status) {
     console.log("Error in provider",validate)
     return validate;
   }
   let web3 = validate.web3;
   let walletAddress=validate.accounts
   let chain = await validate.chainId

   if(networkId != chain) {
     try{
       await window.ethereum.request({
           method: 'wallet_switchEthereumChain',
           params: [{chainId: networkId}]
         });
   } catch(switchError) {
       console.error('Error',switchError)
       return {
         message: switchError.toString()
       }
   }
   }

   let contractPresale = new web3.eth.Contract(presaleAbi,preSaleAddress)
  let usdt = await contractPresale.methods.getUserUsdt(tokenAddress).call({from:walletAddress})

  return {
    status: true,
    usdtAmt: Number(usdt)
  }
  } catch (error) {
    return {
      status: false,
      message: error.toString()
  }
  }
}


export const RefundStat = async (req) => {
  try{
    const {walletProvider, preSaleAddress,tokenAddress,networkId} = req
    const validate = await validateProvider(walletProvider)
    if (!validate.status) {
      return validate;
    }
    let web3 = validate.web3;
    let walletAddress = await validate.accounts
    let chain = await validate.chainId

    if(networkId != chain) {
      try{
        await window.ethereum.request({
            method: 'wallet_switchEthereumChain',
            params: [{chainId: networkId}]
          });
    } catch(switchError) {
        console.error('Error',switchError)
        return {
          message: switchError.toString()
        }
    }
  }

    let contractPresale = new web3.eth.Contract(presaleAbi,preSaleAddress)

    let trx = await contractPresale.methods.refundStatus(tokenAddress).call({from:walletAddress})

    return {
      status:trx
    }

  } catch(error) {
    return {
      status: false,
      message: error.toString()
  }
  }
}

export const WithdrawStatus = async (req) => {
  try{
    const {walletProvider, preSaleAddress,tokenAddress,networkId} = req
    const validate = await validateProvider(walletProvider)
    if (!validate.status) {
      return validate;
    }
    let web3 = validate.web3;
    let walletAddress = await validate.accounts
    let chain = await validate.chainId

    if(networkId != chain) {
      try{
        await window.ethereum.request({
            method: 'wallet_switchEthereumChain',
            params: [{chainId: networkId}]
          });
    } catch(switchError) {
        console.error('Error',switchError)
        return {
          message: switchError.toString()
        }
    }
  }

    let contractPresale = new web3.eth.Contract(presaleAbi,preSaleAddress)

    let trx = await contractPresale.methods.canWithdraw(tokenAddress).call()

    return {
      status:trx
    }

  } catch(error) {
    return {
      status: false,
      message: error.toString()
  }
  }
}

export const SaleStatus = async (req) => {
  try{
    const {walletProvider, preSaleAddress,tokenAddress,networkId} = req
    const validate = await validateProvider(walletProvider)
    if (!validate.status) {
      return validate;
    }
    let web3 = validate.web3;
    let walletAddress = await validate.accounts
    let chain = await validate.chainId

    if(networkId != chain) {
      try{
        await window.ethereum.request({
            method: 'wallet_switchEthereumChain',
            params: [{chainId: networkId}]
          });
    } catch(switchError) {
        console.error('Error',switchError)
        return {
          message: switchError.toString()
        }
    }
  }

    let contractPresale = new web3.eth.Contract(presaleAbi,preSaleAddress)

    let trx = await contractPresale.methods.isPresaleActive(tokenAddress).call()

    return {
      status:trx
    }

  } catch(error) {
    return {
      status: false,
      message: error.toString()
  }
  }
}


const validateProvider2 = async (walletProvider) => {
  console.log("validateProvider2");
  try {
    if (!walletProvider) {
      console.log("walletProvider");

      return {
        status: false,
        message: `Please connect your web3 wallet`,
      };
    }
    const web3 = new Web3(walletProvider);

    return {
      status: true,
      web3
    };
  } catch (error) {
    console.log("Error in validateProvider :", error);
    return {
      status: false,
      message: "Error in validating provider. Please try again.",
    };
  }
};