import axios from 'axios';
import config from '../config/config';
const serverPath = config.serverPath;

export const request = (path, data, method, loginData) => {
  var options = {
    method: method,
    url: `${serverPath}/${path}`,
    headers: {
      'Content-Type': 'application/json',
    },
    dataType: 'json',
  };

  if (loginData) {
    options.headers['Authorization'] = loginData?.authToken;
  }
  if (method === 'GET') {
    options['params'] = data;
  } else {
    options['data'] = data;
  }
  let res = axios(options);
  res.then((res1) => {});
  return res;
};

export const requestFormData = (path, data, method, loginData) => {
  var form_data = new FormData();

  for (var key in data) {
    form_data.append(key, data[key]);
  }

  var options = {
    method: method,
    url: `${serverPath}/${path}`,
    data: form_data,
    headers: { authorization: loginData?.authToken },
  };
  let res = axios(options);
  res.then((res1) => {});
  return res;
};

export const postRequest = async (path, data, loginData) =>
  await request(path, data, 'POST', loginData);
export const getRequest = async (path, data, loginData) =>
  await request(path, data, 'GET', loginData);
export const putRequest = async (path, data, loginData) =>
  await request(path, data, 'PUT', loginData);
export const deleteRequest = async (path, data, loginData) =>
  await request(path, data, 'DELETE', loginData);
export const postRequestFormData = async (path, data, loginData) =>
  await requestFormData(path, data, 'POST', loginData);
