import Web3 from 'web3';
import web3Config from '../Web3/web3config';
import erc20Abi from '../Web3/abi/erc20Abi.json';
const validateProvider = async (walletProvider) => {
  try {
    walletProvider = await walletProvider;

    if (!walletProvider) {
      return {
        status: false,
        message: `Please connect your web3 wallet`,
      };
    }
    const web3 = new Web3(walletProvider);

    return {
      status: true,
      web3,
    };
  } catch (error) {
    console.log(error);
  }
};

export const checkContractOnChain = async (
  walletProvider,
  smartContractAddress
) => {
  try {
    const validate = await validateProvider(walletProvider);

    if (!validate.status) {
      return validate;
    }
    let web3 = validate.web3;
    let tokenSmartContractInstance = new web3.eth.Contract(
      erc20Abi,
      smartContractAddress
    );

    let smartContractName = await tokenSmartContractInstance.methods
      .name()
      .call();
    return {
      status: true,
      msg: `Data found`,
      name: smartContractName,
    };
  } catch (error) {
    console.log('Error :', error);
    return {
      status: false,
      msg: `Please choose the correct Chain Network or correct the smart contract address according to choosen Chain Network.`,
    };
  }
};
