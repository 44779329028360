import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import config from '../../config';
import {
  Container,
  Row,
  Col,
  Card,
  Button,
  Form,
  InputGroup,
} from 'react-bootstrap';
import * as ACTIONTYPES from '../../redux/actionTypes'
import { Spin } from 'antd';
import { verifyEmail } from '../../Action/action'
import { useDispatch, useSelector } from 'react-redux';
import toast, { Toaster } from 'react-hot-toast';

export default function ForgetPassword()  {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showLoading, setShowLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState('');
  const loginDetails = useSelector((state) => state.auth.LoginDetails);
  const forgetEmails = useSelector((state) => state.auth.ForgetEmail);

  const forgetmail = async () => {
    setShowLoading(true);
    console.log(email, 'email');
    dispatch({ type: ACTIONTYPES.ForgetEmail, payload: email });
    let regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

    let checkEmail = email.match(regex);
    //console.log('che',checkEmail)

    if (checkEmail == null) {
      setShowLoading(false);
      toast.error('Please enter a valid email')
      console.log('Please enter a valid email');
      setEmail("")
      return;
    }
    if (!email) {
      setShowLoading(false);
      console.log('Please enter a valid email')
      toast.error('Please enter a valid email');
      setEmail("")
      return;
    }
    try {
    let res = await verifyEmail({email});
    console.log(res, 'res');
    if (res.success) {
      toast.success(res.message);
      navigate(`${config.baseUrl}changepassword`, {state: {email}});
      return;
    } else {
      toast.error('Email not registered');
      return;
    } } catch(error) {
      toast.error('Something went wrong. Please try again.');
    } finally {
      setShowLoading(false);
    }
  };
  const inputhandler = (e) => {
    setEmail(e.target.value);
  }

  return (
    <>
    <Toaster/>
      <section className="login-section ">
        <div className="login-container">
          <Container fluid className="">
         
                <Row className="justify-content-center">
                 
                  <Col lg={7} md={7} xs={12} className="">
                    <Spin spinning={showLoading}>
                      {/* <div className="loginDiv" style={{ minHeight: "368px" }}> */}
                      <div className="loginDiv pt-5 mt-5">
                        <Row>
                          <Col lg={8} className="mx-auto">
                            <div className="form_content border rounded-5 p-5">
                              <div className="formDiv">
                                <div className="text-center formheadbox">
                                  <h1 className=" mb-1">
                                    Forgot Your Password ?
                                  </h1>
                                  <p className="">
                                    <small>
                                      Enter your email address below.We will
                                      send you an email, where follow
                                      instructions”
                                    </small>
                                  </p>
                                </div>
                                <div className="mb-3">
                                <InputGroup className=" theme-input">
                                    <Form.Control
                                      placeholder="Email"
                                      aria-label="Username"
                                      aria-describedby="basic-addon1"
                                      // helperText={errors.userName}
                                      name="userName"
                                      className="py-2"
                                      defaultValue={""}
                                      onChange={inputhandler}
                                    />
                                  </InputGroup>
                                </div>

                                <div className="text-center mt-3 ">
                                  <Button
                                    variant="primary"
                                    className="py-2 w-100 fw-bold"
                                    type="submit"
                                    // href={`${config.baseurl}changepassword`}
                                    onClick={forgetmail}
                                  >
                                    Request Reset Link
                                  </Button>
                                </div>
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </Spin>
                  </Col>
                </Row>
            
          </Container>
        </div>
      </section>
    </>
  );
};

