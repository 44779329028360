const config = {
    // serverPath: 'http://localhost:8000/users',
    // imagePath: 'http://localhost:8000/users/uploads/',
    serverPath: 'https://launchpad.infimultichain.com/users',
    imagePath: 'https://launchpad.infimultichain.com/media/',

    // serverPath: 'https://espsofttech.org:4321/users',
    // imagePath: 'https://espsofttech.org:4321/users/uploads/',



    priceAPI: 'https://api.coingecko.com/api/v3/coins/markets'
};

export default config;


