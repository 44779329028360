import React from "react";
import { Col, Row } from "react-bootstrap";
import { FaFacebook, FaInstagram, FaXTwitter } from "react-icons/fa6";
import { SiDiscord } from "react-icons/si";
import { Link } from "react-router-dom";

export default function Footer() {
    return (
        <>
            <section className="des_footer mt-4">
                <Row>
                    <Col lg={9}>
                        <div className="d-block d-lg-flex justify-content-center justify-content-lg-start links">
                            <div className="text-center text-lg-start">
                                <Link>About Us</Link>
                            </div>

                            <div className="text-center text-lg-start">
                                <Link>Team</Link>
                            </div>

                            <div className="text-center text-lg-start">
                                <Link>Contact</Link>
                            </div>

                            <div className="text-center text-lg-start">
                                <Link>Blog</Link>
                            </div>

                            <div className="text-center text-lg-start">
                                <Link>Privacy Policy</Link>
                            </div>

                            <div className="text-center text-lg-start">
                                <Link>Cookie Policy</Link>
                            </div>
                        </div>
                    </Col>
                    <Col lg={3}>
                        <div className="icons_wrapper d-flex justify-content-center justify-content-lg-end mt-3 mt-lg-0">
                            <a href="">
                                <FaFacebook />
                            </a>

                            <a href="">
                                <FaInstagram />
                            </a>

                            <a href="">
                                <FaXTwitter />
                            </a>

                            <a href="">
                                <SiDiscord />
                            </a>
                        </div>
                    </Col>
                </Row>
                <hr />

                <div className="d-block d-lg-flex justify-content-between">
                    <a href="">support@infimultichainlaunchpad.com</a>

                    <div>© 2024 Infi Multichain Launchpad. All rights reserved.</div>
                </div>
            </section>
        </>
    )
}