import React, { useState, useEffect } from "react";
import Header from '../Layout/Header';
import Footer from '../Layout/Footer';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import "./contact.css"
import toast, { Toaster } from "react-hot-toast";
import {submitContactUsForm} from '../../Action/action'
import { useNavigate } from "react-router-dom";




export default function Contactus() {

  const [form, setForm] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
    agree: false,
  });
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();



  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm((prevForm) => ({
      ...prevForm,
      [name]: value,
    }))
  };

  const validateForm = async () => {
    const newErrors = {};
    if (!form.name) newErrors.name = "Name is required";
    if (!form.email)
      newErrors.email = "Email is required";
    if (!form.message) newErrors.message = "Message is required";
    console.log(newErrors)
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  }; 

  const handleCall = async(e) => {

  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (await validateForm()) {
      //console.log("Form submitted", form);
      let result = await submitContactUsForm(form)
      console.log('Res',result)
      if (result.success) {
        toast.success('Submitted successfully. Please wait we will contact you soon');
        setForm({name:'', email:'', phone:'', message:''})
        setErrors({})
      } else {
        toast.error('Error submitting form. Please try after sometime')
      }

    } else {
      toast.error("Please check the validations required.");
    }
  };
  
  return (
    <>
    <Toaster/>
      <Header />
      <section className='contact py-4 pb-5'>
        <Container>
          <Row>
            <Col lg={12}>
              <div>
                <h1>Contact Us</h1>
                <div className=' mt-4'>
                  <Row>

                    <Col lg={8} className='mb-2'>
                      <Form>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                          <Form.Label>Name</Form.Label>
                          <Form.Control 
                          type="text" 
                          name="name"
                          onChange={handleChange}
                          value={form.name}
                          isInvalid={!!errors.name}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.name}
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                          <Form.Label>Email</Form.Label>
                          <Form.Control 
                          type="text" 
                          name="email"
                          onChange={handleChange}
                          value={form.email}
                          isInvalid={!!errors.email}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.email}
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                          <Form.Label>Phone</Form.Label>
                          <Form.Control 
                          type="text" 
                          name="phone"
                          onChange={handleChange}
                          value={form.phone}
                          isInvalid={!!errors.phone}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.phone}
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                          <Form.Label>Message</Form.Label>
                          <Form.Control 
                          as="textarea" 
                          name="message"
                          rows={3}
                          onChange={handleChange}
                          value={form.message}
                          isInvalid={!!errors.message}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.message}
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Button variant='dark' onClick={handleSubmit}>Submit</Button>

                      </Form>
                    </Col>
                    <Col lg={4} className='mb-2'>
                      <div class="contact-address bg-light px-4 py-3">
                        <div class="mb-4">
                          <div class="text-uppercase maincolor1 fw-bolder mb-1">Our location</div>
                          <p class="mb-0">Inverted Investment LLC 
                                          66 W Flagler Street, Suite 900
                                          PMB 10673
                                          Miami, FL 33130, USA
                            </p>
                        </div>
                        <div class="mb-4">
                        <p>
                        <Button variant='dark' onClick={(e) => {
                              e.preventDefault()
                              window.open("https://calendly.com/inverted-investment", "_blank")
                        }}>Book a call with us</Button>
                          </p>
                        </div>
                        <div class="">
                          <div class="text-uppercase maincolor1 fw-bolder mb-1">Email us</div>
                          <p class=" mb-0"><a href='mailto: info@invertedinvestment.com'className='me-3 text-decoration-none d-inline-block'> info@invertedinvestment.com</a>
                          </p>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            </Col>
          </Row>
        </Container>

      </section>
      <Footer />
    </>
  );
}
