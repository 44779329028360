import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Index from './component/Home';
import config from './config';
//import IndexUpcoming from './component/UpcomingProject/index';
//import ModalBootstrap from './component/BuyToken/Modal';
import Login from './component/Login/LogIn';
import SidebarInfi from './component/Dashboard/Sidebar';
import { useEffect, useState } from 'react';
import OutletInfi from './component/Dashboard';
import { Route, Routes, BrowserRouter } from 'react-router-dom';
import Liquidity from '../src/component/Dashboard/Pages/Liquidity';
import InfiLiquidity from './component/Dashboard/Pages/InfiLiquidity';
import Signup from './component/Login/Signup';
import ChangePassword from './component/Login/changePassword';
import ForgetPassword from './component/Login/forgetPassword';
import LaunchProcessForm from './component/pages/LaunchProcessForm';
import LaunchListingForm from './component/pages/LaunchListingForm';
import LiquidityListingForm from './component/pages/LiquidityListingForm';
import LiquidityLaunchForm from './component/pages/LiquidityLaunchForm';
import InfiListingForm from './component/pages/InfiListingForm';
import InfiLaunchForm from './component/pages/infiLaunchForm';
import PreLiquidity from './component/pages/PreLiquidity';
import Detail from './component/Detail';
import Dashboard from './component/Detail/dashboard';
import LiquidityDetail from './component/LiquidityDetail';
import InfiDetail from './component/InfiSwapDetail';
import Howitwork from './component/Howitwork';
import Faq from './component/Faq';
import Aboutus from './component/Aboutus';
import Contactus from './component/Contactus';

function App() {
  const [collapsed, setCollapsed] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (width < 600) {
      setCollapsed(true);
    } else {
      setCollapsed(false);
    }
  }, [window.innerWidth]);
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path={`${config.baseUrl}`} element={<Signup />} />
          <Route path={`${config.baseUrl}login`} element={<Login />} />
          <Route
            path={`${config.baseUrl}changepassword`}
            element={<ChangePassword />}
          />
          <Route
            path={`${config.baseUrl}forgetpassword`}
            element={<ForgetPassword />}
          />
          <Route path={`${config.baseUrl}home`} element={<Index />} />
          <Route path={`${config.baseUrl}about`} element={<Aboutus />} />
          <Route path={`${config.baseUrl}howitwork`} element={<Howitwork />} />
          <Route path={`${config.baseUrl}faq`} element={<Faq />} />
          <Route path={`${config.baseUrl}contact`} element={<Contactus />} />

          {/* <Route path={`${config.baseUrl}upcoming`} element={<IndexUpcoming />} /> */}
          <Route path={`${config.baseUrl}detail/:id`} element={<Detail />} />
          <Route
            path={`${config.baseUrl}detaildashboard`}
            element={<Dashboard />}
          />
          {/* <Route
          path={`${config.baseUrl}popup/:id`}
          element={<ModalBootstrap />}
        /> */}

          <Route
            path={`${config.baseUrl}launchprocessform`}
            element={<LaunchProcessForm />}
          />
          <Route
            path={`${config.baseUrl}launchListingForm/:id`}
            element={<LaunchListingForm />}
          />
          <Route
            path={`${config.baseUrl}liquidityListingForm`}
            element={<LiquidityListingForm />}
          />

          <Route
            path={`${config.baseUrl}liquidityLaunchForm/:id`}
            element={<LiquidityLaunchForm />}
          />

          <Route
            path={`${config.baseUrl}infiListingForm`}
            element={<InfiListingForm />}
          />

          <Route
            path={`${config.baseUrl}infiLaunchForm/:id`}
            element={<InfiLaunchForm />}
          />

          <Route
            path={`${config.baseUrl}preliquidity/:id`}
            element={<PreLiquidity />}
          />

          <Route
            path={`${config.baseUrl}liquidityDetail/:id`}
            element={<LiquidityDetail />}
          />

          <Route
            path={`${config.baseUrl}infiDetail/:id`}
            element={<InfiDetail />}
          />

          {/* ======================== Dashboard ========================= */}
          <Route
            path={`${config.baseUrl}dashboard/`}
            element={
              <OutletInfi
                collapsed={collapsed}
                setCollapsed={setCollapsed}
                width={width}
              />
            }
          >
            <Route
              path={`${config.baseUrl}dashboard/liquidity/:id`}
              element={<Liquidity />}
            />

            <Route
              path={`${config.baseUrl}dashboard/infiLiquidity/:id`}
              element={<InfiLiquidity />}
            />
          </Route>
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
