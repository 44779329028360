import React from 'react';
import Header from '../Layout/Header';
import SidebarInfi from './Sidebar';
import { Outlet } from 'react-router-dom';
import { Container } from 'react-bootstrap';

export default function OutletInfi(props) {
  const { collapsed, setCollapsed, width } = props;
  return (
    <>
      <div style={{ height: '100vh' }}>
        <Header
          collapsed={collapsed}
          setCollapsed={setCollapsed}
          width={width}
        />
        <SidebarInfi
          collapsed={collapsed}
          setCollapsed={setCollapsed}
          width={width}
        />
        <div
          className="main_outlet_wrapper"
          style={
            collapsed
              ? { marginLeft: '0px' }
              : width < 600
              ? { marginLeft: '0px' }
              : { marginLeft: '250px' }
          }
        >
          <Container className="h-100">
            <Outlet />
          </Container>
        </div>
      </div>
    </>
  );
}
