export const showLoader = () => {
  const existingLoader = document.getElementById('global_loader');
  if (!existingLoader) {
    const loaderDiv = document.createElement('div');
    loaderDiv.innerHTML = `
            <div class="loader_parent" id="global_loader">
                <div class="loader"></div>
            </div>`;
    document.body.appendChild(loaderDiv);
  }
};

export const hideLoader = () => {
  const loaderElement = document.getElementById('global_loader');
  if (loaderElement && loaderElement.parentNode) {
    loaderElement.parentNode.removeChild(loaderElement);
  }
};
