import React, { useEffect, useState, useRef } from "react";
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Container, Row, Col, Form, InputGroup, Button, Tab, Nav } from 'react-bootstrap';
import config from "../../config"
import "../Detail/detail.css"
import { getLiquidityFormDetailsById } from "../../Action/action"
import Web3WalletProvider from "../../ConnectWallet/walletProvider"
import { useDisconnect, useAccount } from "wagmi";
import { toast, Toaster } from 'react-hot-toast';
import { FaArrowRightLong } from "react-icons/fa6";
import ReadMore from "../Detail/readmore";
import { GrSync } from "react-icons/gr";
import { ApproveToken, getTokenPriceSwap, PoolBalances, getUserTokenBalance, getUserUsdtBalance, ApproveUsdtToken, ReceivableUsdt, ReceivableToken, SwapTokenToUsdt, SwapUsdtToToken } from "../../Web3/Liquidity/swapConnector";
import { showLoader, hideLoader } from "../loader";
import Chart from 'react-apexcharts';



export default function LiquidityDetail() {
    const { id } = useParams()
    const [form, setForm] = useState([]);
    const loginDetails = useSelector((state) => state.auth.LoginDetails);
    const walletConnnected = useSelector((state) => state.auth.walletConnnected);
    console.log('WWAAA', walletConnnected)
    const navigate = useNavigate();
    const _useWeb3WalletProvider = Web3WalletProvider()
    const { address, isConnected } = useAccount()
    const [connectWalletNow, setconnectWalletNow] = useState(false);
    const [Waddress, setAddress] = useState("");

    // const [expectedUsdt, setExpectedUsdt] = useState({});
    // const [expectedToken, setExpectedToken] = useState({});
    const [swapClick, setSwapClick] = useState(false)
    const _useDisconnect = useDisconnect()
    const [isProviderLoaded, setisProviderLoaded] = useState(false);


    const [swapLoading, setSwapLoading] = useState(false);
    const [usdt, setUsdt] = useState(0);
    const [token, setToken] = useState(0);
    const [isUsdtFirst, setIsUsdtFirst] = useState(true)
    const [toTreasury, setToTreasury] = useState(false);

    // const [series, setSeries] = useState([{name: 'Token Price', data: []}]);

    // const [options, setOptions] = useState({
    //     chart: {
    //         type: 'line',
    //         height: '455px',
    //         offsetY: 0,
    //     },
    //     title: {
    //         text: 'Price Chart',
    //         align: 'left',
    //         style: {
    //             fontSize: '32px',
    //             fontWeight: '700',
    //             color: '#000', 
    //             fontFamily: '"Darker Grotesque", sans-serif',
    //         },
    //     },

    //     xaxis: {
    //         type: 'datetime',
    //         max: new Date().getTime(),
    //         labels: {
    //             formatter: function(val) {
    //                 const date = new Date(val)
    //                 return date.toLocaleDateString('en-GB')
    //             }
    //         }
    //     },
    //     yaxis: {
    //         labels: {
    //             formatter: function(val) {
    //                 return val.toFixed(2)
    //             }
    //         },
    //         tooltip: {
    //             enabled: true
    //         }
    //     },
    // });

    const [priceArray, setPriceArray] = useState([])
    const [price,setPrice] = useState(null)

    useEffect(() => {
        const customStyles = {

        };

        // Apply custom styles to the body
        for (const [key, value] of Object.entries(customStyles)) {
            document.body.style[key] = value;
        }

        // Cleanup function to reset styles when component unmounts (optional)
        return () => {
            for (const key of Object.keys(customStyles)) {
                document.body.style[key] = "";
            }
        };
    }, []);

    useEffect(() => {

        if (walletConnnected) {
            setconnectWalletNow(false)
        }
        setAddress(address)
    }, [walletConnnected])

    const disconnectWallet = () => {
        _useDisconnect.disconnect()
    }

    useEffect(() => {
        if (!isProviderLoaded) {
            _useWeb3WalletProvider.then((data) => {
                if (data) {
                    setisProviderLoaded(true)
                }
            })
        }
    }, [_useWeb3WalletProvider])
    const buttonRef = useRef(null); // Add a ref for the button

    useEffect(() => {
        // AutoBNBally click the button after 2 seconds
        const timer = setTimeout(() => {
            if (buttonRef.current) {
                buttonRef.current.click();
            }
        }, 2000);

        return () => clearTimeout(timer); // Cleanup the timer
    }, []);


    useEffect(() => {
        getFormData()

        //   
        const intervalId = setInterval(async () => {
        try {
            let tp = await getTokenPriceSwap(form);
            const latestPrice = tp.data[0];
            setPrice(latestPrice);

            setPriceArray((prevPrices) => [
                ...prevPrices,
                { x: new Date().getTime(), y: Number(latestPrice) }
            ]);
        } catch (error) {
            console.log("Error fetching latest price:", error);
        }
    }, 60000);

    // Clean up the interval on component unmount
    return () => clearInterval(intervalId);
    }, [])

    const getFormData = async () => {
        try {
            const response = await getLiquidityFormDetailsById({ id });
            console.log('FORM', response)
            if (response.success) {
                if (response.data.length > 0) {

                    let pb = await poolTokenBalance(response.data[0])
                    response.data[0].BALANCES = pb

                    let tp = await getTokenPriceSwap(response.data)
                    let PP = tp.data[0]
                    //console.log("P",PP)
                    response.data[0].PRICE = tp.data[0]

                    const initialPricePoint = { x: new Date().getTime(), y: Number(PP) };
                    setPriceArray([initialPricePoint]);
    
                    // const startDate = new Date(response.data[0].create_at);
                    // const endDate = new Date();
                    // const dateArray = [];
                    // const pricesArray = [];
                    
                    // for (let dt = new Date(startDate); dt <= endDate; dt.setMinutes(dt.getMinutes() + 1)) {
                    //     dateArray.push(new Date(dt).getTime());
                    // }

                    // dateArray.forEach(date => {
                    //     pricesArray.push({ x: date, y: Number(PP) });
                    // });

                    console.log("zxc",priceArray)
                    // setSeries([{name: 'Token Price', data: pricesArray}])

                    let balUsdt = await WalletUsdtBalance(response.data[0])
                    response.data[0].BALANCEUSDT = balUsdt

                    let balToken = await WalletTokenBalance(response.data[0])
                    response.data[0].BALANCETOKEN = balToken

                    let uData = await ExpectedUsdt(response.data[0], 0)
                    response.data[0].usdtData = uData
                    console.log('UUUU', uData)

                    let uToken = await ExpectedToken(response.data[0], 0)
                    response.data[0].tokenData = uToken
                    console.log('TTTT', uToken)

                }
                setForm(response.data);            }
        } catch (error) {
            console.log(error);
        }
    };


    const WalletUsdtBalance = async (formData) => {
        try {
            let request = {
                walletProvider: _useWeb3WalletProvider,
                usdtContract: formData.usdtContract,
                pairContractAddress: formData.pairContractAddress,
                networkId: formData.chainNetworkId
            }
            let usdtBal = await getUserUsdtBalance(request)
            return usdtBal.balance
        } catch (error) {
            console.log("Error getting wallet usdt balance", error)
        }
    }

    const WalletTokenBalance = async (formData) => {
        try {
            let request = {
                walletProvider: _useWeb3WalletProvider,
                pairContractAddress: formData.pairContractAddress,
                tokenAddress: formData.token_address,
                networkId: formData.chainNetworkId
            }
            let tokenBal = await getUserTokenBalance(request)
            return tokenBal.balance
        } catch (error) {
            console.log("Error getting wallet token balance", error)
        }
    }

    const ExpectedUsdt = async (formData, _token) => {
        try {
            let request = {
                walletProvider: formData.chainRPCUrl,
                pairContractAddress: formData.pairContractAddress,
                tokenAddress: formData.token_address,
                tokenAmount: _token,
                networkId: formData.chainNetworkId
            }
            let res = await ReceivableUsdt(request)
            if (res.length > 0) {
                return res[0].data
            }
        } catch (error) {
            console.log("Error getting expected usdt", error)
        }
    }

    const ExpectedToken = async (formData, _usdt) => {
        try {
            let request = {
                walletProvider: formData.chainRPCUrl,
                pairContractAddress: formData.pairContractAddress,
                tokenAddress: formData.token_address,
                usdtAddress: formData.usdtContract,
                usdtAmount: _usdt,
                networkId: formData.chainNetworkId
            }
            let res = await ReceivableToken(request)
            console.log('EUR', res)
            if (res.length > 0) {
                return res[0].data
            }
        } catch (error) {
            console.log("Error getting expected token", error)
        }
    }


    const poolTokenBalance = async (formData) => {
        let request = {
            walletProvider: formData.chainRPCUrl,
            tokenAddress: formData.token_address,
            pairContractAddress: formData.pairContractAddress,
            networkId: formData.chainNetworkId,
        }
        const res = await PoolBalances(request)
        if (res.length > 0) {
            return (res[0].data)
        }
    }

    const handleUsdtToTokenSwap = async () => {
        if (!isConnected) {
            toast.error("Please connect with the registered wallet.");
            setconnectWalletNow(true)
            return
        }
        if (address.toUpperCase() != loginDetails.walletAddress.toUpperCase()) {
            toast.error("Please connect with registered wallet")
            return
        }
        try {
            setSwapLoading(true)
            showLoader()
            let request = {
                walletProvider: _useWeb3WalletProvider,
                pairContractAddress: form.length > 0 ? form[0].pairContractAddress : '',
                usdtAddress: form.length > 0 ? form[0].usdtContract : '',
                tokenAddress: form.length > 0 ? form[0].token_address : '',
                usdtAmount: usdt,
                toTreasury: toTreasury,
                networkId: form.length > 0 ? form[0].chainNetworkId : '',
            }
            let usdtApp = await ApproveUsdtToken(request)

            if (usdtApp.status) {
                let swap = await SwapUsdtToToken(request)
                console.log('swapres', swap)
                if (swap.status) {
                    toast.success(swap.message)
                    window.location.reload()
                } else {
                    toast.error(swap.message)
                    window.location.reload()
                }
            } else {
                toast.error(usdtApp.message)
                setUsdt("")
                setToken("")
                window.location.reload()
            }

        } catch (error) {
            console.log('usdt to token Swap error', error)
            setUsdt("")
            setToken("")

        } finally {
            hideLoader()
            setSwapLoading(false);
        }
    }

    const handleTokenToUsdtSwap = async () => {
        if (!isConnected) {
            toast.error("Please connect with the registered wallet.");
            setconnectWalletNow(true)
            return
        }
        if (address.toUpperCase() != loginDetails.walletAddress.toUpperCase()) {
            toast.error("Please connect with registered wallet")
            return
        }
        try {
            setSwapLoading(true)
            showLoader()
            let request = {
                walletProvider: _useWeb3WalletProvider,
                pairContractAddress: form.length > 0 ? form[0].pairContractAddress : '',
                usdtAddress: form.length > 0 ? form[0].usdtContract : '',
                tokenAddress: form.length > 0 ? form[0].token_address : '',
                tokenAmount: token,
                toTreasury: toTreasury,
                networkId: form.length > 0 ? form[0].chainNetworkId : '',
            }
            let tokenApp = await ApproveToken(request)
            if (tokenApp.status) {
                let swap = await SwapTokenToUsdt(request)
                if (swap.status) {
                    toast.success(swap.message)
                    setSwapLoading(false)
                    window.location.reload()
                } else {
                    setSwapLoading(false)
                    toast.error(swap.message)
                    window.location.reload()
                }
                setUsdt("")
                setToken("")
            }
            else {
                toast.error(tokenApp.message)
                setUsdt("")
                setToken("")
                window.location.reload()
            }
        } catch (error) {
            console.log('token to usdt Swap error', error)
            setUsdt("")
            setToken("")
        } finally {
            hideLoader()
            setSwapLoading(false)
        }
    }

    const onchangehandlerUsdt = async (e) => {
        e.preventDefault()
        let newUsdt = e.target.value
        setUsdt(newUsdt);
        let newForm = [...form]
        let res = await ExpectedToken(newForm[0], newUsdt)
        newForm[0].tokenData = res
        let priceImpact = 15
        //console.log('Impact',(form[0].BALANCES.Token * priceImpact)/100,res.Token)
        if ((form[0].BALANCES.Token * priceImpact) / 100 <= res.Token) {
            newForm[0].error = "Price Impact too high.Try a different value"
        } else {
            newForm[0].error = ""
        }
        setForm(newForm)
    }

    const onchangehandlerToken = async (e) => {
        e.preventDefault()
        let newToken = e.target.value
        setToken(newToken)
        let updatedForm = [...form]
        let res = await ExpectedUsdt(updatedForm[0], newToken)
        updatedForm[0].usdtData = res
        let priceImpact = 15
        //console.log('Impact',(form[0].BALANCES.USDT * priceImpact)/100,res.USDT)
        if ((form[0].BALANCES.Token * priceImpact) / 100 <= res.USDT) {
            updatedForm[0].error = "Price Impact too high.Try a different value"
        } else {
            updatedForm[0].error = ""
        }
        setForm(updatedForm)

    }


    const handleToggle = (e) => {
        //e.preventDeafult()
        setToTreasury(prev => !prev)
    }

    const changeCoins = (e) => {
        // e.preventDefault()
        setIsUsdtFirst(!isUsdtFirst)
    }

    const handleDashboard = async (e) => {
        e.preventDefault()
        navigate(`${config.baseUrl}dashboard/liquidity/${id}`)
    }


    const TokenPriceChart = ({ pricesArray, currentPrice }) => {
        const options = {
            chart: {
                id: 'token-price-chart',
                type: 'bar',
                zoom: { enabled: false },
            },
            xaxis: {
                type: 'datetime',
                labels: {
                    datetimeUTC: false,
                },
            },
            yaxis: {
                title: { text: 'Price' },
                labels: {
                    formatter: (val) => `$${val}`,
                },
            },
            tooltip: {
                x: { format: 'dd MMM HH:mm' },
                y: { formatter: (val) => `$${val}` },
            },
            plotOptions: {
                bar: {
                    borderRadius: 2,
                    dataLabels: {
                        position: 'top',
                    },
                },
            },
            dataLabels: {
                enabled: true,
                formatter: (val) => `$${val}`,
                offsetY: -10,
                style: {
                    fontSize: '12px',
                    colors: ["#304758"]
                }
            },
        };
    
        const series = [
            {
                name: 'Token Price',
                data: pricesArray,
            },
        ];
    
        return (
            <div>
                <Chart options={options} series={series} type="line" height={350} />
            </div>
        );
    };
    

    return (
        <>
            <Header />
            <Toaster></Toaster>
            <div className="detail process_form my-0 mt-0">
                <div className="infivideo">
                    <video autoPlay muted loop id="myVideo">
                        <source src={`${config.baseUrl}images/banner/INFIBGVIDEO.mp4`} type="video/mp4" />
                        Your browser does not support HTML5 video.
                    </video>
                </div>
                <Container>
                    <Row> 
                    <Col lg={12} className="text-end mb-4">
                            {form.length > 0 && form[0].walletAddress === address && (
                                <Button variant="light" style={{marginTop: "35px"}} onClick={(e) => handleDashboard(e)}>
                                    Go To Dashboard&nbsp;<FaArrowRightLong size={`15px`} style={{ marginTop: "-3px" }} className="ms-1" />
                                </Button>
                            )} 
                     </Col> 
                    </Row> 
                    <Row>
                        <Col lg={3} className="pe-lg-0">
                            <div className="leftsidebar py-sm-5 py-4 row">
                                {form.length > 0 &&
                                    <Col lg={12}>
                                        <div className="image-wrapper mb-4 text-md-center">
                                            <img src={`${config.imagePath}${form[0].brand_logo}`} alt="" className="img-fluid" />
                                        </div>
                                    </Col>}
                                {form.length > 0 &&
                                    <Col lg={12}>
                                        <div className="item_info mb-lg-4">
                                            <Row>
                                                <Col lg={12} className="mb-4">
                                                    <div className="d-flex justify-content-between">
                                                        <div>
                                                            <h2>{form[0].token_name}</h2>
                                                            {form[0].description.length > 100 ?

                                                                <p><ReadMore text={form[0].description} maxLength={100} /></p> :
                                                                <p>{form[0].description}</p>
                                                            }
                                                        </div>
                                                        <div className="text-right pt-2">
                                                            <img src={`${config.imagePath}${form[0].chainName}.png`} alt="" width={35} />
                                                        </div>
                                                    </div>

                                                    <div className="item_info_counts">
                                                        <ul className="list-unstyled ps-0">
                                                            <li>
                                                                <div className="item_info_type" >
                                                                    <a href={`${form[0].website}`} className="text-decoration-none"> Website</a>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className="item_info_type" >
                                                                    <a href={`${form[0].whitepaper}`} className="text-decoration-none"> Whitepaper </a>
                                                                </div>
                                                            </li>

                                                        </ul>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <div className="d-inline-block">
                                                <div className="price_attr">
                                                    <label className="mb-0 fw-medium">Token Price</label>
                                                    <h5>${(form[0].PRICE)}</h5>
                                                </div>
                                                <div className="price_attr">
                                                    <label className="mb-0 fw-medium">Start Date</label>
                                                    <h5>{new Date(form[0].create_at).toISOString().split('T')[0]}</h5>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>}
                            </div>
                        </Col>
                        <Col lg={5}>
                            <div className=" my-lg-5 my-lg-4">
                                <div>
                                    <div id="chart" className="border rounded-1">
                                    <TokenPriceChart pricesArray={priceArray} currentPrice={price} />
                                    {/* <Chart options={options} series={series} type="line" height="455px"/> */}
                                    </div>
                                    <div id="html-dist"></div>
                                </div>
                            </div>
                        </Col>
                        <Col lg={4}>
                            <Row className="justify-content-center ">
                                {form.length > 0 &&
                                    <Col lg={12}>
                                        <Tab.Container id="left-tabs-example" defaultActiveKey="buy" >
                                            <Row>
                                                <Col sm={12} className="tab-style1 py-lg-5 py-4 rightsidebar">
                                                    <Nav variant="pills" className="nav-tabs border-0">

                                                        <Nav.Item>
                                                            <div className="">
                                                                <h2 className="mb-0 pt-1">Trade</h2>
                                                            </div>
                                                            <div className="Swapform from_box outlineField px-sm-0 position-relative mt-0 bg-transparent border-0 pt-0">
                                                                {isUsdtFirst ? (
                                                                    <div>
                                                                        <Row>
                                                                            <Col lg={12}>

                                                                                <div className="position-relative">
                                                                                    {form.length > 0 &&
                                                                                        <div className="text-end">
                                                                                            <h6 className="mb-1">Balance: <span className="fw-medium">{form[0].BALANCEUSDT}</span></h6>
                                                                                        </div>
                                                                                    }
                                                                                    <Form.Label className="small form-label position-absolute z-2 p-2">
                                                                                        You Send
                                                                                    </Form.Label>
                                                                                    <InputGroup className="mb-0 border rounded-1">
                                                                                        <Form.Control
                                                                                            placeholder=""
                                                                                            aria-describedby="basic-addon2"
                                                                                            name="UsdtAmount"
                                                                                            className="rounded-2 form-control z-1 text-end border-0"
                                                                                            style={{ paddingLeft: "50px" }}
                                                                                            value={usdt || ""}
                                                                                            onChange={onchangehandlerUsdt}
                                                                                        />
                                                                                        <Button
                                                                                            variant="swap"
                                                                                            type="button"
                                                                                            className="border-start border-0 h-auto text-blue  end-0 rounded-2 z-3 cursor-pointer swapbtn"
                                                                                            onClick={(e) => changeCoins(e)}
                                                                                        >
                                                                                            <div className="d-flex align-items-center">
                                                                                                <img src={`${config.baseUrl}images/usdt.svg`} width="25px" />
                                                                                                &nbsp; USDT
                                                                                            </div>
                                                                                        </Button>
                                                                                    </InputGroup>
                                                                                    {form[0].error && (
                                                                                        <div className="text-danger mt-1">
                                                                                            {form[0].error}
                                                                                        </div>
                                                                                    )}
                                                                                </div>
                                                                            </Col>
                                                                            {form.length > 0 &&
                                                                                <Col lg={12} className="text-end my-3">
                                                                                    <div className="swapIcon">

                                                                                        <Button variant="primary" className="text-white" onClick={(e) => changeCoins(e)}>
                                                                                            <GrSync />
                                                                                        </Button>

                                                                                    </div>
                                                                                </Col>}
                                                                            <Col lg={12}>
                                                                                {form.length > 0 && (
                                                                                    <div className="mb-1 position-relative" style={{ marginTop: "-1px" }}>
                                                                                        {console.log('1234', form[0].tokenData.Token)}
                                                                                        <Form.Label className="small form-label position-absolute z-2 p-2">
                                                                                            You Get
                                                                                        </Form.Label>
                                                                                        <InputGroup className="border rounded-1">
                                                                                            {console.log("///", form[0].tokenData.Token)}
                                                                                            <Form.Control
                                                                                                placeholder=""
                                                                                                aria-describedby="basic-addon2"
                                                                                                name={`${form[0].token_name}`}
                                                                                                className=" form-control z-1 text-end border-0"
                                                                                                style={{ paddingLeft: "50px" }}
                                                                                                value={form[0].tokenData.Token || ""}
                                                                                                disabled
                                                                                            />
                                                                                            <Button
                                                                                                variant="swap"
                                                                                                type="button"
                                                                                                className="border-start border-0 h-auto text-blue end-0 rounded-2 z-3 cursor-pointer swapbtn"
                                                                                                onClick={(e) => changeCoins(e)}
                                                                                            >
                                                                                                <div className="d-flex align-items-center">
                                                                                                    <img src={`${config.imagePath}${form[0].brand_logo}`} width="25px" />
                                                                                                    &nbsp; {form[0].token_name}
                                                                                                </div>
                                                                                            </Button>

                                                                                        </InputGroup>
                                                                                        {form.length > 0 &&
                                                                                            <div className="text-end">
                                                                                                <h6 className="mb-0 mt-1">Balance: <span className="fw-medium">{form[0].BALANCETOKEN}</span></h6>
                                                                                            </div>
                                                                                        }
                                                                                    </div>
                                                                                )}
                                                                            </Col>

                                                                            {form.length > 0 && (
                                                                                <Col lg={12}>
                                                                                    <div className="d-flex justify-content-between">
                                                                                        <div className="mb-3">
                                                                                            <h6 className="mb-0">Price: 1 {form[0].token_name} =  <span className="fw-medium">{(form[0].PRICE)} USDT </span></h6>
                                                                                            <h6 className="mb-0">Price Impact: <span className="fw-medium">15%</span> </h6>
                                                                                            <h6 className="mb-0">Fees: <span className="fw-medium">{form[0].tokenData.Fees || 0} USDT </span></h6>
                                                                                            <h6 className="mb-0">Cash Back: <span className="fw-medium">{form[0].tokenData.BuyBack || 0} USDT</span> </h6>
                                                                                        </div>
                                                                                    </div>
                                                                                    <Button
                                                                                        variant="primary"
                                                                                        className="w-100 fw-medium mb-3"
                                                                                        onClick={(e) => handleUsdtToTokenSwap(e)}
                                                                                        disabled={!usdt || !!form[0].error}
                                                                                    >
                                                                                        Swap
                                                                                    </Button>
                                                                                </Col>
                                                                            )}
                                                                        </Row>
                                                                    </div>
                                                                ) : (
                                                                    <div>
                                                                        <Row>
                                                                            {form.length > 0 && (
                                                                                <Col lg={12}>
                                                                                    <div className=" position-relative" style={{ marginTop: "-1px" }}>
                                                                                        {form.length > 0 &&
                                                                                            // <div className="text-end">
                                                                                            //     <h6 className="mb-1">Balance: <span className="fw-medium">{form[0].BALANCEUSDT}</span></h6>
                                                                                            // </div>
                                                                                            <div className="text-end">
                                                                                                <h6 className="mb-1">Balance: <span className="fw-medium">{form[0].BALANCETOKEN}</span></h6>
                                                                                            </div>
                                                                                        }
                                                                                        <Form.Label className="small form-label position-absolute z-2 p-2">
                                                                                            You Send
                                                                                        </Form.Label>
                                                                                        <InputGroup className="mb-0 border rounded-1">
                                                                                            <Form.Control
                                                                                                placeholder=""
                                                                                                aria-describedby="basic-addon2"
                                                                                                name="TokenAmount"
                                                                                                className=" form-control z-1 text-end border-0"
                                                                                                style={{ paddingLeft: "50px" }}
                                                                                                value={token || ""}
                                                                                                onChange={onchangehandlerToken}
                                                                                            />
                                                                                            <Button
                                                                                                variant="swap"
                                                                                                type="button"
                                                                                                className="border-start border-0 h-auto text-blue end-0 rounded-2 z-3 cursor-pointer swapbtn"
                                                                                                onClick={(e) => changeCoins(e)}
                                                                                            >
                                                                                                <div className="d-flex align-items-center">
                                                                                                    <img src={`${config.imagePath}${form[0].brand_logo}`} width="25px" />
                                                                                                    &nbsp; {form[0].token_name}
                                                                                                </div>
                                                                                            </Button>
                                                                                        </InputGroup>
                                                                                        {form[0].error && (
                                                                                            <div className="text-danger mt-1">
                                                                                                {form[0].error}
                                                                                            </div>
                                                                                        )}
                                                                                    </div>
                                                                                </Col>
                                                                            )}
                                                                            {form.length > 0 &&
                                                                                <Col lg={12} className="text-end my-3">
                                                                                    <div className="swapIcon">

                                                                                        <Button variant="primary" className="text-white" onClick={(e) => changeCoins(e)}>
                                                                                            <GrSync />
                                                                                        </Button>

                                                                                    </div>
                                                                                </Col>}
                                                                            <Col lg={12}>
                                                                                <div className="position-relative mb-1">
                                                                                    <Form.Label className="small form-label position-absolute z-2 p-2">
                                                                                        You Get
                                                                                    </Form.Label>
                                                                                    <InputGroup className="mb-0 border rounded-1">
                                                                                        <Form.Control
                                                                                            placeholder=""
                                                                                            aria-describedby="basic-addon2"
                                                                                            name="UsdtAmount"
                                                                                            className=" form-control z-1 text-end border-0"
                                                                                            style={{ paddingLeft: "50px" }}
                                                                                            value={form[0].usdtData.USDT || ""}
                                                                                            disabled
                                                                                        />
                                                                                        <Button
                                                                                            variant="swap"
                                                                                            type="button"
                                                                                            className="border-start border-0 h-auto text-blue end-0 rounded-2 z-3 cursor-pointer swapbtn"
                                                                                            onClick={(e) => changeCoins(e)}
                                                                                        >
                                                                                            <div className="d-flex align-items-center">
                                                                                                <img src={`${config.baseUrl}images/usdt.svg`} width="25px" />
                                                                                                &nbsp; USDT
                                                                                            </div>
                                                                                        </Button>
                                                                                    </InputGroup>
                                                                                    {form.length > 0 &&
                                                                                        // <div className="text-end">
                                                                                        //     <h6 className="mb-0 mt-1">Balance: <span className="fw-medium">{form[0].BALANCETOKEN}</span></h6>
                                                                                        // </div>
                                                                                        <div className="text-end">
                                                                                            <h6 className="mb-0 mt-1">Balance: <span className="fw-medium">{form[0].BALANCEUSDT}</span></h6>
                                                                                        </div>

                                                                                    }
                                                                                </div>
                                                                            </Col>
                                                                            {form.length > 0 && (
                                                                                <Col lg={12}>
                                                                                    <div className="d-flex justify-content-between">
                                                                                        <div className="mb-3">
                                                                                            <h6 className="mb-0">Fees: <span className="fw-medium">{form[0].usdtData.Fees || 0} USDT </span></h6>
                                                                                            <h6 className="mb-0">Price Impact: <span className="fw-medium">15%</span></h6>
                                                                                            <h6 className="mb-0">Cash Back: <span className="fw-medium">{form[0].usdtData.BuyBack || 0} USDT</span> </h6>
                                                                                            {toTreasury ? (
                                                                                                <h6 className="mb-0">Treasury: <span className="fw-medium">{form[0].usdtData.ToTreasuryOrPool || 0} USDT</span> </h6>
                                                                                            ) : (
                                                                                                <h6 className="mb-0">SbSe Pool: <span className="fw-medium">{form[0].usdtData.ToTreasuryOrPool || 0} USDT </span></h6>
                                                                                            )}
                                                                                        </div>
                                                                                        <div className="d-flex align-items-center mb-3 switchBtn">
                                                                                            <div className="me-2 mb-1">Send to Treasury</div>
                                                                                            <Form>
                                                                                                <Form.Check
                                                                                                    type="switch"
                                                                                                    id="custom-switch"
                                                                                                    checked={toTreasury}
                                                                                                    onChange={handleToggle}
                                                                                                />
                                                                                            </Form>
                                                                                        </div>
                                                                                    </div>
                                                                                    <Button
                                                                                        variant="primary"
                                                                                        className=" w-100 fw-medium mb-3"
                                                                                        onClick={(e) => handleTokenToUsdtSwap(e)}
                                                                                        disabled={!token || form[0].error}
                                                                                    >
                                                                                        Swap
                                                                                    </Button>
                                                                                </Col>
                                                                            )}
                                                                        </Row>
                                                                    </div>
                                                                )}


                                                            </div>
                                                        </Nav.Item>
                                                    </Nav>
                                                </Col>
                                            </Row>
                                        </Tab.Container>

                                    </Col>}
                            </Row>
                        </Col>
                    </Row>
                    <Row>
                        {/* <Col lg={6}>
                            <div>
                                <hr />
                            </div>

                        </Col> */}
                    </Row>

                </Container>

            </div>
            <Footer />
        </>
    )
}