import React from 'react';
import Header from '../Layout/Header';
import Footer from '../Layout/Footer';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import {Container,Row,Col} from 'react-bootstrap';
import config from '../../config';
export default function Aboutus() {
  const loginDetails = useSelector((state) => state.auth.LoginDetails);
  const navigate = useNavigate();

  if (!loginDetails) {
    navigate(`${config.baseUrl}`);
  }
  return (
    <>
      <Header />
      
      <section className='aboutus py-4'>
        <Container>
          <Row>
            <Col lg={12}>
              <div>
                <h1>About us</h1>
                <p>Our business model revolves around the primary token, INFI, which goes beyond the typical
                  cryptocurrency. Ownership of INFI means ownership of Inverted Investment LLC. With our
                  innovative protocol, we guarantee secure digital transactions across different blockchains, forge
                  virtual spaces for metaverse gaming, and create a more inclusive blockchain-based banking
                  system that prioritises users' financial well-being. While we cater to individuals globally, our main
                  focus is on serving governments and businesses across various sectors, including finance,
                  healthcare, leisure, IT and real estate. Establishing our headquarters in Dubai is essential, as the
                  city has made remarkable strides in tokenisation and digital payment innovation. We are confident
                  that our business model will foster economic opportunities within the INFI eBank and SbSe
                  protocol, guided by our WEB4 concept.</p>
                <p>INFI CDEX MultiChain Exchange is a modern cryptocurrency trading platform regulated by the
                  proprietary ©SbSe Protocol. It emphasises strong cybersecurity, transparency, cost-effective trading
                  and the generation of passive income for INFI holders in USDT and Bitcoin on a monthly basis.</p>
              </div>
            </Col>
          </Row>
        </Container>

      </section>
      <Footer />
    </>
  );
}
